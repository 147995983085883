import React, { useState, useEffect } from 'react';

const SearchingAnimation: React.FC = () => {
    const [dots, setDots] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return <div className="text-lg font-medium text-gray-400">Researching{dots}</div>;
};

export default SearchingAnimation;
