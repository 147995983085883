import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//defs
import type { RootState } from 'src/redux/store';

//views
import {
    Campaign,
    Home,
    HubspotSuccess,
    LinkedinSuccess,
    OrgIndexPage,
    OrgOnboarding,
    Profile,
    ContactsDetails,
    CaseStudies,
    AdvancedCampaign,
    Assets,
    Presets
} from 'src/views/Private';
import { Privacy, Tnc } from 'src/views';
import { Companies, Company, ContactsDbAnalytics } from 'src/views/Private/Admin';

//components
import { AppSidebar } from 'src/components';

//helpers
import { analytics } from 'src/shared/utils/Analytics';
import { updateConfig } from 'src/redux/reducers/configReducer';
import { clearStore } from 'src/redux/reducers/userReducer';

//api
import { useLazyGetConfigQuery } from 'src/redux/queries/config';

//constants
import { STATUS } from 'src/shared/constants';
import { ROUTES, ADMIN_ROUTES } from 'src/shared/constants/routes';
import { toast } from 'react-toastify';

//widgets
import AddCampaignTypeModal from 'src/components/AddCampaignTypeModal';

const PrivateRoutes = ({ isAdmin = false }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [getConfig] = useLazyGetConfigQuery();

    const [showAdCampaignModal, setShowAddCampaignModal] = useState(false);
    const userOrgs = useSelector((state: RootState) => state.user.userDetails?.orgs) || [];
    const config = useSelector((state: RootState) => state.config);

    useEffect(() => {
        const path = location.pathname + location.search;
        analytics.sendPageview(path);
    }, [location]);

    useEffect(() => {
        try {
            getConfig()
                .unwrap()
                .then((data: RootState['config']) => {
                    if (data) dispatch(updateConfig(data));
                })
                .catch((err: any) => {
                    if (err.status === STATUS.unauthorized) {
                        dispatch(clearStore());
                        toast.error(err?.message || err?.data?.message);
                    }
                });
        } catch (err: any) {
            toast.error(err?.data?.message || 'Error in loading resources!');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCampaignModalClose = () => {
        setShowAddCampaignModal(false);
    };

    const ORG_ROUTES = [
        { path: ROUTES.HOME, element: <Home /> },
        { path: ROUTES.CAMPAIGN, element: <Campaign /> },
        { path: ROUTES.CAMPAIGN_ID, element: <Campaign /> },
        { path: ROUTES.CAMPAIGN_BDR_CADENCE_ID, element: <Campaign /> },
        { path: ROUTES.PROFILE, element: <Profile /> },
        { path: ROUTES.ORG, element: <OrgIndexPage /> },
        { path: ROUTES.ORG_ONBOARDING, element: <OrgOnboarding /> },
        { path: ROUTES.PRIVACY, element: <Privacy /> },
        { path: ROUTES.TNC, element: <Tnc /> },
        { path: ROUTES.CAMPAIGN_PESONALIZED, element: <Campaign /> },
        { path: ROUTES.CAMPAIGN_PESONALIZED_ID, element: <Campaign /> },
        { path: ROUTES.HUBSPOT_AUTH_SUCCESSS, element: <HubspotSuccess /> },
        { path: ROUTES.Linkedin_AUTH_SUCCESSS, element: <LinkedinSuccess /> },
        { path: ROUTES.CASE_STUDIES, element: <CaseStudies /> },
        { path: ROUTES.ASSETS, element: <Assets /> },
        { path: ROUTES.PRESETS, element: <Presets /> },
        { path: '*', element: <Navigate replace to={ROUTES.HOME} /> }
    ];

    if (config.enableAdvancedPersonalizationCampaign) {
        ORG_ROUTES.push(
            { path: ROUTES.ADVANCED_CAMPAIGN_PESONALIZED, element: <AdvancedCampaign /> },
            { path: ROUTES.ADVANCED_CAMPAIGN_PESONALIZED_ID, element: <AdvancedCampaign /> }
        );
    }

    if (config.enableContacts) {
        ORG_ROUTES.push({ path: ROUTES.CONTACT_DETAILS, element: <ContactsDetails /> });
    }

    const NO_ORG_ROUTES = [
        { path: ROUTES.ORG_ONBOARDING, element: <OrgOnboarding /> },
        { path: '*', element: <Navigate replace to={ROUTES.ORG_ONBOARDING} /> }
    ];

    let ADMIN_PATHS: {
        path: string;
        element: JSX.Element;
    }[] = [];

    if (isAdmin) {
        ADMIN_PATHS = [
            { path: ADMIN_ROUTES.CONTACTS_DB_SCORE, element: <ContactsDbAnalytics /> },
            { path: ADMIN_ROUTES.COMPANIES, element: <Companies /> },
            { path: ADMIN_ROUTES.COMPANY, element: <Company /> }
        ];
    }

    const isAdminPath = location.pathname.includes('/admin');

    return (
        <div className="flex">
            {!isAdminPath && !!userOrgs?.length && (
                <AppSidebar setShowAddCampaignModal={setShowAddCampaignModal} />
            )}
            <Routes>
                {ADMIN_PATHS.map(({ path, element }, key) => (
                    <Route key={`admin${key}`} path={path} element={element} />
                ))}
                {userOrgs?.length
                    ? ORG_ROUTES.map(({ path, element }, key) => (
                          <Route key={key} path={path} element={element} />
                      ))
                    : NO_ORG_ROUTES.map(({ path, element }, key) => (
                          <Route key={key} path={path} element={element} />
                      ))}
            </Routes>

            {showAdCampaignModal && <AddCampaignTypeModal handleClose={handleCampaignModalClose} />}
        </div>
    );
};

export default React.memo(PrivateRoutes);
