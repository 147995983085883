import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

//defs
import type { IContentForm, IContentFormData, IFile, IPreset } from 'src/defs';
import type { MenuPlacement } from 'react-select';
import type { IContacts } from 'src/defs';
import type { IList } from 'src/views/Private/Contacts/widgets/ContactsTable/widgets/Heading/Headings';

//constants
import { STRINGS } from 'src/shared/strings';
import { CONTENT_FILE_TYPES } from 'src/shared/constants';

//helpers
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';
import { validateData } from 'src/views/Private/AdvancedCampaign/helpers';

//widgets
import { CustomButton, CustomInput } from 'src/widgets';
import FilesListingModal from './widgets/FilesListingModal';
import Sidebar from './widgets/Sidebar';
import Contacts from './widgets/Contacts';
import PresetsModal from './widgets/PresetsModal';
import BottomBar from './widgets/BottomBar';
import ResultTab from '../ResultTab';
import ContentSection from 'src/components/ContentSection';

//styles
import styles from './styles.module.scss';

//apis
import { useLazyGetPresetsQuery } from 'src/redux/queries/Presets';

//constants
import { textDarkTitleColor } from 'src/shared/styles/colors';

interface IFields {
    label: string;
    placeholder: string;
    value: string | { label: string; value: string };
    error?: string;
    id: string;
    ref?: (el: HTMLInputElement | null) => HTMLInputElement | null;
    tooltip?: string;
    inputType?: string;
    selectOptions?: { label: string; value: string }[];
    placement?: MenuPlacement;
    isMulti?: boolean;
    customCss?: React.CSSProperties;
    rows?: number;
    readOnly?: boolean;
}

const ContentForm = ({
    data,
    updateData = () => {},
    errors = {},
    handleError = () => {},
    personas = [],
    audiences = [],
    handleSubmit = () => {},
    onAddPersonaSuccess = () => {},
    onAddAudienceSuccess = () => {},
    updatePriorityQueue = () => {},
    updateChoices,
    regenerateResults,
    acceptResults,
    maxWords,
    handleMaxWords,
    regeneratedChoices,
    updateRegeneratedChoices,
    handleNext,
    handleBack,
    title,
    isComplete,
    tags,
    subType,
    feedback,
    handleFeedback,
    content,
    seoKeywords,
    handleSave,
    groupBy,
    choices,
    isDark
}: IContentForm | any) => {
    const [getPresets] = useLazyGetPresetsQuery();

    const [showFileListing, setShowFileListing] = useState(false);
    const [showSavePreset, setShowSavePreset] = useState(false);
    const [presets, setPresets] = useState<[]>([]);

    const errorRefs = useRef<{ [key: string]: HTMLInputElement | HTMLSpanElement | null }>({});

    useEffect(() => {
        document.title = 'Campaign Like a Pro - Create Campaign';
    }, []);

    useEffect(() => {
        if (choices?.length) {
            document.getElementById('resultContainer')?.scrollIntoView();
        }
    }, [choices]);

    useEffect(() => {
        fetchAllPresets();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAllPresets = async () => {
        await getPresets()
            .unwrap()
            .then((data) => {
                setPresets(data.data.presets);
            });
    };

    const handlePDFUpload = async (files: IFile[]) => {
        try {
            sessionStorage.removeItem('summary');
            analytics.sendClickEvent(
                'file_upload_click',
                ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
                ANALYTICS.ACTIONS.FILE_UPLOAD
            );
            if (files) {
                const pdfTypeIndex = data.file?.findIndex(
                    (file: any) => file.type === CONTENT_FILE_TYPES.PDF.value
                );
                data.file[pdfTypeIndex].files = files;
                updateData({ ...data });
                handleError({ ...errors, file: '' });
            }
        } catch (err) {
            console.log('error >>> ', err);
        }
    };

    const handleDocxUpload = async (files: IFile[]) => {
        try {
            sessionStorage.removeItem('summary');
            analytics.sendClickEvent(
                'file_upload_click',
                ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
                ANALYTICS.ACTIONS.FILE_UPLOAD
            );
            if (files) {
                const docTypeIndex = data.file?.findIndex(
                    (file: any) => file.type === CONTENT_FILE_TYPES.DOCX.value
                );
                data.file[docTypeIndex].files = files;
                updateData({ ...data });
                handleError({ ...errors, file: '' });
            }
        } catch (err) {
            console.log('error >>> ', err);
        }
    };

    const handelTextInput = (value: string) => {
        try {
            sessionStorage.removeItem('summary');
            let file = data.file;

            const inputTypeIndex = data.file?.findIndex(
                (file: any) => file.type === CONTENT_FILE_TYPES.TEXT.value
            );

            if (inputTypeIndex !== -1) {
                file[inputTypeIndex].value = value;
            } else {
                file.push({ type: 'textBox', value });
            }
            updateData({ ...data, file });
            handleError({ ...errors, file: '' });
        } catch (e) {
            console.log('error >>> ', e);
        }
    };

    const handleUrlInput = (urls: string[] = []) => {
        try {
            sessionStorage.removeItem('summary');
            let file = data.file;

            const urlTypeIndex = data.file?.findIndex(
                (file: any) => file.type === CONTENT_FILE_TYPES.URL.value
            );
            if (urlTypeIndex !== -1) {
                file[urlTypeIndex].value = urls;
            } else {
                file.push({ type: 'url', value: urls });
            }

            updateData({ ...data, file });
            handleError({ ...errors, file: '' });
        } catch (error) {
            console.log('error >>>> ', error);
        }
    };

    const onSubmit = () => {
        const errors = validateData(data);

        if (errors && Object.values(errors).length) {
            Object.keys(errors).map((field) => errorRefs?.current[field]?.focus());
        }

        handleSubmit();
    };

    const handleInstructions = (value: string) => {
        updateData({ ...data, instructions: value });
        handleError({ ...errors, file: '' });
    };

    const inputOptions = (types = [] as IContentFormData['file']) => {
        return (
            <div className={cx('flex items-center py-1')}>
                <ContentSection
                    isDark={isDark}
                    handleShowFileListing={handleShowFileListing}
                    handlePDFUpload={handlePDFUpload}
                    handleDocxUpload={handleDocxUpload}
                    content={types}
                    handelTextInput={handelTextInput}
                    handleUrlInput={handleUrlInput}
                    instructions={data.instructions}
                    handleInstructions={handleInstructions}
                    selectedContacts={data.contacts}
                />
            </div>
        );
    };

    const handleShowFileListing = () => {
        setShowFileListing(true);
    };

    const handleCloseFileListing = () => {
        setShowFileListing(false);
    };

    const handleFileSelect = (files: IFile[]) => {
        if (files.length) {
            const file = [...data.file];
            const index = data.file?.findIndex((file: any) => file.type === 'pdf');
            file[index].files = files;
            updateData({ ...data, file });
            handleError({ ...errors, file: '' });
        }
    };

    const setContentInputType = (type: string) => {
        let typeObject = { type, files: [], value: '' };
        const file = data.file;
        const pos = file.findIndex((f: { type: string }) => f.type === type);
        if (pos !== -1) {
            file.splice(pos, 1);
        } else {
            file.push(typeObject);
        }

        sessionStorage.removeItem('summary');

        updateData({ ...data, file: [...file] });
        handleError({ ...errors, file: '' });
        analytics.sendClickEvent(
            `source_asset_${type}_click`,
            ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
            ANALYTICS.ACTIONS.SELECT_SOURCE_TYPE
        );
    };

    const handleToneChange = (tone: { value: any }) => {
        updateData({ ...data, toneRegister: tone.value });
        handleError({ ...errors, toneRegister: '' });
        analytics.sendClickEvent(
            `tone_type_${tone.value}_click`,
            ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
            ANALYTICS.ACTIONS.SELECT_TONE_TYPE
        );
    };

    const handleFieldInputChange = (e: { target: { id: string; value: string } }) => {
        const { id, value } = e?.target;
        updateData({ ...data, [id]: value });
        handleError({ ...errors, [id]: '' });
    };

    const handleOrgUpdate = (e: { target: { value: { label: string; value: string } } }) => {
        const { label, value } = e?.target?.value;
        updateData({ ...data, orgCode: value, orgName: label });
    };

    const renderMain = () => {
        const className = cx(styles.mainField, '');
        const renderFields = () => {
            const Fields: IFields[] = [
                {
                    label: `${STRINGS.CAMPAIGN_NAME}`,
                    placeholder: '',
                    value: data.title,
                    error: errors?.title,
                    id: 'title',
                    ref: (el: HTMLInputElement | null) => (errorRefs.current['title'] = el)
                }
            ];

            return Fields.map((field, key) => {
                return (
                    <CustomInput
                        key={key}
                        id={field.id}
                        label={field.label}
                        inputClass={styles.mainInput}
                        value={field.value}
                        onChange={handleFieldInputChange}
                        error={field.error}
                        inputType={field.inputType}
                        className={className}
                        selectOptions={field.selectOptions}
                        placement={field.placement}
                        tooltipText={field.tooltip}
                        isMulti={field.isMulti}
                        labelClass={styles.label}
                        inputProps={{ ref: field.ref }}
                        customCss={field.customCss}
                        rows={field.rows}
                        readonly={field.readOnly}
                    />
                );
            });
        };

        return (
            <div className={styles.main}>
                <div className={cx('flex flex-col w-full justify-between')}>{renderFields()}</div>

                <div className={cx(`my-2`)}>
                    <div className={cx('flex justify-between')}>
                        <span className={cx('pb-2 font-semibold', textDarkTitleColor)}>
                            {'Upload, instruct, research, generate'}
                        </span>
                        <span
                            tabIndex={0}
                            ref={(el) => (errorRefs.current['file'] = el)}
                            className={styles.configureTitleError}
                        >
                            {errors?.file}
                        </span>
                    </div>
                    {inputOptions(data.file)}
                </div>

                {!!choices?.length && (
                    <div className={styles.mainFooter}>
                        <CustomButton
                            title={'Generate'}
                            onClick={onSubmit}
                            className={cx('[&&]:text-slate-300')}
                        />
                    </div>
                )}

                <BottomBar data={data} />

                {!!choices?.length && (
                    <div id="resultContainer">
                        <ResultTab
                            data={choices}
                            updateChoices={updateChoices}
                            regenerateResults={regenerateResults}
                            acceptResults={acceptResults}
                            maxWords={maxWords}
                            handleMaxWords={handleMaxWords}
                            regeneratedChoices={regeneratedChoices}
                            updateRegeneratedChoices={updateRegeneratedChoices}
                            handleNext={handleNext}
                            handleBack={handleBack}
                            title={title}
                            isComplete={isComplete}
                            tags={tags}
                            subType={subType}
                            feedback={feedback}
                            handleFeedback={handleFeedback}
                            content={content}
                            seoKeywords={seoKeywords}
                            handleSave={handleSave}
                            groupBy={groupBy}
                        />
                    </div>
                )}

                <Contacts
                    handleSelectContacts={handleSelectContacts}
                    handlePriortyQueue={handlePriortyQueue}
                    groupBy={data.groupBy}
                    selectedContacts={data.contacts}
                    error={errors?.contacts}
                />

                <div className={styles.mainFooter}>
                    <CustomButton
                        title={'Generate'}
                        onClick={onSubmit}
                        className={cx('[&&]:text-slate-300')}
                    />
                </div>

                <BottomBar data={data} />
            </div>
        );
    };

    const handlePriortyQueue = (data: IList[]) => {
        let pq = data.map((list) => list.id);
        updatePriorityQueue(pq);
    };

    const handleSelectContacts = (contacts: IContacts[], groupBy: string) => {
        updateData({
            ...data,
            contacts,
            groupBy
        });
    };

    const handleEmotionalFilterChange = (emotionalFilter: { value: string }) => {
        updateData({ ...data, emotionalFilter: emotionalFilter.value });
    };

    const handleContentFilterChange = (contentFilter: { value: string }) => {
        updateData({ ...data, contentFilter: contentFilter.value });
    };

    const handlePersonaFilterChange = (personaFilter: { value: string }) => {
        updateData({ ...data, personaFilter: personaFilter.value });
    };

    const handleShowPreset = () => {
        setShowSavePreset(true);
    };

    const handleCancel = () => {
        setShowSavePreset(false);
    };

    const handleApplyPreset = (preset: IPreset) => {
        updateData({
            ...data,
            toneRegister: preset.tone,
            emotionalFilter: preset.emotionalFilter,
            contentFilter: preset.contentFilter,
            personaFilter: preset.personaFilter,
            subType: preset.bdrType
        });
    };

    return (
        <div className={styles.container}>
            <Sidebar
                data={data}
                errors={errors}
                presets={presets}
                applyPreset={handleApplyPreset}
                setContentInputType={setContentInputType}
                handleToneChange={handleToneChange}
                handleOrgUpdate={handleOrgUpdate}
                handleEmotionalFilterChange={handleEmotionalFilterChange}
                handleContentFilterChange={handleContentFilterChange}
                handlePersonaFilterChange={handlePersonaFilterChange}
                handeSavePreset={handleShowPreset}
                isDark={isDark}
            />
            {renderMain()}

            {showFileListing && (
                <FilesListingModal
                    orgCode={data.orgCode}
                    closeModal={handleCloseFileListing}
                    handleFileSelect={handleFileSelect}
                    files={
                        data.file?.find((file: { type: string }) => file.type === 'pdf')?.files ||
                        []
                    }
                    containerClass={cx(styles.listing)}
                />
            )}

            {showSavePreset && <PresetsModal data={data} handleClose={handleCancel} />}
        </div>
    );
};

export default React.memo(ContentForm);
