import React from 'react';
import cx from 'classnames';

//constans
import { STRINGS } from 'src/shared/strings';
import { slateBlackBL, themeBorder } from 'src/shared/styles/colors';

//styles
import styles from './OrgMembersList.module.scss';

//widgets
import { CustomInput } from 'src/widgets';

//icons
import { ICON_BIN } from 'src/shared/constants/icons';

//defs
import { IOrg } from 'src/defs';

interface IProps {
    org: IOrg;
    isAdmin: boolean;
    handleUserRoleChange: (e: React.ChangeEvent, email: string, org: IOrg) => void;
    setDeleteData: (data: { title: string; desc: string }) => void;
    setDeleteId: (data: { type: string; id: string }) => void;
    setShowDeleteModal: (value: boolean) => void;
}

const OrgMembersList = ({
    org,
    isAdmin,
    handleUserRoleChange,
    setDeleteData,
    setDeleteId,
    setShowDeleteModal
}: IProps) => {
    if (!org?.users?.length) return null;

    return (
        <div className={cx('p-4')}>
            <h5 className="block mb-0 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                {STRINGS.MEMBERS}
            </h5>
            <div className={cx('flex-auto block p-2 rounded', slateBlackBL)}>
                <div style={{ minHeight: 120 }}>
                    <table className="w-full my-0 text-dark">
                        <thead className="align-bottom">
                            <tr className="font-semibold text-[0.95rem] text-secondary-dark">
                                <th className="pb-3 text-start min-w-[20px]">SR.</th>
                                <th className="pb-3 text-start min-w-[100px]">Email</th>
                                <th className={cx('pb-3 text-start min-w-[100px]')}>Role</th>
                                <th className="pb-3 text-end w-[20px]"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {org.users?.map(
                                (
                                    user: {
                                        email: string;
                                        role: string;
                                        type: string;
                                    },
                                    key: number
                                ) => (
                                    <tr
                                        key={key}
                                        className={cx(
                                            themeBorder,
                                            `border-0 border-b border-dashed last:border-b-0`
                                        )}
                                    >
                                        <td className="text-light-inverse text-md/normal">
                                            {key + 1}
                                        </td>
                                        <td className="p-3 pl-0 text-start">
                                            <span className=" text-light-inverse text-md/normal">
                                                {user.email}
                                            </span>
                                        </td>
                                        <td className={`p-0 w-40 text-end pr-2`}>
                                            <CustomInput
                                                inputType={'select'}
                                                selectOptions={[
                                                    {
                                                        label: 'admin',
                                                        value: 'admin'
                                                    },
                                                    {
                                                        label: 'user',
                                                        value: 'user'
                                                    }
                                                ]}
                                                value={{
                                                    label: user.type,
                                                    value: user.type
                                                }}
                                                placement={'top'}
                                                className={cx('w-40')}
                                                isMulti={false}
                                                onChange={(e: React.ChangeEvent) =>
                                                    handleUserRoleChange(e, user.email, org)
                                                }
                                            />
                                        </td>
                                        <td className="p-3 pl-0 text-end w-20">
                                            {isAdmin && user.type !== 'admin' && (
                                                <div
                                                    className={cx(ICON_BIN, styles.edit)}
                                                    onClick={() => {
                                                        setDeleteId({
                                                            type: 'user',
                                                            id: user?.email
                                                        });
                                                        setDeleteData({
                                                            title: 'Remove User',
                                                            desc: 'Are you sure you want to remove this user from your Org ?'
                                                        });
                                                        setShowDeleteModal(true);
                                                    }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default React.memo(OrgMembersList);
