//libs
import React from 'react';
import cx from 'classnames';
import { generatePath } from 'react-router-dom';

//styles
import styles from './AddCampaignTypeModal.module.scss';

//constants
import { ICON_CONSTRUCTION } from 'src/shared/constants/icons';
import { ROUTES } from 'src/shared/constants/routes';
import { themeBorder } from 'src/shared/styles/colors';

//hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';
import { useSelector } from 'react-redux';

//assets
import Icomoon from 'src/assets/Icomoon';

//widgets
import BetaTag from 'src/components/BetaTag';

// defs
import type { RootState } from 'src/redux/store';

interface IProps {
    handleClose: VoidFunction;
}

interface ICtas {
    idx: number;
    title: string;
    onClick: VoidFunction;
    disabled?: boolean;
}

const AddCampaignTypeModal = ({ handleClose }: IProps) => {
    const config = useSelector((state: RootState) => state.config);

    const handleNavigation = useCustomNavigation();

    const goToCampaign = () => {
        handleClose();
        handleNavigation(ROUTES.CAMPAIGN, false, {
            new: true
        });
    };

    const goToBdrCampaign = () => {
        const path = generatePath(ROUTES.CAMPAIGN_BDR_CADENCE);
        handleClose();
        handleNavigation(path, false, {
            new: true
        });
    };

    const goToPersonalizedCampaign = () => {
        const path = generatePath(ROUTES.CAMPAIGN_PESONALIZED);
        handleClose();
        handleNavigation(path, false, {
            new: true
        });
    };

    const goToAdvancedPersonalizedCampaign = () => {
        if (!config.enableAdvancedPersonalizationCampaign) return;

        const path = generatePath(ROUTES.ADVANCED_CAMPAIGN_PESONALIZED);
        handleClose();
        handleNavigation(path, false, {
            new: true
        });
    };

    const blockStyle = cx(
        'flex items-center justify-center',
        'p-2 shadow-md rounded',
        'w-full my-2',
        'text-slate-400 text-sm font-semibold bg-indigo-900',
        'cursor-pointer',
        'hover:text-slate-300 hover:bg-indigo-800'
    );

    const CampaignCtas = ({ idx, title, onClick, disabled }: ICtas) => {
        return (
            <div
                className={cx(
                    blockStyle,
                    idx === 2 && 'relative',
                    disabled &&
                        `relative pointer-events-none [&&]:bg-transparent [&&]:text-gray-400`
                )}
                onClick={onClick}
            >
                {title}

                {idx === 2 && <BetaTag classname={styles.tag} />}
                {disabled && (
                    <div
                        className={cx(
                            'absolute top-0 right-[-4px]',
                            '[&&]:text-lg [&&]:font-medium text-green-600',
                            ICON_CONSTRUCTION
                        )}
                    />
                )}
            </div>
        );
    };

    const CAMPAIGN_TYPES = [
        { title: 'Universal Campaign', onClick: goToCampaign },
        // { title: 'BDR Cadence Campaign', onClick: goToBdrCampaign },
        // { title: 'Personalized Campaign', onClick: goToPersonalizedCampaign },
        {
            title: 'Advanced Personalized Campaign',
            onClick: goToAdvancedPersonalizedCampaign,
            disabled: !config.enableAdvancedPersonalizationCampaign
        }
    ];

    return (
        <div className={cx(styles.overlay)}>
            <div
                className={cx(
                    styles.container,
                    themeBorder,
                    'p-2 shadow-lg bg-slate-800 rounded-lg shadow-sm shadow-slate-800'
                )}
            >
                <Icomoon
                    className={cx(styles.closeButton, 'bg-indigo-900')}
                    onClick={handleClose}
                    icon={'add'}
                />
                <div className={cx('py-1 px-2', 'text-md font-bold text-slate-300')}>
                    {'Select Campaign type'}
                </div>
                <div
                    className={cx(
                        'flex flex-col items-center justify-around',
                        'rounded-lg font-medium',
                        'mt-2 px-2'
                    )}
                >
                    {CAMPAIGN_TYPES.map((campaign, key) => (
                        <CampaignCtas
                            idx={key}
                            key={key}
                            title={campaign.title}
                            onClick={campaign.onClick}
                            disabled={campaign.disabled}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default React.memo(AddCampaignTypeModal);
