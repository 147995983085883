import React, { useRef } from 'react';
import cx from 'classnames';

//helpers
import { getContentTypeLabel, highlightDifferences } from 'src/shared/utils/helpers';
import { formatMarkdown } from 'src/shared/utils';

//widgets
import { CustomButton, CustomTextArea } from 'src/widgets';
import CustomActionsContainer from 'src/components/CustomActionsContainer';

//styles
import styles from './styles.module.scss';

//constants
import { LABELS } from 'src/shared/strings';

//defs
import type {
    IAudience,
    IFeedback,
    IHandleFeedback,
    IMaxWordLimit,
    IRegeneratedChoice
} from 'src/defs';

//hooks
import useMergeContent from 'src/hooks/useMergeContent';
import { themeBorder } from 'src/shared/styles/colors';

interface IProps {
    choice: { choices: string[]; type: string };
    targetKey: number;
    pos: number;
    audience: IAudience;
    maxWords: IMaxWordLimit;
    regeneratedChoices: IRegeneratedChoice;
    currentTrends?: string;
    content: string;
    seoKeywords?: string;
    feedback: IFeedback[];
    isDark: boolean;
    updateResult: (
        value: string | string[],
        targetKey: number,
        pos: number,
        idx: number,
        type: string,
        saveData?: boolean,
        isMerge?: boolean
    ) => void;
    updateRegenChoices: (value: string, targetKey: number, pos: number, idx: number) => void;
    handleFeedback: (data: IHandleFeedback) => void;
    handleSetCurrentTrends: (data: string, targetKey: number, pos: number) => void;
    handleMaxWords: (maxWords: IMaxWordLimit) => void;
    handleRegenerate: (
        audience: IAudience,
        targetKey: number,
        key: number,
        type: string,
        resultInstructions?: string
    ) => void;
    clearCurrentTrends: (targetKey: number, key: number) => void;
    updateTrends: (data: string, targetKey: number, key: number) => void;
}

const textClass = cx(
    'max-h-[calc(100vh_-_250px)] min-h-[400px] overflow-hidden overflow-y-auto inset-0'
);
const listContainer = cx('flex flex-col justify-between flex-1 min-w-[40%]');

const BdrMail = ({
    choice,
    targetKey,
    pos,
    audience,
    currentTrends = '',
    content,
    seoKeywords,
    feedback,
    isDark,
    maxWords,
    regeneratedChoices,
    updateResult,
    updateRegenChoices,
    handleRegenerate,
    handleFeedback,
    handleSetCurrentTrends,
    handleMaxWords,
    updateTrends,
    clearCurrentTrends
}: IProps) => {
    const textref: React.RefObject<HTMLInputElement>[] = [
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null)
    ];
    const mergeContent = useMergeContent();

    const contentFeedback =
        feedback?.find(
            (feed) => feed.contentType === choice?.type && feed.audienceType === audience?.label
        ) || null;

    const allowRegenerate = !regeneratedChoices?.choices?.filter((choice) => choice)?.length;
    const allowCurrentTrends = !currentTrends.length;

    const showAction = !regeneratedChoices?.choices?.[targetKey]?.length;

    const renderTextBox = (
        content: string,
        targetKey: number,
        pos: number,
        idx: number,
        type: string,
        noClap?: boolean,
        onRegenChange?: (value: string, targetKey: number, pos: number, idx: number) => void
    ) => {
        return (
            <CustomTextArea
                value={content}
                showCount={false}
                onChange={(value: string) =>
                    onRegenChange
                        ? onRegenChange(value, targetKey, pos, idx)
                        : updateResult(value, targetKey, pos, idx, type)
                }
                noClap={noClap}
                textClass={textClass}
            />
        );
    };

    const renderActions = (shareData: string, hideActions?: boolean, idx?: number) => {
        return (
            <CustomActionsContainer
                choiceType={choice.type}
                maxWords={maxWords}
                allowRegenerate={allowRegenerate}
                showAllCtas={true}
                allowCurrentTrends={allowCurrentTrends}
                showAction={showAction}
                targetKey={targetKey}
                pos={pos}
                content={content}
                seoKeywords={seoKeywords || ''}
                contentFeedback={contentFeedback}
                shareData={shareData}
                audienceLabel={audience?.label}
                handleFeedback={handleFeedback}
                handleSetCurrentTrends={handleSetCurrentTrends}
                handleMaxWords={handleMaxWords}
                handleRegenerate={() =>
                    handleRegenerate(audience, targetKey, idx || 0, choice.type)
                }
                hideActions={hideActions}
            />
        );
    };

    const acceptChoiceCta = (value: string, pos: number, idx: number, type: string) => {
        return (
            <CustomButton
                title={LABELS.ACCEPT}
                onClick={() => {
                    updateResult(value, targetKey, pos, idx, type, true);
                }}
            />
        );
    };

    const handleMergeCta = async (
        value: string | string[],
        pos: number,
        idx: number,
        type: string
    ) => {
        if (typeof value !== 'string') {
            value.splice(
                value.length - 1,
                1,
                value[value.length - 1] + '<br /> <br />' + currentTrends
            );
            updateResult(value, targetKey, pos, idx, type, true, true);
            return;
        }

        if (!value || !currentTrends) return;

        let mergedContent = await mergeContent({ content: value, currentTrends });
        updateResult(mergedContent, targetKey, pos, idx, type, true, true);
    };

    const clearCta = () => {
        return (
            <CustomButton
                className="mx-4"
                title={LABELS.CLEAR}
                onClick={() => clearCurrentTrends(targetKey, pos)}
            />
        );
    };

    const renderList = (
        list: string[],
        pos: number,
        type: string,
        regeneratedList: string[] = []
    ) => {
        let str1: string,
            str2: string = '';
        return (
            <div className={'flex flex-col felx-wrap justify-between flex-1'}>
                {list?.map((item, idx) => {
                    let regeneratedString: string = regeneratedList[idx];
                    str1 = item;
                    str2 = regeneratedString;
                    regeneratedString = highlightDifferences(str1, regeneratedString, styles.diff);
                    return (
                        <div
                            className={cx(
                                'flex pb-4 my-2',
                                themeBorder,
                                '[&&]:border-0 [&&]:border-b last:[&&]:border-0 last:[&&]:pb-0 border-dashed'
                            )}
                            style={{ minHeight: textref[idx]?.current?.offsetHeight }}
                            key={idx}
                        >
                            <div className={cx(listContainer, 'pr-4')}>
                                {renderTextBox(formatMarkdown(str1), targetKey, pos, idx, type)}
                                {!!regeneratedString && (
                                    <div className={cx(styles.listItemContainerCta, 'my-2')}>
                                        {acceptChoiceCta(str1, pos, idx, type)}
                                    </div>
                                )}
                            </div>
                            {!!regeneratedString && (
                                <div className={cx(listContainer, 'pr-4')}>
                                    {renderTextBox(
                                        formatMarkdown(regeneratedString),
                                        targetKey,
                                        pos,
                                        idx,
                                        type,
                                        true,
                                        updateRegenChoices
                                    )}
                                    <div className={cx(styles.listItemContainerCta, 'my-2')}>
                                        {acceptChoiceCta(str2, pos, idx, type)}
                                    </div>
                                </div>
                            )}

                            {!!currentTrends && (
                                <div
                                    className={'w-[40%] flex-1 pr-4 flex flex-col justify-between'}
                                >
                                    <CustomTextArea
                                        value={formatMarkdown(currentTrends)}
                                        showCount={false}
                                        onChange={(value: string) =>
                                            updateTrends(value, targetKey, pos)
                                        }
                                        noClap
                                        textClass={textClass}
                                    />
                                    <div className={cx('flex justify-center', 'my-2')}>
                                        {clearCta()}
                                        <CustomButton
                                            title={LABELS.MERGE}
                                            onClick={() => handleMergeCta(str1, pos, 0, type)}
                                        />
                                    </div>
                                </div>
                            )}
                            {renderActions(str1, false, idx)}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.wrapperTitle}>{getContentTypeLabel(choice.type)}</h1>
            {renderList(choice?.choices, pos, choice?.type, regeneratedChoices?.choices)}
        </div>
    );
};

export default React.memo(BdrMail);
