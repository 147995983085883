import React from 'react';
import cx from 'classnames';

//widgets
import AddMemberCta from 'src/views/Private/Profile/widgets/AddMemberCta';
import { OnboardingForm } from 'src/views/Private/Org/OrgOnboarding/widgets/OnboardingForm';
import BlockGen from './widgets/BlockGen';
import OrgMembersList from './widgets/OrgMembersList';
import ContentList from './widgets/ContentList';
import Header from '../Header';

//helpers
import { transformStateData } from 'src/views/Private/Org/OrgOnboarding/helpers';

//assets
import Icomoon from 'src/assets/Icomoon';

//styles
import styles from '../../Tabs.module.scss';

//defs
import type { IOrg } from 'src/defs';

//constants
import { bgSlate750, ctaStyle, textDarkTitleColor, themeBorder } from 'src/shared/styles/colors';
import { CustomButton } from 'src/widgets';

interface IProps {
    userOrgs: { role: string; code: string; name: string; isDefault: boolean }[];
    orgsData: IOrg[];
    activeOrg: string | number;
    isEditingOrgId: string | number;
    defaultOrg: string | number;
    addMember: (value: string, hideModal: VoidFunction) => Promise<void>;
    handleAddNewOrg: VoidFunction;
    setActiveOrg: (org: string) => void;
    setIsEditingOrgId: (orgId: string) => void;
    handleDefaultOrg: (e: React.MouseEvent, orgCode: string) => void;
    handleUserRoleChange: (e: React.ChangeEvent, email: string, org: IOrg) => void;
    setDeleteData: (data: { title: string; desc: string }) => void;
    setDeleteId: (data: { type: string; id: string }) => void;
    setShowDeleteModal: (value: boolean) => void;
    handleEditOrgSuccess: (data: IOrg) => void;
}

const OrgsTab = ({
    userOrgs,
    orgsData,
    activeOrg,
    isEditingOrgId,
    defaultOrg,
    addMember,
    handleAddNewOrg,
    setActiveOrg,
    setIsEditingOrgId,
    handleDefaultOrg,
    handleUserRoleChange,
    setDeleteData,
    setDeleteId,
    setShowDeleteModal,
    handleEditOrgSuccess
}: IProps) => {
    return (
        <div className={cx('')}>
            <Header ctaTtitle={'Add Org'} handleCtaClick={handleAddNewOrg} />
            {orgsData?.map((org, key) => {
                const isAdmin = userOrgs.find((uo) => uo.role === 'admin' && uo.code === org.code);
                const isActiveOrg = activeOrg === org.code;
                const isEditActive = isEditingOrgId === org?.code;
                const isDefaultOrg = defaultOrg === org?.code;
                return (
                    <div key={key} className={cx('bg-slate-100 rounded-md my-2')}>
                        <div
                            className={cx(
                                'flex items-center justify-between text-lg bg-slate-800',
                                'p-2 cursor-pointer hover:bg-slate-900',
                                isActiveOrg ? `rounded-t` : 'rounded',
                                isDefaultOrg && themeBorder,
                                textDarkTitleColor
                            )}
                            onClick={() => {
                                if (isEditActive) return;
                                setActiveOrg(isActiveOrg ? '' : org.code);
                                setIsEditingOrgId('');
                            }}
                        >
                            <div>
                                {org?.name}{' '}
                                {isDefaultOrg && (
                                    <Icomoon
                                        className={cx('h-2 mr-2', styles.edit)}
                                        icon={'badge'}
                                    />
                                )}
                            </div>

                            {isAdmin && isActiveOrg ? (
                                !isEditActive ? (
                                    <Icomoon
                                        className={styles.edit}
                                        icon={'pencil'}
                                        onClick={(
                                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                                        ) => {
                                            setIsEditingOrgId(org.code);
                                            e.stopPropagation();
                                        }}
                                    />
                                ) : (
                                    <Icomoon
                                        className={styles.edit}
                                        icon={'cancel'}
                                        onClick={(
                                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                                        ) => {
                                            setIsEditingOrgId('');
                                            e.stopPropagation();
                                        }}
                                    />
                                )
                            ) : null}
                        </div>
                        {activeOrg === org?.code && (
                            <div className={cx(bgSlate750)}>
                                <div className={cx('px-4 pt-4 flex')}>
                                    {isAdmin && (
                                        <div className={cx('mr-4')}>
                                            <AddMemberCta
                                                handleAddMember={addMember}
                                                ctaClass={cx(ctaStyle)}
                                            />
                                        </div>
                                    )}
                                    {!isDefaultOrg && (
                                        <CustomButton
                                            className={cx('mr-4')}
                                            onClick={(
                                                e: React.MouseEvent<HTMLElement, MouseEvent>
                                            ) => handleDefaultOrg(e, org?.code)}
                                            title={'Make Default'}
                                        />
                                    )}
                                    <BlockGen orgName={org.name} orgId={org.code} />
                                </div>
                                <div className={cx('w-full')}>
                                    <OnboardingForm
                                        data={{ ...org, ...transformStateData(org) }}
                                        isViewing={!isEditActive}
                                        isEditing={isEditActive}
                                        inputClass={styles.input}
                                        handleSuccess={handleEditOrgSuccess}
                                        isDefaultOrg={isDefaultOrg}
                                    />
                                </div>
                                <ContentList org={org} />
                                <OrgMembersList
                                    org={org}
                                    isAdmin={!!isAdmin}
                                    handleUserRoleChange={handleUserRoleChange}
                                    setDeleteData={setDeleteData}
                                    setDeleteId={setDeleteId}
                                    setShowDeleteModal={setShowDeleteModal}
                                />
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(OrgsTab);
