import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { toast } from 'react-toastify';

// defs
import type { RootState } from 'src/redux/store';
import type { IContacts, IGroupedContacts } from 'src/defs';
import type { IList } from './widgets/Heading/Headings';

// hooks
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// widgets
import CustomProgressBar from 'src/widgets/CustomProgressBar';
import TableData from './widgets/TableData';
import { CustomInput } from 'src/widgets';
import GroupedTableData from './widgets/GroupedTableData';

// apis
import { useLazyInitHubspotQuery } from 'src/redux/queries/Hubspot';
import {
    useContactsMappingMutation,
    useStopContactsMappingMutation
} from 'src/redux/queries/Mappings';
import {
    useDeleteLeadsMutation,
    useLazyGetLeadsQuery,
    useUpdateLeadMutation
} from 'src/redux/queries/Leads';

// helpers
import { startLoading, stopLoading } from 'src/redux/reducers';
import { debounce, openUrl } from 'src/shared/utils';

// constants
import { ICON_ARROW_LEFT, ICON_ARROW_RIGHT } from 'src/shared/constants/icons';
import { PROCESS_CONTACTS } from 'src/shared/constants/events';
import { GROUP_FILTERS } from 'src/shared/constants';
import { CONTACT_HEADINGS, KEYS_TO_REMOVE } from './constants';
import { bgSlate600 } from 'src/shared/styles/colors';

// styles
import styles from './ContactTable.module.scss';

//assets
import TARGET from 'src/assets/icons/target-black.png';

interface IProps {
    selectedFilters: { value: string; label: string }[];
    listLength?: number;
    isCampaign?: boolean;
    campaignContacts?: IContacts[];
    groupBy?: { label: string; value: string };
    error?: string;
    handleCampaignContacts?: (contact: IContacts[], groupBy: string) => void;
    handlePriortyQueue?: (data: IList[]) => void;
}

const ctaStyle = cx(
    'text-sm',
    'p-2 ml-4',
    'cursor-pointer',
    'border-2 border-slate-700 rounded-lg',
    'bg-slate-700',
    'hover:bg-slate-800'
);

const cssLoader = cx('absolute', 'w-[-webkit-fill-available]');

const ContactTable = ({
    selectedFilters,
    listLength,
    isCampaign,
    campaignContacts = [],
    groupBy,
    error,
    handleCampaignContacts,
    handlePriortyQueue
}: IProps) => {
    const [initHubspot] = useLazyInitHubspotQuery();
    const [contactMapping] = useContactsMappingMutation();
    const [cancelContactMapping] = useStopContactsMappingMutation();
    const [getLeads] = useLazyGetLeadsQuery();
    const [deleteLeads] = useDeleteLeadsMutation();
    const [updateLead] = useUpdateLeadMutation();

    const user = useSelector((state: RootState) => state.user);
    const userDetails = user.userDetails;

    const dispatch = useDispatch();

    const [contacts, setContacts] = useState<IContacts[] | { [key: string]: IContacts[] }>([]);
    const [totalContacts, setTotalContacts] = useState<number>(0);
    const [selectedContacts, setSelectedContacts] = useState<IContacts[]>([]);
    const [inProgress, setInProgress] = useState(false);
    const [uploadingContacts, setUploadingContacts] = useState(false);
    const [totalChunks, setTotalChunks] = useState(0);
    const [counter, setCounter] = useState(0);
    const [contactsQuery, setContactsQuery] = useState({
        limit: listLength || 200,
        offset: 0,
        sortBy: 'updatedAt',
        order: -1,
        searchQuery: '',
        groupBy: groupBy ? groupBy : { label: 'None', value: '' }
    });
    const [isGrouped, setIsGrouped] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [query, setQuery] = useState('');

    const [priorityList, setPriorityList] = useState<IList[]>([...CONTACT_HEADINGS]);

    const loc = useLocation();

    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        if (hiddenFileInput?.current) {
            hiddenFileInput.current.click();
        }
    };

    useEffect(() => {
        isCampaign && setSelectedContacts(campaignContacts);
    }, [campaignContacts, isCampaign]);

    useEffect(() => {
        isCampaign ? fetchContacts() : fetchContactsWithLoader();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactsQuery, isCampaign]);

    let socket: WebSocket;

    const openSocket = () => {
        // Determine the WebSocket URL
        const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        const host = window.location.hostname;

        const userId = userDetails.id;

        const websocketUrl = `${protocol}//${host}?userId=${userId}`;

        socket = new WebSocket(websocketUrl);

        socket.addEventListener('message', async (event) => {
            const data = JSON.parse(event.data);
            console.log('edvemt ???? ', data.eventId);
            if (data?.eventId !== PROCESS_CONTACTS) return;

            setInProgress(true);

            const { totalChunks, currentChunkIdx } = data;

            if (data.status === 'completed') {
                await fetchContacts();
                setTotalChunks(totalChunks);
                setCounter(currentChunkIdx);
                if (currentChunkIdx === totalChunks) {
                    setTimeout(() => {
                        setInProgress(false);
                        setTotalChunks(0);
                        setCounter(0);
                    }, 3000);
                }
            } else if (data.status === 'failed') {
                console.error('Event processing failed');
                // Handle failure
            } else if (data.status === 'canceled') {
                setInProgress(false);
                toast.success('Contact enrichment Canceled');
            }
        });

        socket.addEventListener('open', () => {
            console.log('WebSocket connection established');
            socket.send(JSON.stringify({ eventId: 'your-event-id' }));
        });

        // Handle connection close
        socket.addEventListener('close', () => {
            console.log('WebSocket connection closed');
        });

        // Handle connection errors
        socket.addEventListener('error', (error) => {
            console.error('WebSocket error:', error);
        });
    };

    useEffect(() => {
        if (isCampaign) {
            handlePriortyQueue?.(sortHeadingsByPriority(CONTACT_HEADINGS));

            return;
        }

        openSocket();

        // Cleanup the WebSocket connection when the component unmounts
        return () => {
            socket?.close();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchContactsWithLoader = async () => {
        try {
            dispatch(startLoading());
            await fetchContacts();
        } catch (error) {
            console.log('error >>>> ', error);
        } finally {
            dispatch(stopLoading());
        }
    };

    const cancelContactsProcessing = async () => {
        try {
            dispatch(startLoading());
            await cancelContactMapping()
                .unwrap()
                .then((res) => {
                    console.log(res);
                    setInProgress(false);
                });
        } catch (error) {
            console.log('error >>>> ', error);
        } finally {
            dispatch(stopLoading());
        }
    };

    const fetchHubspotContacts = async () => {
        // convert contacts to csv file and then send to api
        try {
            dispatch(startLoading());
            await initHubspot(null)
                .unwrap()
                .then((data) => {
                    if (data?.loginUrl) {
                        localStorage.setItem('hubspot_redirect', loc.pathname);
                        openUrl(data.loginUrl);
                    } else if (data?.data) {
                        setContacts(data?.data || []);
                    }
                })
                .catch((e) => {
                    throw e;
                });
        } catch (error) {
            console.log('error >>>> ', error);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setInProgress(true);
        setUploadingContacts(true);
        const file = event.target.files?.[0];

        event.target.value = '';

        if (file) {
            try {
                await contactMapping({
                    file,
                    orgId: userDetails?.orgs.find((org: any) => org.isDefault)?.code || ''
                }).unwrap();
                openSocket();
            } catch (error: any) {
                setInProgress(false);
                toast.error(error?.data?.message || 'file processing failed!');
            } finally {
                dispatch(stopLoading());
                setTimeout(() => {
                    setUploadingContacts(false);
                }, 300);
            }
        }
        localStorage.setItem('processingContacts', JSON.stringify('1'));
    };

    const fetchContacts = async () => {
        const { offset, limit, sortBy, order, searchQuery, groupBy } = contactsQuery;
        try {
            await getLeads({
                offset,
                limit,
                sortBy,
                order,
                searchQuery,
                groupBy: groupBy.value
            })
                .unwrap()
                .then((data: any) => {
                    if (data.isGrouped) {
                        setIsGrouped(true);
                        setContacts(data?.contacts || []);
                    } else {
                        setIsGrouped(false);
                        setContacts(data?.contacts || []);
                    }
                    setTotalContacts(data.total);
                    setTotalPages(Math.ceil(data.total / contactsQuery.limit) || 1);
                })
                .catch((e) => {
                    throw e;
                });
        } catch (error) {
            console.log('error >>>> ', error);
        }
    };

    function convertJSONToCSV(jsonArray: any) {
        const csvRows = [];

        // Extract headers
        const headers = Object.keys(jsonArray[0]);
        csvRows.push(headers.join(',')); // Add the headers row

        // Loop through the data and convert each row to CSV format
        for (const row of jsonArray) {
            const values = headers.map((header) => {
                const escapeValue = ('' + row[header]).replace(/"/g, '\\"'); // Escape double quotes
                return `"${escapeValue}"`;
            });
            csvRows.push(values.join(',')); // Add each row to the csvRows array
        }

        return csvRows.join('\n'); // Join all rows with a newline
    }

    function downloadCSV(csvString: any, filename = 'leads.csv') {
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        a.click();
    }

    const handleDownloadClick = () => {
        let data = [...(JSON.parse(JSON.stringify(contacts)) as IContacts[])];
        const sanitizedContacts = data.map((obj) => {
            KEYS_TO_REMOVE.forEach((key) => delete obj[key]);
            return obj;
        });
        const csvData = convertJSONToCSV(sanitizedContacts);
        downloadCSV(csvData);
    };

    const handleDelete = async () => {
        if (isCampaign || !(contacts instanceof Array)) return;

        try {
            // Filter out the contacts that are not selected

            if (!selectedContacts.length) return;

            const deleteIds = selectedContacts.map((contact) => contact._id);

            const updatedContacts = contacts.filter(({ _id }, _) => !deleteIds.includes(_id));

            dispatch(startLoading());

            await deleteLeads({ ids: deleteIds })
                .unwrap()
                .then((data: any) => {
                    toast.success(data?.message);
                    setContacts(updatedContacts);
                    setSelectedContacts([]);
                })
                .catch((err) => {
                    toast.error(err.message || 'Contcts(s) deletion failed!');
                })
                .finally(() => {
                    dispatch(stopLoading());
                });

            await fetchContactsWithLoader();
        } catch (error) {
            console.log(error);
        }
    };

    const handleOrderChange = (data: { order: number; sortBy: string }) => {
        const { order, sortBy } = data;

        setContactsQuery((state) => ({ ...state, order, sortBy }));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSearch = useCallback(
        debounce(async (query) => {
            try {
                if (query && query?.length < 3) return;

                setContactsQuery((state) => ({
                    ...state,
                    searchQuery: query
                }));
            } catch (e: any) {
                console.log(e);
                toast.error(e, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }, 1000),
        []
    );

    const handleSearch = (e: { target: { value: string } }) => {
        const value = e.target.value;
        setQuery(value);
        debounceSearch(value);
    };

    const handleGroupBy = async (e: { target: { value: { label: string; value: string } } }) => {
        setContactsQuery((state) => ({ ...state, groupBy: e.target.value }));
        handleCampaignContacts?.([], e.target?.value?.value);
        setSelectedContacts([]);
    };

    const handleSelectContacts = (contacts: IContacts[]) => {
        setSelectedContacts(contacts);
        isCampaign && handleCampaignContacts?.(contacts, contactsQuery.groupBy.value);
    };

    const handleUpdateContact = async (contact: { [key: string]: string }) => {
        try {
            dispatch(startLoading());
            const { id, ...rest } = contact;
            await updateLead({ id, payload: rest as IContacts })
                .unwrap()
                .then((res: any) => toast.success(res?.message))
                .catch((err) => {
                    throw err;
                });
        } catch (err: any) {
            toast.error(err?.data?.message || 'Failed to update contact!');
        } finally {
            dispatch(stopLoading());
        }
    };

    const sortHeadingsByPriority = (list: IList[]): IList[] =>
        [...list].sort((a, b) => b.priority - a.priority);

    const handlePriorityUpdate = (data: IList, idx: number) => {
        let list = [...priorityList];
        list[idx] = data;
        setPriorityList([...list]);

        handlePriortyQueue?.(sortHeadingsByPriority(list));
    };

    return (
        <div className={cx('relative mx-auto w-[100%]', 'flex flex-col', styles.container)}>
            <div>
                <span className={styles.containerError}>{error}</span>
                <header
                    className={cx(
                        bgSlate600,
                        !isCampaign ? 'p-4' : 'py-1 px-4',
                        'flex justify-between items-center',
                        'rounded-t-md'
                    )}
                >
                    <h1 className={cx('text-3xl')}>Contacts</h1>

                    {!isCampaign ? (
                        <div className={cx('flex justify-between items-center')}>
                            {inProgress ? (
                                !uploadingContacts && (
                                    <div
                                        className={cx(
                                            ctaStyle,
                                            'bg-red-700 hover:bg-red-600 border-red-700  hover:border-red-600'
                                        )}
                                        onClick={cancelContactsProcessing}
                                    >
                                        <input
                                            type="file"
                                            ref={hiddenFileInput}
                                            accept=".csv, .xlsx"
                                            onChange={handleFileUpload}
                                            className="hidden"
                                        />
                                        Cancel Contacts Enrichment
                                    </div>
                                )
                            ) : (
                                <div className={ctaStyle} onClick={handleClick}>
                                    <input
                                        type="file"
                                        ref={hiddenFileInput}
                                        accept=".csv, .xlsx"
                                        onChange={handleFileUpload}
                                        className="hidden"
                                    />
                                    Upload CSV
                                </div>
                            )}

                            <div className={ctaStyle} onClick={handleDownloadClick}>
                                Download CSV
                            </div>

                            <div className={ctaStyle} onClick={handleDelete}>
                                Delete
                            </div>

                            <CustomInput
                                className={styles.search}
                                inputClass={styles.searchInput}
                                inputProps={{ placeholder: 'Search' }}
                                value={query}
                                onChange={handleSearch}
                            />
                        </div>
                    ) : (
                        <div className={cx('flex items-center')}>
                            <div className={cx('ml-2')}>Search</div>
                            <CustomInput
                                className={styles.search}
                                inputClass={styles.searchInput}
                                value={query}
                                onChange={handleSearch}
                            />

                            <div className={cx('mx-2', 'w-[200px] text-nowrap')}>Group By</div>
                            <CustomInput
                                id={'groupBy'}
                                customCss={{ zIndex: 15 }}
                                value={{
                                    label: contactsQuery.groupBy?.label,
                                    value: contactsQuery.groupBy?.value
                                }}
                                onChange={handleGroupBy}
                                error={''}
                                inputType={'select'}
                                labelClass={styles.label}
                                selectOptions={GROUP_FILTERS}
                                placement={'bottom'}
                                isMulti={false}
                            />

                            <div className={cx('font-sans', 'flex items-center', 'ml-4 h-9')}>
                                <div className={cx('mx-2', 'w-12 h-12')}>
                                    <img
                                        src={TARGET}
                                        className={cx(
                                            'text-lg text-blue-700',
                                            ' mr-2',
                                            'w-[100%] h-[100%]',
                                            'rotate-[206deg]'
                                        )}
                                        alt="target"
                                    />
                                </div>
                                <div
                                    className={cx(
                                        'flex items-center',
                                        'text-slate-300 font-semibold'
                                    )}
                                >
                                    {campaignContacts.length}
                                </div>
                            </div>
                        </div>
                    )}
                </header>

                <div className={cx('relative', 'w-full shadow-md rounded')}>
                    {isGrouped ? (
                        <GroupedTableData
                            list={contacts as IGroupedContacts}
                            groupBy={contactsQuery?.groupBy?.value}
                            selectedGroup={selectedContacts}
                            handleOrderChange={handleOrderChange}
                            handleGroupSelect={handleSelectContacts}
                            handleUpdateContact={handleUpdateContact}
                            handlePriorityUpdate={handlePriorityUpdate}
                            priorityList={priorityList}
                            isCampaign={isCampaign}
                        />
                    ) : (
                        <TableData
                            list={contacts as IContacts[]}
                            contactsQuery={contactsQuery}
                            handleOrderChange={handleOrderChange}
                            selectedContacts={selectedContacts}
                            handleSelectContacts={handleSelectContacts}
                            handleUpdateContact={handleUpdateContact}
                            handlePriorityUpdate={handlePriorityUpdate}
                            priorityList={priorityList}
                            isCampaign={isCampaign}
                        />
                    )}

                    {/* {inProgress && (
                        <div className={styles.shimmerContainer}>
                            <div className={cx(styles.shimmer)} />
                        </div>
                    )} */}
                </div>
            </div>

            <div className={cx('flex flex-1 items-center', 'relative')}>
                {inProgress && (
                    <div className={cx(cssLoader)}>
                        <CustomProgressBar
                            progressText={`Processing Contacts....... `}
                            progress={(counter / totalChunks) * 100 || 0}
                        />
                    </div>
                )}

                <div className={cx('flex flex-1 items-center justify-between', 'py-1')}>
                    <div className={cx('flex items-center justify-between')}>
                        <div
                            className={cx('flex items-center', 'text-black', 'text-right', 'pr-2')}
                        >
                            <span className={cx('text-cyan-400', 'font-semibold', 'mr-2')}>
                                Rows per page
                            </span>
                            <span className={cx('text-white')}>{contactsQuery.limit}</span>
                        </div>

                        <div
                            className={cx(
                                'flex items-center',
                                'text-black',
                                'text-right',
                                'ml-4 px-2'
                            )}
                        >
                            <span className={cx('text-cyan-400', 'font-semibold', 'mr-2')}>
                                Page
                            </span>
                            <span className={cx('text-white')}>{contactsQuery.offset + 1}</span>
                            <span className={cx('text-cyan-400', 'font-semibold', 'mx-2')}>of</span>
                            <span className={cx('text-white')}>{totalPages}</span>
                        </div>

                        <div
                            className={cx(
                                'flex items-center',
                                'text-black',
                                'text-right',
                                'ml-4 px-2'
                            )}
                        >
                            <span
                                className={cx(
                                    ICON_ARROW_LEFT,
                                    'text-cyan-400',
                                    'text-2xl font-semibold',
                                    'mr-2',
                                    'cursor-pointer'
                                )}
                                onClick={() => {
                                    if (contactsQuery.offset === 0) return;
                                    setContactsQuery((state) => ({
                                        ...state,
                                        offset: state.offset - 1
                                    }));
                                }}
                            />
                            <span
                                className={cx(
                                    ICON_ARROW_RIGHT,
                                    'text-cyan-400',
                                    'text-2xl font-semibold',
                                    'cursor-pointer'
                                )}
                                onClick={() => {
                                    if (contactsQuery.offset === totalPages - 1) return;
                                    setContactsQuery((state) => ({
                                        ...state,
                                        offset: state.offset + 1
                                    }));
                                }}
                            />
                        </div>
                    </div>

                    <div
                        className={cx('flex items-center', 'text-black', 'text-right', 'px-2 py-1')}
                    >
                        <span className={cx('text-cyan-400', 'font-semibold', 'mr-2')}>
                            Total Contacts
                        </span>
                        <span className={cx('text-white')}>{totalContacts}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ContactTable);
