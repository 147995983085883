import type { IResearhInput } from 'src/defs';

const errorMsg = '*';

export function validateForm(data: IResearhInput) {
    const {
        targetCompany,
        targetPersona,
        solutionArea,
        sourceCompany,
        productKeywords,
        region,
        domain,
        industry
    } = data;

    const errors = {} as IResearhInput;

    if (!targetCompany) {
        errors.targetCompany = errorMsg;
    }

    if (!targetPersona) {
        errors.targetPersona = errorMsg;
    }

    if (!solutionArea) {
        errors.solutionArea = errorMsg;
    }

    if (!sourceCompany) {
        errors.sourceCompany = errorMsg;
    }

    if (!productKeywords) {
        errors.productKeywords = errorMsg;
    }

    if (!domain) {
        errors.domain = errorMsg;
    }

    if (!region) {
        errors.region = errorMsg;
    }

    if (!industry) {
        errors.industry = errorMsg;
    }

    return errors;
}
