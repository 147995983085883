import React, { useCallback, useEffect, useState } from 'react';

//api
import { useLazyGetVersionQuery } from 'src/redux/queries/config';

const UpdateInfoBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    const [getVersion] = useLazyGetVersionQuery();

    const checkForUpdate = useCallback(async () => {
        try {
            const latestVersion = await getVersion()
                .unwrap()
                .then((res) => res.version)
                .catch(() => null);

            if (!latestVersion) return;

            const storedVersion = localStorage.getItem('app_version');

            if (storedVersion && storedVersion !== latestVersion) {
                setShowBanner(true);
            }

            if (!storedVersion || storedVersion !== latestVersion) {
                localStorage.setItem('app_version', latestVersion);
            }
        } catch (error) {
            console.error('Error checking for update:', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        checkForUpdate(); // Check on mount

        const handleVisibilityChange = () => {
            if (!document.hidden) {
                requestIdleCallback(checkForUpdate, { timeout: 2000 });
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [checkForUpdate]);

    return showBanner ? (
        <div className="fixed bottom-0 left-0 w-full bg-yellow-500 text-black text-center p-2 z-50">
            A new version of the app is available.{' '}
            <button
                onClick={() => window.location.reload()}
                className="ml-2 bg-black text-white px-3 py-1 rounded"
            >
                Please Refresh
            </button>
        </div>
    ) : null;
};

export default React.memo(UpdateInfoBanner);
