import React from 'react';
import cx from 'classnames';

// widgets
// import BetaTag from 'src/components/BetaTag';
import { CustomButton } from 'src/widgets';
import { toast } from 'react-toastify';
import Feedback from 'src/components/Feedback';
import CustomActions from 'src/components/CustomActions';
import ShareContent from 'src/components/ShareContent';

// constants
import { slateBlackBR } from 'src/shared/styles/colors';
import { LABELS } from 'src/shared/strings';

// defs
import type { IFeedback, IHandleFeedback, IMaxWordLimit } from 'src/defs';
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';

interface IProps {
    choiceType: string;
    maxWords: IMaxWordLimit;
    allowRegenerate: boolean;
    showAllCtas: boolean;
    allowCurrentTrends: boolean;
    showAction: boolean;
    seoKeywords: string;
    content: string;
    targetKey: number;
    pos: number;
    contentFeedback: IFeedback | null;
    audienceLabel: string;
    shareData: string;
    hideActions?: boolean;
    handleSetCurrentTrends: (data: string, targetKey: number, pos: number) => void;
    handleMaxWords: (maxWords: IMaxWordLimit) => void;
    handleRegenerate: VoidFunction;
    handleFeedback: (data: IHandleFeedback) => void;
}

const actionsCss = cx('flex-1  h-[32px]', 'mt-4 mx-1');
// const BetaTagCss = cx('text-[8px]');

const CustomActionsContainer = ({
    choiceType,
    maxWords,
    allowRegenerate,
    showAllCtas,
    allowCurrentTrends,
    showAction,
    targetKey,
    pos,
    content,
    seoKeywords,
    contentFeedback,
    audienceLabel,
    shareData,
    hideActions = false,
    handleMaxWords,
    handleRegenerate,
    handleSetCurrentTrends,
    handleFeedback
}: IProps) => {
    const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        analytics.sendClickEvent(
            'word_range_change_click',
            ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
            ANALYTICS.ACTIONS.WORD_COUNT_CHANGE
        );
        handleMaxWords({ ...maxWords, [type]: Number(e.target.value) });
    };

    const handleFeedbackSubmit = (
        type: string,
        audienceLabel: string,
        isLiked: boolean,
        comment = '',
        key?: number
    ) => {
        handleFeedback({ type, audienceLabel, key, isLiked, comment });

        if (isLiked) {
            const event = key && key >= 0 ? `${type}_${key}_like_click` : `${type}_like_click`;
            analytics.sendClickEvent(
                event,
                ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                ANALYTICS.ACTIONS.LIKE_CLICK
            );
        } else {
            const event =
                key && key >= 0 ? `${type}_${key}_dislike_click` : `${type}_dislike_click`;
            analytics.sendClickEvent(
                event,
                ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                ANALYTICS.ACTIONS.DISLIKE_CLICK
            );
        }
    };

    return (
        <div
            className={cx(
                'flex flex-col justify-start items-center flex-[0.25]',
                'relative h-fit',
                'p-2 py-4 min-w-[280px] max-w-[380px]',
                'rounded-xl',
                slateBlackBR
            )}
        >
            {!!shareData && <ShareContent data={shareData} type={choiceType} />}
            {!hideActions && (
                <>
                    <div className={cx('flex flex-col')}>
                        <label htmlFor={`${choiceType}maxWords`}>
                            &asymp; Words : {maxWords[choiceType]}
                        </label>
                        <input
                            id={`${choiceType}maxWords`}
                            type={'range'}
                            value={maxWords[choiceType]}
                            step={10}
                            min={10}
                            max={8000}
                            onChange={(e) => handleRangeChange(e, choiceType)}
                            className={cx(`w-[116px] my-2 accent-slate-750`)}
                        />
                    </div>

                    <div className={cx('flex flex-wrap justify-between', 'w-full')}>
                        <CustomButton
                            title={LABELS.REGENERATE}
                            onClick={() => {
                                !allowRegenerate &&
                                    toast.info('Please Accept one choice to regenerate again', {
                                        toastId: 1
                                    });
                                allowRegenerate && handleRegenerate();
                            }}
                            className={actionsCss}
                        />

                        {showAllCtas && (
                            <CustomActions
                                allowCurrentTrends={allowCurrentTrends}
                                showAction={showAction}
                                targetKey={targetKey}
                                pos={pos}
                                handleSetCurrentTrends={handleSetCurrentTrends}
                                content={content || shareData}
                                seoKeywords={seoKeywords}
                            />
                        )}
                    </div>

                    <Feedback
                        contentFeedback={contentFeedback}
                        handleFeedbackSubmit={(isLiked, comment) =>
                            handleFeedbackSubmit(choiceType, audienceLabel, isLiked, comment)
                        }
                    />
                </>
            )}
        </div>
    );
};

export default React.memo(CustomActionsContainer);
