import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true'
};

export const configApi = createApi({
    reducerPath: 'configApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/',
        timeout: 60000,
        prepareHeaders: (headers, { getState }) => {
            const state = getState() as any;
            let token = state.user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getConfig: builder.query<any, void>({
            query: () => ({
                method: 'Get',
                headers: { ...headers },
                url: 'getConfig'
            })
        }),
        getVersion: builder.query<any, void>({
            query: () => ({
                method: 'Get',
                headers: { ...headers },
                url: 'version'
            })
        })
    })
});

export const { useLazyGetConfigQuery, useLazyGetVersionQuery } = configApi;
