import React, { useRef } from 'react';
import cx from 'classnames';

//helpers
import { highlightDifferences } from 'src/shared/utils/helpers';
import { formatMarkdown } from 'src/shared/utils';

//widgets
import { CustomButton, CustomTextArea } from 'src/widgets';
import Feedback from 'src/components/Feedback';
import ShareContent from 'src/components/ShareContent';
import CustomActions from 'src/components/CustomActions';

//styles
import styles from './styles.module.scss';

//constants
import { LABELS } from 'src/shared/strings';
import { bgSlate800, slateBlackBR, themeBorder } from 'src/shared/styles/colors';

//defs
import { IAudience, IFeedback, IMaxWordLimit } from 'src/defs';
import { IRegeneratedChoice } from '../../ResultTab';

//hooks
import useMergeContent from 'src/hooks/useMergeContent';

interface IProps {
    choice: { choices: string[]; type: string };
    targetKey: number;
    pos: number;
    audience: IAudience;
    cancelEdit: VoidFunction;
    saveData: VoidFunction;
    updateResult: (
        value: string,
        targetKey: number,
        pos: number,
        idx: number,
        type: string,
        saveData?: boolean,
        isMerge?: boolean
    ) => void;
    maxWords: IMaxWordLimit;
    handleRangeChange: (e: React.ChangeEvent<HTMLInputElement>, type: string) => void;
    handleRegenerate: (audience: IAudience, targetKey: number, key: number, type: string) => void;
    regeneratedChoice: IRegeneratedChoice;
    subType: string;
    handleFeedbackSubmit: (
        type: string,
        audienceLabel: string,
        isLiked: boolean,
        comment: string,
        key: number
    ) => void;
    feedback: IFeedback[];
    currentTrends?: string;
    content: string;
    clearCurrentTrends: (targetKey: number, key: number) => void;
    updateTrends: (data: string, targetKey: number, key: number) => void;
    handleSetCurrentTrends: (data: string, targetKey: number, pos: number) => void;
    updateRegenChoices: (value: string, targetKey: number, key: number, idx: number) => void;
}

const actionsCss = cx('flex-1', 'mt-4 mx-1');
const textClass = cx('h-[calc(100vh_-_250px)] overflow-hidden overflow-y-auto inset-0');

const BdrCadenceResult = ({
    choice,
    targetKey,
    pos,
    audience,
    cancelEdit,
    saveData,
    updateResult,
    maxWords,
    handleRangeChange,
    handleRegenerate,
    regeneratedChoice,
    subType,
    handleFeedbackSubmit,
    feedback,
    currentTrends,
    content,
    handleSetCurrentTrends,
    updateTrends,
    clearCurrentTrends,
    updateRegenChoices
}: IProps) => {
    const resultRef = useRef<HTMLDivElement>(null);
    const mergeContent = useMergeContent();

    const textref: React.RefObject<HTMLInputElement>[] = [
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null),
        useRef<HTMLInputElement>(null)
    ];

    const renderSendMail = (data: string) => {
        return <ShareContent data={data} type={choice.type} />;
    };

    const renderTextBox = (
        content: string,
        targetKey: number,
        pos: number,
        idx: number,
        type: string
    ) => {
        return (
            <CustomTextArea
                value={content}
                showCount={false}
                onChange={(value: string) => updateResult(value, targetKey, pos, idx, type)}
                textClass={textClass}
            />
        );
    };

    const renderRegenerateCta = (
        data: string,
        key: number,
        type: string,
        regeneratedString: boolean
    ) => {
        const contentFeedback =
            feedback?.find((feed) => feed.contentType === type && feed.position === key) || null;
        return (
            <div className={styles.containerContentAction}>
                <label htmlFor={`${choice.type}maxWords`}>
                    &asymp; Words : {maxWords[choice.type]}
                </label>
                <input
                    id={`${choice.type}maxWords`}
                    type={'range'}
                    value={maxWords[choice.type]}
                    step={10}
                    min={10}
                    max={8000}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleRangeChange(e, choice?.type)
                    }
                    className={cx(styles.wordRange, `accent-slate-750`)}
                />

                <div className={cx('flex flex-wrap justify-between', 'w-full')}>
                    <CustomButton
                        title={LABELS.REGENERATE}
                        onClick={() => {
                            handleRegenerate(audience, targetKey, key, choice.type);
                        }}
                        className={actionsCss}
                    />

                    <CustomActions
                        allowCurrentTrends={!!!currentTrends?.length}
                        showAction={!regeneratedString}
                        targetKey={targetKey}
                        pos={key}
                        handleSetCurrentTrends={handleSetCurrentTrends}
                        content={content || data}
                        seoKeywords={''}
                    />
                </div>

                <Feedback
                    contentFeedback={contentFeedback}
                    handleFeedbackSubmit={(isLiked, comment) =>
                        handleFeedbackSubmit(choice.type, audience?.label, isLiked, comment, key)
                    }
                />
            </div>
        );
    };

    const acceptChoiceCta = (
        value: string,
        targetKey: number,
        pos: number,
        idx: number,
        type: string
    ) => {
        return (
            <CustomButton
                title={LABELS.ACCEPT}
                onClick={() => {
                    if (resultRef?.current) {
                        resultRef.current?.scrollIntoView({ block: 'start' });
                    }
                    updateResult(value, targetKey, pos, idx, type, true);
                }}
            />
        );
    };

    const clearCta = () => {
        return (
            <CustomButton
                className="mx-4"
                title={LABELS.CLEAR}
                onClick={() => clearCurrentTrends(targetKey, pos)}
            />
        );
    };

    const handleMergeCta = async (value: string, pos: number, idx: number, type: string) => {
        if (!value || !currentTrends) return;

        let mergedContent = await mergeContent({ content: value, currentTrends });
        updateResult(mergedContent, targetKey, pos, idx, type, true, true);
    };

    const renderList = (
        list: string[],
        targetKey: number,
        pos: number,
        type: string,
        regeneratedList = ['']
    ) => {
        let str1: string,
            str2: string = '';
        return (
            <div className={styles.list}>
                {list?.map((item: string, idx: number) => {
                    let regeneratedString: string = regeneratedList[idx];
                    str1 = formatMarkdown(item);
                    str2 = regeneratedString;
                    regeneratedString = highlightDifferences(str1, regeneratedString, styles.diff);
                    return (
                        <div className={styles.listItem} key={idx}>
                            <div className="flex-1">
                                <div className={styles.listItemContainer}>
                                    <div className={styles.listItemContainerWrap}>
                                        {renderTextBox(str1, targetKey, pos, idx, type)}
                                        {!!regeneratedString && (
                                            <div className={styles.listItemContainerCta}>
                                                {acceptChoiceCta(str1, targetKey, pos, idx, type)}
                                            </div>
                                        )}
                                    </div>
                                    {!!regeneratedString && (
                                        <div className={styles.listItemContainerWrap}>
                                            <div
                                                className={cx(
                                                    styles.listItemContainerText,
                                                    `py-2 px-5 m-0 ml-2 rounded-xl`,
                                                    bgSlate800,
                                                    themeBorder
                                                )}
                                                ref={textref[idx]}
                                                dangerouslySetInnerHTML={{
                                                    __html: formatMarkdown(regeneratedString)
                                                }}
                                            />
                                            <div className={styles.listItemContainerCta}>
                                                {acceptChoiceCta(str2, targetKey, pos, idx, type)}
                                            </div>
                                        </div>
                                    )}
                                    {currentTrends && (
                                        <div
                                            className={'w-[48%] px-4 flex flex-col justify-between'}
                                        >
                                            <CustomTextArea
                                                value={formatMarkdown(currentTrends)}
                                                showCount={false}
                                                className={styles.listItemContainerWrap}
                                                onChange={(value: string) =>
                                                    updateTrends(value, targetKey, pos)
                                                }
                                                noClap
                                                textClass={textClass}
                                            />

                                            <div className={cx('flex justify-center', 'my-2')}>
                                                {clearCta()}
                                                <CustomButton
                                                    title={LABELS.MERGE}
                                                    onClick={() =>
                                                        handleMergeCta(str1, pos, 0, type)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div
                                className={cx(
                                    'flex-[0.3]',
                                    'p-2 py-4 ml-2 rounded-xl h-fit',
                                    'min-w-[280px] max-w-[380px]',
                                    themeBorder,
                                    slateBlackBR
                                )}
                            >
                                <div className={styles.listItemIcons}>
                                    {/* {renderIcons(idx, !!regeneratedString)} */}
                                    {renderSendMail(str1)}
                                </div>
                                {renderRegenerateCta(item, idx, type, !!regeneratedString)}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={styles.wrapper} ref={resultRef}>
            {renderList(choice?.choices, targetKey, pos, choice?.type, regeneratedChoice?.choices)}
        </div>
    );
};

export default React.memo(BdrCadenceResult);
