import React, { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

// consts
import {
    darkContainer,
    slateDarkBg,
    textDarkColor,
    textDarkTitleColor,
    themeBorder,
    themeShadow
} from 'src/shared/styles/colors';
import { STRINGS } from 'src/shared/strings';

// api
import { useLazyFetchContentQuery } from 'src/redux/queries/Org';

// helpers
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { RootState } from 'src/redux/store';
import { CustomInput } from 'src/widgets';
import { truncateFileName } from 'src/shared/utils';

const containerStyle = cx('w-full py-4 px-8');

interface IFileData {
    name: string;
    url: string;
}
interface IDataProps {
    idx: number;
    orgId: string;
    orgName: string;
    createdBy: string;
    fileData: IFileData;
}

const Assets = () => {
    const dispatch = useDispatch();

    const [fetchContent] = useLazyFetchContentQuery();

    const [assets, setAssets] = useState([]);
    const [orgOptions, setOrgOptions] = useState<{ label: string; value: string }[]>([]);
    const [query, setQuery] = useState<{ label: string; value: string }>({
        label: 'All',
        value: 'all'
    });

    const user = useSelector((state: RootState) => state.user);
    const userOrgs = useMemo(() => user.userDetails?.orgs || [], [user]);

    const defaultOrg = userOrgs.find((uo) => uo?.isDefault) || { name: '', code: '' };

    useEffect(() => {
        if (userOrgs?.length) {
            const options = userOrgs.map((org) => ({
                label: org?.name,
                value: org?.code
            }));
            setOrgOptions([{ label: 'All', value: 'all' }, ...options]);
        }
    }, [userOrgs]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const fetchData = async () => {
        try {
            dispatch(startLoading());
            const contentData = await fetchContent(query?.value || defaultOrg.code).unwrap();
            setAssets(contentData.data);
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(stopLoading());
        }
    };

    const DataCard: React.FC<IDataProps> = ({ idx, orgId, orgName, createdBy, fileData }) => {
        return (
            <div
                className={cx(
                    'relative',
                    'p-4 rounded-lg max-w-lg w-[300px] h-[200px]',
                    themeShadow,
                    themeBorder,
                    slateDarkBg
                )}
            >
                {/* Header */}
                <div
                    className={cx(
                        'absolute top-[-4px] left-[-4px]',
                        'bg-slate-900 flex items-center justify-center',
                        'rounded-full w-8 h-8 text-lg',
                        textDarkColor
                    )}
                >
                    {idx}
                </div>
                {/* File Info */}
                <div className="mt-4">
                    <h2 className={cx('text-lg font-semibold', textDarkTitleColor)}>File Info</h2>
                    <p className="text-sm break-all">
                        <span className="font-semibold">Name:</span>{' '}
                        {truncateFileName(fileData.name)}
                    </p>

                    <p className="text-sm break-all">
                        <span className="font-semibold">Organization Name:</span> {orgName}
                    </p>
                    <p className="text-sm break-all">
                        <span className="font-semibold">Organization ID:</span> {orgId}
                    </p>
                    <p className="text-sm break-all">
                        <span className="font-semibold">Created By:</span> {createdBy}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div className={cx(containerStyle, darkContainer)}>
            <div className={cx('flex items-center justify-between', 'mb-4')}>
                <h1>{STRINGS.ASSETS}</h1>

                <div className={cx('flex items-center justify-between')}>
                    <h1 className="mr-2">{'Organization'}</h1>
                    <CustomInput
                        inputType="select"
                        placement="bottom"
                        inputProps={{ placeholder: 'Organization' }}
                        selectOptions={orgOptions}
                        isMulti={false}
                        onChange={(e: { target: { value: { label: string; value: string } } }) => {
                            setQuery(e.target.value);
                        }}
                        className={cx('[&&]:w-80 text-black')}
                        value={query}
                    />
                </div>
            </div>

            <div className={cx('flex flex-wrap items-center justify between gap-8')}>
                {assets.map((asset: IDataProps, key) => (
                    <DataCard {...asset} key={key} idx={key + 1} />
                ))}
            </div>
        </div>
    );
};

export default React.memo(Assets);
