import React, { useEffect, useState } from 'react';
import cx from 'classnames';

//widgets
import BdrCadenceResult from './Widgets/BdrCadenceResult';
import Tabs from './Tabs';

//styles
import styles from './styles.module.scss';

//constants
import { MaxWordLimit } from 'src/shared/constants';

//analytics
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';

//defs
import { IAudience, IChoices, IFeedback, IMaxWordLimit, ITargetChoice } from 'src/defs';

//helpers
import { deepClone } from 'src/shared/utils';

export interface IRegeneratedChoice {
    choices: string[];
}

interface IProps {
    data: [];
    regeneratedChoices: { contentTypes: IRegeneratedChoice[] }[];
    title: string;
    isComplete: boolean;
    maxWords: IMaxWordLimit;
    tags: string;
    subType: string;
    feedback: IFeedback[];
    content: string;
    seoKeywords?: string;
    groupBy: string;
    regenerateResults: (
        audience: IAudience,
        type: string,
        targetKey: number,
        key: number,
        Instructions?: string
    ) => void;
    handleMaxWords: (maxWords: IMaxWordLimit) => void;
    updateChoices: (data: IChoices[]) => void;
    updateRegeneratedChoices: (data: { contentTypes: IRegeneratedChoice[] }[]) => void;
    handleNext: () => void;
    handleBack: () => void;
    acceptResults: (targetKey: number, key: number, type: String) => void;
    handleFeedback: (
        type: string,
        audienceLabel: string,
        key: number | undefined,
        isLike: boolean,
        comment: string
    ) => void;
    handleSave: VoidFunction;
}

const ResultTab = ({
    data = [],
    regenerateResults,
    acceptResults,
    handleMaxWords,
    maxWords = MaxWordLimit,
    updateChoices,
    regeneratedChoices = [],
    updateRegeneratedChoices,
    handleNext,
    handleBack,
    title = '',
    isComplete = false,
    tags = '',
    subType = '',
    feedback = [],
    handleFeedback,
    seoKeywords = '',
    content = '',
    handleSave,
    groupBy
}: IProps) => {
    const [updatedData, setUpdatedData] = useState<IChoices[]>([]);
    const [currentTrends, setCurrentTrends] = useState<string[][]>([[]]);

    useEffect(() => {
        document.title = 'Campaign Like a Pro - Approve Campaign Output';

        setUpdatedData([...data.map((d) => d)]);
    }, [data]);

    const handleRegenerate = (
        audience: IAudience,
        targetKey: number,
        key: number,
        type: string,
        resultInstructions?: string
    ) => {
        analytics.sendClickEvent(
            'regenrate_click',
            ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
            ANALYTICS.ACTIONS.REGENERATE_CLICK
        );
        regenerateResults(audience, type, targetKey, key, resultInstructions);
    };

    const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        analytics.sendClickEvent(
            'word_range_change_click',
            ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
            ANALYTICS.ACTIONS.WORD_COUNT_CHANGE
        );
        handleMaxWords({ ...maxWords, [type]: Number(e.target.value) });
    };

    const updateResult = (
        value: string | string[],
        targetKey: number,
        key: number,
        idx: number,
        type: string,
        saveData = false,
        isMerge = false
    ) => {
        try {
            let choices = [...updatedData[targetKey].choices[key]?.choices];
            if (typeof value === 'string') {
                choices[idx] = value;
            } else {
                choices = value;
            }
            updatedData[targetKey].choices.splice(key, 1, {
                choices,
                accepted: updatedData[targetKey].choices[key]?.accepted,
                type
            });
            setUpdatedData([...updatedData]);
            if (saveData) {
                updateChoices([...updatedData]);
                if (typeof value === 'string') {
                    regeneratedChoices[targetKey]?.contentTypes[key]?.choices?.splice(idx, 1, '');
                } else {
                    if (regeneratedChoices?.length)
                        regeneratedChoices[targetKey].contentTypes[key].choices = [];
                }
                updateRegeneratedChoices([...regeneratedChoices]);
            }
            if (isMerge) {
                clearCurrentTrends(targetKey, key);
            }
            handleSave();
        } catch (e) {
            console.log('error>>', e);
        }
    };

    const updateRegenChoices = (value: string, targetKey: number, key: number, idx: number) => {
        try {
            let regenChoices = deepClone(regeneratedChoices);
            regenChoices[targetKey].contentTypes[key].choices[idx] = value;
            updateRegeneratedChoices([...regenChoices]);
        } catch (e) {
            console.log('error>>', e);
        }
    };

    const saveData = () => {
        analytics.sendClickEvent(
            'save_after_edit_click',
            ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
            ANALYTICS.ACTIONS.SAVE_EDIT
        );
        updateChoices([...updatedData]);
        setTimeout(() => {
            handleSave();
        }, 1000);
    };

    const cancelEdit = () => {
        analytics.sendClickEvent(
            'cancel_edit_click',
            ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
            ANALYTICS.ACTIONS.CANCEL_EDIT
        );
        setUpdatedData([...data]);
    };

    const handleFeedbackSubmit = (
        type: string,
        audienceLabel: string,
        isLiked: boolean,
        comment = '',
        key?: number
    ) => {
        handleFeedback(type, audienceLabel, key, isLiked, comment);

        if (isLiked) {
            const event = key && key >= 0 ? `${type}_${key}_like_click` : `${type}_like_click`;
            analytics.sendClickEvent(
                event,
                ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                ANALYTICS.ACTIONS.LIKE_CLICK
            );
        } else {
            const event =
                key && key >= 0 ? `${type}_${key}_dislike_click` : `${type}_dislike_click`;
            analytics.sendClickEvent(
                event,
                ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                ANALYTICS.ACTIONS.DISLIKE_CLICK
            );
        }
    };

    const clearCurrentTrends = (targetKey: number, key: number) => {
        updateTrends('', targetKey, key);
    };

    const updateTrends = (data: string, targetKey: number, key: number) => {
        let trends = currentTrends.map((trends) => trends);
        trends[targetKey][key] = data;
        setCurrentTrends(() => trends);
    };

    const handleSetCurrentTrends = (data: string, targetKey: number, key: number) => {
        updateTrends(data, targetKey, key);
    };

    const renderBdrContent = (
        choice: ITargetChoice,
        targetKey: number,
        key: number,
        regeneratedChoice: IRegeneratedChoice,
        audience: IAudience
    ) => {
        return (
            <div key={key} className={styles.containerContentRow}>
                <BdrCadenceResult
                    targetKey={targetKey}
                    choice={choice}
                    pos={key}
                    audience={audience}
                    updateResult={updateResult}
                    cancelEdit={cancelEdit}
                    saveData={saveData}
                    maxWords={maxWords}
                    handleRangeChange={handleRangeChange}
                    handleRegenerate={handleRegenerate}
                    regeneratedChoice={regeneratedChoice}
                    subType={subType}
                    handleFeedbackSubmit={handleFeedbackSubmit}
                    feedback={feedback}
                    currentTrends={currentTrends?.[targetKey]?.[key] || ''}
                    content={content}
                    clearCurrentTrends={clearCurrentTrends}
                    updateTrends={updateTrends}
                    handleSetCurrentTrends={handleSetCurrentTrends}
                    updateRegenChoices={updateRegenChoices}
                />
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <div className={cx(styles.containerContent)}>
                <Tabs
                    data={updatedData}
                    groupBy={groupBy}
                    regeneratedChoices={regeneratedChoices}
                    renderBdrContent={renderBdrContent}
                />
            </div>
        </div>
    );
};

export default React.memo(ResultTab);
