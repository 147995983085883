import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IOrg {
    isDefault: boolean;
    code: string;
    name: string;
    role: string;
}

interface IUserDetails {
    id: string;
    orgs: IOrg[];
    userType: number;
    name?: string;
    picture?: string;
}

interface IState {
    userDetails: IUserDetails;
    token: string;
    isDarkTheme: boolean;
}

const initialState: IState = {
    userDetails: {
        id: '',
        orgs: [],
        name: '',
        userType: 1
    },
    token: '',
    isDarkTheme: true
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateToken: (state: IState, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        updateUserDetails: (state: IState, action: PayloadAction<IUserDetails>) => {
            state.userDetails = action.payload;
        },
        updateTheme: (state: IState, action: PayloadAction<boolean>) => {
            state.isDarkTheme = action.payload;
        },
        clearStore: (state: IState) => {
            state.userDetails = initialState.userDetails;
            state.token = '';
        }
    }
});

export const { updateToken, updateTheme, updateUserDetails, clearStore } = userSlice.actions;

export default userSlice.reducer;
