import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';

//defs
import type { IContacts, IFile, IResearhInput } from 'src/defs';

// widgets
import MenuModal from 'src/components/MenuModal';
import { CustomInput, CustomInputSeparator } from 'src/widgets';

// constant
import {
    ICON_ATTACHMENT,
    ICON_CANCEL,
    ICON_FILE,
    ICON_GLOBE,
    ICON_LINK
} from 'src/shared/constants/icons';
import {
    bgSlate600,
    bgSlate800,
    darkContainer,
    hoverBorderResearch,
    slateBlackBR,
    themeBorder
} from 'src/shared/styles/colors';
import { CONTENT_FILE_TYPES } from 'src/shared/constants';

// helpers
import { formatScenarios, sanitizeFileNames, sanitizeFileType } from 'src/shared/utils';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { validateForm } from './helpers';

//styles
import styles from './ContentSection.styles';
import { toast } from 'react-toastify';

//apis
import { useLazyGetResarchDataQuery, useStartResearchMutation } from 'src/redux/queries/Research';

//widgets
import TypingAnimation from './TypingAnimation';
import { RootState } from 'src/redux/store';

interface IProps {
    content: { files: IFile[]; value: string | string[]; type: string }[];
    instructions: string;
    isDark?: boolean;
    selectedContacts?: IContacts[];
    handleInstructions: (value: string) => void;
    handleShowFileListing: VoidFunction;
    handlePDFUpload: (files: IFile[]) => void;
    handleDocxUpload: (files: IFile[]) => void;
    handelTextInput: (value: string) => void;
    handleUrlInput: (urls: string[]) => void;
}

const emptyResearchInput = {
    sourceCompany: '',
    targetCompany: '',
    targetPersona: '',
    industry: '',
    solutionArea: '',
    region: '',
    domain: '',
    productKeywords: ''
};

const ContentSection = ({
    content,
    isDark,
    instructions,
    selectedContacts,
    handleInstructions,
    handleShowFileListing,
    handlePDFUpload,
    handleDocxUpload,
    handelTextInput,
    handleUrlInput
}: IProps) => {
    const dispatch = useDispatch();

    const user = useSelector((state: RootState) => state.user);

    const defaultOrg = useMemo(() => {
        const userOrgs = user.userDetails?.orgs || [];
        return userOrgs.find((uo) => uo?.isDefault) || { name: '', code: '' };
    }, [user]);

    const [showLinkInput, setShowLinkInput] = useState(false);
    const [isResearch, setIsResearch] = useState(false);
    const [researchInput, setResearchInput] = useState<IResearhInput>(emptyResearchInput);
    const [researchComplete, setResearchComplete] = useState(false);
    const [isFetch, setIsFetch] = useState(false);
    const [errors, setErrors] = useState<IResearhInput>(emptyResearchInput);

    const [startResearch] = useStartResearchMutation();
    const [getResearchData] = useLazyGetResarchDataQuery();

    useEffect(() => {
        setResearchInput((state) => ({
            ...state,
            sourceCompany: state.sourceCompany || defaultOrg.name,
            targetCompany: state.targetCompany || selectedContacts?.[0]?.companyName || '',
            targetPersona: state.targetPersona || selectedContacts?.[0]?.jobTitle || '',
            industry: state.industry || selectedContacts?.[0]?.industry || '',
            solutionArea: state.solutionArea || selectedContacts?.[0]?.painPoints || '',
            region: state.region || selectedContacts?.[0]?.companyAddress || '',
            domain: state.domain || selectedContacts?.[0]?.industryTrends || ''
        }));
    }, [selectedContacts, defaultOrg]);

    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const acceptType = 'application/pdf,.pdf,.doc,.docx,.txt';

    const linksContent = content.find((content) => content.type === CONTENT_FILE_TYPES.URL.value);
    const pdfFiles = content.find((content) => content.type.includes('pdf'));
    const docFiles = content.find((content) => content.type.includes('doc'));

    let files = [] as IFile[];
    if (pdfFiles?.files?.length) {
        files.push(...pdfFiles.files);
    }

    if (docFiles?.files?.length) {
        files.push(...docFiles.files);
    }

    const removeFile = (name: string, type: string) => {
        if (['pdf', 'PDF'].includes(type) && pdfFiles) {
            let idx = pdfFiles.files.findIndex((file) => file.name === name);
            pdfFiles.files.splice(idx, 1);
            handlePDFUpload(pdfFiles.files);
        } else {
            if (docFiles) {
                let idx = docFiles.files.findIndex((file) => file.name === name);
                docFiles.files?.splice(idx, 1);
                handleDocxUpload(docFiles.files);
            }
        }
    };

    const handleClick = () => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;

        const selectedFiles = Array.from(e.target.files);

        if (selectedFiles.length + files.length > 5) {
            toast.error('You can only select up to 5 files.');
            e.target.value = '';
            return;
        }

        const filesArray = [...selectedFiles, ...files];

        e.target.value = '';
        const pdfFiles = filesArray.filter((file) => file.name.includes('pdf'));
        const docFiles = filesArray.filter((file) => file.name.includes('doc'));

        if (pdfFiles?.length) {
            handlePDFUpload(pdfFiles);
        }
        if (docFiles?.length) {
            handleDocxUpload(docFiles);
        }
    };

    const handleLinkClick = () => {
        setShowLinkInput((state) => !state);
    };

    const handeResearchForm = (e: { target: { id: string; value: string } }) => {
        const { id, value } = e?.target;
        setResearchInput((state) => ({
            ...state,
            [id]: value
        }));
    };

    const fetchResearchData = async (job_id: string) => {
        try {
            let ts = setInterval(async () => {
                if (researchComplete) {
                    clearInterval(ts);
                }
                await getResearchData({ job_id })
                    .unwrap()
                    .then((data) => {
                        if (data.status === 'completed') {
                            setResearchComplete(true);
                            clearInterval(ts);
                            const template = formatScenarios(data.data.scenarios);
                            handleInstructions(template);
                            setIsFetch(false);
                        } else if (data.status === 'failed') {
                            setResearchComplete(true);
                            clearInterval(ts);
                            setIsFetch(false);
                            toast.error('Research Failed!. Please try again later');
                        }
                    })
                    .catch(() => {
                        setIsFetch(false);
                    });
            }, 15000);
        } catch (error) {
            console.log({ error });
            toast.error('Research Failed !');
        } finally {
            setIsResearch(true);
        }
    };

    const handleResearch = async () => {
        const errors = validateForm(researchInput);
        if (Object.keys(errors).length) {
            setErrors({ ...errors });
            return;
        }

        try {
            dispatch(startLoading());
            await startResearch(researchInput)
                .unwrap()
                .then((data) => {
                    if (data.status === 'processing') {
                        fetchResearchData(data.job_id);
                        setIsFetch(true);
                    }
                });
        } catch (error) {
            console.log({ error });
            toast.error('Research Failed !');
        } finally {
            setIsResearch(false);
            dispatch(stopLoading());
        }
    };

    return (
        <div
            className={cx(
                'relative',
                `flex flex-col flex-1 rounded-lg overflow-hidden`,
                bgSlate800,
                themeBorder
            )}
        >
            {!!files?.length && (
                <div className={cx('w-[calc(100vw_-_354px)]')}>
                    <div className={cx(styles.files)}>
                        {files.map((file, idx) => {
                            let fileName = sanitizeFileNames(file?.name) || '';
                            let type = sanitizeFileType(file.name, file.type);

                            return (
                                <div key={idx} className={cx(styles.file)}>
                                    <div className={cx(styles.fileIcon, ICON_FILE)} />
                                    <div className={styles.fileText}>
                                        <div className={styles.fileTextTitle}>{fileName}</div>
                                        <div className={styles.fileTextType}>{type}</div>
                                    </div>

                                    <div
                                        className={cx(styles.fileRemove, ICON_CANCEL)}
                                        onClick={() => removeFile(file.name, type)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            <textarea
                rows={isResearch ? 2 : 12}
                className={cx(
                    `w-full`,
                    'text-white',
                    bgSlate800,
                    `resize-none highlight-none outline-none p-4`
                )}
                value={instructions}
                onChange={(e) => handleInstructions(e.target.value)}
                maxLength={100000}
                autoComplete="off"
                disabled={isFetch}
            />

            {isFetch && (
                <div className={cx('absolute left-4', files.length ? 'top-[72px]' : 'top-2')}>
                    <TypingAnimation />
                </div>
            )}

            {isResearch && (
                <div className={cx(slateBlackBR)}>
                    <div className={cx(`relaitve`, `flex flex-wrap`, `gap-x-3 gap-y-2 px-4 py-3`)}>
                        {[
                            {
                                label: 'Source Company',
                                id: 'sourceCompany',
                                value: researchInput.sourceCompany
                            },
                            {
                                label: 'Target Company',
                                id: 'targetCompany',
                                value: researchInput.targetCompany
                            },
                            {
                                label: 'Target Persona',
                                id: 'targetPersona',
                                value: researchInput.targetPersona
                            },
                            { label: 'Industry', id: 'industry', value: researchInput.industry },
                            {
                                label: 'Solution Area',
                                id: 'solutionArea',
                                value: researchInput.solutionArea
                            },
                            { label: 'Region', id: 'region', value: researchInput.region },
                            { label: 'Domain', id: 'domain', value: researchInput.domain },
                            {
                                label: 'Product Keywords',
                                id: 'productKeywords',
                                value: researchInput.productKeywords
                            }
                        ].map((item) => (
                            <CustomInput
                                key={item.id}
                                label={item.label}
                                id={item.id}
                                onChange={handeResearchForm}
                                value={item.value}
                                className={cx('[&&]:w-[24%]')}
                                labelClass={cx('[&&]:text-sm')}
                                error={errors[item.id]}
                            />
                        ))}
                    </div>
                    <div
                        className={cx(
                            'flex items-center px-2 py-1 rounded',
                            bgSlate600,
                            'm-auto mb-1.5 w-[74px] cursor-pointer border-[1px] border-transparent',
                            hoverBorderResearch
                        )}
                        onClick={handleResearch}
                    >
                        <div className={cx(ICON_GLOBE)} />
                        <div className={cx('ml-2')}>{'start'}</div>
                    </div>
                </div>
            )}

            {/* <CustomTextArea
                value={textContent?.value as string}
                onChange={(e: string) => {}}
                key={0}
                className={isDark ? 'bg-slate-800' : 'bg-white'}
            /> */}

            {(linksContent?.value?.length || showLinkInput) && (
                <CustomInputSeparator
                    values={linksContent ? [...linksContent?.value] : []}
                    onChange={(urls = []) => handleUrlInput(urls)}
                    key={0}
                    classname={cx('overflow-hidden overflow-y-auto [&&]:mb-0', darkContainer)}
                />
            )}

            <div className={cx('flex items-center rounded', slateBlackBR)}>
                <MenuModal
                    Icon={ICON_ATTACHMENT}
                    iconClass={cx('text-slate-300 hover:bg-slate-700')}
                    list={[
                        {
                            title: 'Select existing file(s)',
                            onClick: handleShowFileListing
                        },
                        {
                            title: 'Upload File(s)',
                            onClick: handleClick
                        }
                    ]}
                />

                <MenuModal
                    Icon={ICON_LINK}
                    iconClass={cx('text-slate-300 hover:bg-slate-700')}
                    handleClick={handleLinkClick}
                />

                <MenuModal
                    title="Research"
                    Icon={ICON_GLOBE}
                    iconClass={cx('text-slate-300 hover:bg-slate-700')}
                    handleClick={() => setIsResearch((state) => !state)}
                    isPill
                    isActive={isResearch}
                />

                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: 'none' }}
                    className={cx({ [styles.disabled]: files?.length >= 5 })}
                    accept={acceptType}
                    multiple={true}
                />

                {!!instructions?.length && (
                    <div className={cx('flex-1 text-right px-2')}>{instructions.length}</div>
                )}
            </div>
        </div>
    );
};

export default React.memo(ContentSection);
