import React, { useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

//widgets
import DeleteModal from 'src/components/DeleteModal';
import OrgsTab from './widgets/OrgsTab';
import PersonaTab from './widgets/PersonaTab';
import AudienceTab from './widgets/AudienceTab';

//constants
import {
    themeBorder,
    slateBlackTB,
    slateBlackBR,
    textDarkColor,
    textHoverColor,
    slateBlackBT
} from 'src/shared/styles/colors';

//defs
import type { IAudience, IOrg, IPersona } from 'src/defs';
import type { RootState } from 'src/redux/store';

interface IProps {
    activeTab: number;
    setActiveTab: (tab: number) => void;
    userOrgs: { role: string; code: string; name: string; isDefault: boolean }[];
    orgsData: IOrg[];
    personasData: IPersona[];
    targetAudienceData: IAudience[];
    defaultOrg: string;
    handleAddNewOrg: VoidFunction;
    handleShowPersonaForm: (persona: IPersona | {}, active: boolean) => void;
    handleShowAudienceForm: (audience: {}, active: boolean) => void;
    handleDeletePersona: (deleteId: string) => void;
    handleDeleteAudience: (deleteId: string) => void;
    handleUserRoleChange: (data: any, email: string, org: IOrg) => Promise<void>;
    handleDeleteOrgMember: (deleteId: string, org: string) => void;
    handleAddMemberToOrg: (
        data: { user: string; id: string },
        hideModal: VoidFunction
    ) => Promise<void>;
    handleDefaultOrg: (e: React.MouseEvent, orgCode: string) => void;
    handleEditOrgSuccess: (data: IOrg) => void;
    isDark: boolean;
}

const Tabs = ({
    activeTab = 1,
    setActiveTab,
    userOrgs = [],
    orgsData = [],
    personasData = [],
    targetAudienceData = [],
    defaultOrg = '',
    handleAddNewOrg,
    handleShowPersonaForm,
    handleShowAudienceForm,
    handleDeletePersona,
    handleDeleteAudience,
    handleUserRoleChange,
    handleDeleteOrgMember,
    handleAddMemberToOrg,
    handleDefaultOrg,
    handleEditOrgSuccess,
    isDark
}: IProps) => {
    const userId = useSelector((state: RootState) => state.user.userDetails?.id);

    const [activeOrg, setActiveOrg] = useState('');
    const [activePersona, setActivePersona] = useState('');
    const [activeAudience, setActiveAudience] = useState('');
    const [isEditingOrgId, setIsEditingOrgId] = useState('');
    const [showDeletModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState({ type: '', id: '' });
    const [deleteData, setDeleteData] = useState({ title: '', desc: '' });

    const renderDeleteModal = () => {
        return (
            <DeleteModal
                handleDelete={() => {
                    if (deleteId.type === 'audience') {
                        handleDeleteAudience(deleteId.id);
                    } else if (deleteId.type === 'user') {
                        handleDeleteOrgMember(deleteId.id, activeOrg);
                    } else {
                        handleDeletePersona(deleteId.id);
                    }
                    setDeleteId({ type: '', id: '' });
                    setShowDeleteModal(false);
                }}
                title={deleteData.title}
                desc={deleteData.desc}
                setIsOpen={setShowDeleteModal}
            />
        );
    };

    const addMember = async (value: string, hideModal: VoidFunction) => {
        await handleAddMemberToOrg({ user: value, id: activeOrg }, hideModal);
    };

    const handleOrgUpdate = (org: IOrg) => {
        setIsEditingOrgId('');
        handleEditOrgSuccess(org);
    };

    const renderTabData = () => {
        switch (activeTab) {
            case 1:
                return (
                    <OrgsTab
                        userOrgs={userOrgs}
                        orgsData={orgsData}
                        activeOrg={activeOrg}
                        isEditingOrgId={isEditingOrgId}
                        defaultOrg={defaultOrg}
                        addMember={addMember}
                        handleAddNewOrg={handleAddNewOrg}
                        handleDefaultOrg={handleDefaultOrg}
                        setActiveOrg={setActiveOrg}
                        setIsEditingOrgId={setIsEditingOrgId}
                        handleUserRoleChange={handleUserRoleChange}
                        setDeleteData={setDeleteData}
                        setDeleteId={setDeleteId}
                        setShowDeleteModal={setShowDeleteModal}
                        handleEditOrgSuccess={handleOrgUpdate}
                    />
                );
            case 2:
                return (
                    <PersonaTab
                        userId={userId}
                        userOrgs={userOrgs}
                        personasData={personasData}
                        activePersona={activePersona}
                        setActivePersona={setActivePersona}
                        handleShowPersonaForm={handleShowPersonaForm}
                        setDeleteData={setDeleteData}
                        setDeleteId={setDeleteId}
                        setShowDeleteModal={setShowDeleteModal}
                    />
                );
            case 3:
                return (
                    <AudienceTab
                        targetAudienceData={targetAudienceData}
                        userId={userId}
                        userOrgs={userOrgs}
                        activeAudience={activeAudience}
                        handleShowAudienceForm={handleShowAudienceForm}
                        setActiveAudience={setActiveAudience}
                        setDeleteData={setDeleteData}
                        setDeleteId={setDeleteId}
                        setShowDeleteModal={setShowDeleteModal}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <div className={cx('w-full', isDark && 'text-black')}>
            <div className={cx('flex')}>
                {['Your Orgs', 'Writer Personas', 'Target Audiences'].map((target, key) => {
                    return (
                        <div
                            className={cx(
                                `rounded-t-lg text-medium mt-4 ml-8 p-3 cursor-pointer hover:bg-slate-300`,
                                'font-semibold',
                                textHoverColor,
                                textDarkColor,
                                activeTab === key + 1 ? slateBlackBT : slateBlackBR,
                                activeTab === key + 1 ? cx(themeBorder, 'border-b-0') : '',
                                activeTab === key + 1 && cx('relative top-[1px]')
                            )}
                            key={key}
                            onClick={() => setActiveTab(key + 1)}
                        >
                            {target}
                        </div>
                    );
                })}
            </div>
            <div
                className={cx(
                    slateBlackTB,
                    'px-4 pb-3 rounded-t-md',
                    themeBorder,
                    `h-[calc(100vh_-_65px)] overflow-y-auto pb-20`
                )}
            >
                {renderTabData()}
            </div>
            {showDeletModal && deleteId && renderDeleteModal()}
        </div>
    );
};

export default React.memo(Tabs);
