import React, { useEffect, useState } from 'react';
import cx from 'classnames';

//widgets
import BdrMail from './Widgets/BdrMail';
import BdrCadenceResult from './Widgets/BdrCadenceResult';

//styles
import styles from './styles.module.scss';

//constants
import { MaxWordLimit, CONTENT_TYPE } from 'src/shared/constants';
import { textDarkTitleColor, themeBorder } from 'src/shared/styles/colors';

//assets
import { ListData } from './Widgets';

//analytics
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';

//defs
import {
    IAudience,
    IChoices,
    IFeedback,
    IHandleFeedback,
    IMaxWordLimit,
    ITargetChoice
} from 'src/defs';

//helpers
import { deepClone } from 'src/shared/utils';

export interface IRegeneratedChoice {
    choices: string[];
}

interface IProps {
    data: [];
    regeneratedChoices: { contentTypes: IRegeneratedChoice[] }[];
    title: string;
    isComplete: boolean;
    maxWords: IMaxWordLimit;
    tags: string;
    subType: string;
    feedback: IFeedback[];
    content: string;
    isDark: boolean;
    seoKeywords?: string;
    regenerateResults: (
        audience: IAudience,
        type: string,
        targetKey: number,
        key: number,
        Instructions?: string
    ) => void;
    handleMaxWords: (maxWords: IMaxWordLimit) => void;
    updateChoices: (data: IChoices[]) => void;
    updateRegeneratedChoices: (data: { contentTypes: IRegeneratedChoice[] }[]) => void;
    handleNext: () => void;
    handleBack: () => void;
    acceptResults: (targetKey: number, key: number, type: String) => void;
    handleFeedback: (data: IHandleFeedback) => void;
    handleSave: VoidFunction;
}

const ResultTab = ({
    data = [],
    isDark,
    regenerateResults,
    acceptResults,
    handleMaxWords,
    maxWords = MaxWordLimit,
    updateChoices,
    regeneratedChoices = [],
    updateRegeneratedChoices,
    handleNext,
    handleBack,
    title = '',
    isComplete = false,
    tags = '',
    subType = '',
    feedback = [],
    handleFeedback,
    seoKeywords = '',
    content = '',
    handleSave
}: IProps) => {
    const [updatedData, setUpdatedData] = useState<IChoices[]>([]);
    const [currentTrends, setCurrentTrends] = useState<string[][]>([[]]);

    useEffect(() => {
        document.title = 'Campaign Like a Pro - Approve Campaign Output';

        setUpdatedData([...data.map((d) => d)]);
    }, [data]);

    const handleRegenerate = (
        audience: IAudience,
        targetKey: number,
        key: number,
        type: string,
        resultInstructions?: string
    ) => {
        analytics.sendClickEvent(
            'regenrate_click',
            ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
            ANALYTICS.ACTIONS.REGENERATE_CLICK
        );
        regenerateResults(audience, type, targetKey, key, resultInstructions);
    };

    const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        analytics.sendClickEvent(
            'word_range_change_click',
            ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
            ANALYTICS.ACTIONS.WORD_COUNT_CHANGE
        );
        handleMaxWords({ ...maxWords, [type]: Number(e.target.value) });
    };

    const updateResult = (
        value: string | string[],
        targetKey: number,
        key: number,
        idx: number,
        type: string,
        saveData = false,
        isMerge = false
    ) => {
        try {
            let choices = [...updatedData[targetKey].choices[key]?.choices];
            if (typeof value === 'string') {
                choices[idx] = value;
            } else {
                choices = value;
            }
            updatedData[targetKey].choices.splice(key, 1, {
                choices,
                accepted: updatedData[targetKey].choices[key]?.accepted,
                type
            });
            setUpdatedData([...updatedData]);
            if (saveData) {
                let regenChoices = deepClone(regeneratedChoices);
                updateChoices([...updatedData]);
                if (typeof value === 'string') {
                    regenChoices[targetKey]?.contentTypes[key]?.choices?.splice(idx, 1, '');
                } else {
                    if (regenChoices?.length)
                        regenChoices[targetKey].contentTypes[key].choices = [];
                }
                updateRegeneratedChoices([...regenChoices]);
            }
            if (isMerge) {
                clearCurrentTrends(targetKey, key);
            }
            handleSave();
        } catch (e) {
            console.log('error>>', e);
        }
    };

    const updateRegenChoices = (value: string, targetKey: number, key: number, idx: number) => {
        try {
            let regenChoices = deepClone(regeneratedChoices);
            regenChoices[targetKey].contentTypes[key].choices[idx] = value;
            updateRegeneratedChoices([...regenChoices]);
        } catch (e) {
            console.log('error>>', e);
        }
    };

    const saveData = () => {
        analytics.sendClickEvent(
            'save_after_edit_click',
            ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
            ANALYTICS.ACTIONS.SAVE_EDIT
        );
        updateChoices([...updatedData]);
        setTimeout(() => {
            handleSave();
        }, 1000);
    };

    const cancelEdit = () => {
        analytics.sendClickEvent(
            'cancel_edit_click',
            ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
            ANALYTICS.ACTIONS.CANCEL_EDIT
        );
        setUpdatedData([...data]);
    };

    const handleFeedbackSubmit = (
        type: string,
        audienceLabel: string,
        isLiked: boolean,
        comment = '',
        key?: number
    ) => {
        handleFeedback({ type, audienceLabel, key, isLiked, comment });

        if (isLiked) {
            const event = key && key >= 0 ? `${type}_${key}_like_click` : `${type}_like_click`;
            analytics.sendClickEvent(
                event,
                ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                ANALYTICS.ACTIONS.LIKE_CLICK
            );
        } else {
            const event =
                key && key >= 0 ? `${type}_${key}_dislike_click` : `${type}_dislike_click`;
            analytics.sendClickEvent(
                event,
                ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                ANALYTICS.ACTIONS.DISLIKE_CLICK
            );
        }
    };

    const clearCurrentTrends = (targetKey: number, key: number) => {
        updateTrends('', targetKey, key);
    };

    const updateTrends = (data: string, targetKey: number, key: number) => {
        let newTrends = currentTrends.map((trends) => trends);

        // Ensure targetKey exists
        if (!newTrends[targetKey]) {
            newTrends[targetKey] = []; // Initialize if undefined
        }
        // Set the value
        newTrends[targetKey][key] = data;

        setCurrentTrends(() => newTrends);
    };

    const handleSetCurrentTrends = (data: string, targetKey: number, key: number) => {
        updateTrends(data, targetKey, key);
    };

    const renderContent = (
        choice: ITargetChoice,
        targetKey: number,
        key: number,
        audience: IAudience,
        regeneratedChoices: IRegeneratedChoice,
        currentTrend: string
    ) => {
        return (
            <div
                key={key}
                className={cx('flex pt-2 pb-4', themeBorder, '[&&]:border-0 [&&]:border-t')}
            >
                <ListData
                    choice={choice}
                    targetKey={targetKey}
                    pos={key}
                    audience={audience}
                    feedback={feedback}
                    updateResult={updateResult}
                    currentTrends={currentTrend}
                    clearCurrentTrends={clearCurrentTrends}
                    regeneratedChoices={regeneratedChoices}
                    updateTrends={updateTrends}
                    updateRegenChoices={updateRegenChoices}
                    maxWords={maxWords}
                    content={content}
                    seoKeywords={seoKeywords}
                    handleFeedback={handleFeedback}
                    handleSetCurrentTrends={handleSetCurrentTrends}
                    handleMaxWords={handleMaxWords}
                    handleRegenerate={handleRegenerate}
                />
            </div>
        );
    };

    const renderBdrContent = (
        choice: ITargetChoice,
        targetKey: number,
        key: number,
        regeneratedChoices: IRegeneratedChoice,
        audience: IAudience,
        currentTrend: string
    ) => {
        return (
            <div
                key={key}
                className={cx('flex pt-2 pb-4', themeBorder, '[&&]:border-0 [&&]:border-t')}
            >
                {choice?.type === 'bdrCadence' ? (
                    <BdrCadenceResult
                        targetKey={targetKey}
                        choice={choice}
                        pos={key}
                        audience={audience}
                        updateResult={updateResult}
                        cancelEdit={cancelEdit}
                        saveData={saveData}
                        maxWords={maxWords}
                        handleRangeChange={handleRangeChange}
                        handleRegenerate={handleRegenerate}
                        regeneratedChoice={regeneratedChoices}
                        subType={subType}
                        handleFeedbackSubmit={handleFeedbackSubmit}
                        feedback={feedback}
                        isDark={isDark}
                    />
                ) : (
                    <BdrMail
                        targetKey={targetKey}
                        choice={choice}
                        pos={key}
                        audience={audience}
                        maxWords={maxWords}
                        regeneratedChoices={regeneratedChoices}
                        feedback={feedback}
                        isDark={isDark}
                        currentTrends={currentTrend}
                        content={content}
                        seoKeywords={seoKeywords}
                        updateResult={updateResult}
                        updateRegenChoices={updateRegenChoices}
                        handleFeedback={handleFeedback}
                        handleSetCurrentTrends={handleSetCurrentTrends}
                        handleMaxWords={handleMaxWords}
                        handleRegenerate={handleRegenerate}
                        clearCurrentTrends={clearCurrentTrends}
                        updateTrends={updateTrends}
                    />
                )}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.containerTitle}>{title}</h1>
            <div className={styles.containerContent}>
                {updatedData.map((target, targetKey) => {
                    return (
                        <div key={targetKey} className={'mt-2'}>
                            <div
                                className={cx(
                                    'flex',
                                    'py-1 text-xl',
                                    isDark ? 'text-white' : 'text-slate-800'
                                )}
                            >
                                <h1 className={cx('font-semibold', 'mr-2', textDarkTitleColor)}>
                                    Audience:
                                </h1>
                                <span
                                    className={cx('font-bold drop-shadow-2xl', textDarkTitleColor)}
                                >
                                    {target?.audience?.label || target?.audience?.name}
                                </span>
                            </div>
                            {target?.choices?.map((choice, idx) => {
                                let regenString = regeneratedChoices[targetKey]?.contentTypes[idx];

                                return [CONTENT_TYPE[2].value, 'bdrCadence']?.includes(choice?.type)
                                    ? renderBdrContent(
                                          choice,
                                          targetKey,
                                          idx,
                                          regenString,
                                          target.audience,
                                          currentTrends?.[targetKey]?.[idx] || ''
                                      )
                                    : renderContent(
                                          choice,
                                          targetKey,
                                          idx,
                                          target?.audience,
                                          regenString,
                                          currentTrends?.[targetKey]?.[idx] || ''
                                      );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default React.memo(ResultTab);
