//constants
import { IContentFormData } from 'src/defs';
import { ERRORS } from 'src/shared/strings';

interface IErros {
    file?: string;
    contentType?: string;
    toneRegister?: string;
    audience?: string;
    persona?: string;
    subType?: string;
    title?: string;
    contacts?: string;
}

export const validateData = (data: IContentFormData) => {
    const {
        file,
        instructions,
        contentType,
        toneRegister,
        audience,
        title,
        subType,
        persona,
        contacts
    } = data;

    const errors = {} as IErros;

    if (!contacts?.length) {
        errors.contacts = `Please select atleast one contact!`;
    }

    if (!title) {
        errors.title = `Please add campaign title!`;
    }

    if (contentType.includes('bdrCadence') && !subType) {
        errors.subType = `Please select BDR type!`;
    }

    if (!file.length && !instructions?.length) {
        errors.file = `Please add atleast one source!`;
    } else {
        if (
            !instructions?.length &&
            !file[0]?.value[0]?.length &&
            !file[1].files?.length &&
            !file[2]?.files?.length
        ) {
            errors.file = ERRORS.EMPTY_CONTENT_INPUT;
        }
    }

    if (!contentType || contentType.length <= 0) {
        errors.contentType = ERRORS.EMPTY_CONTENT_TYPE;
    }
    if (!toneRegister || toneRegister.length <= 0) {
        errors.toneRegister = ERRORS.REQUIRED;
    }
    if (!audience || audience.length <= 0) {
        errors.audience = ERRORS.REQUIRED;
    }

    if (!persona) {
        errors.persona = ERRORS.REQUIRED;
    }

    return { ...errors };
};
