import React, { useState } from 'react';
import cx from 'classnames';

//assets
import { ICON_ADD, ICON_THUMBS_DOWN, ICON_THUMBS_UP } from 'src/shared/constants/icons';

//styles
import styles from './Feedback.module.scss';

//widgets
import { CustomButton, CustomInput } from 'src/widgets';

//constants
import { LABELS } from 'src/shared/strings';

interface IProps {
    contentFeedback: { isLiked: boolean } | null;
    handleFeedbackSubmit: (liked: boolean, comment: string) => void;
}

const Feedback = ({ contentFeedback, handleFeedbackSubmit }: IProps) => {
    const isLiked = contentFeedback ? contentFeedback?.isLiked : null;

    const [comment, setComment] = useState('');
    const [liked, setLiked] = useState(isLiked);
    const [showComment, setShowComment] = useState(false);

    const handleSubmit = () => {
        handleFeedbackSubmit(!!liked, comment);
        setShowComment(false);
        setComment('');
    };

    const handleCommentClose = () => {
        setComment('');
        handleFeedbackSubmit(!!liked, '');
        setShowComment(false);
    };

    return (
        <div className={styles.feedback}>
            <div
                className={cx(
                    styles.feedbackThumbsup,
                    'p-2 rounded-full',
                    liked === null
                        ? 'bg-gray-700'
                        : liked
                        ? 'bg-green-600 text-white'
                        : 'bg-gray-700'
                )}
                onClick={() => {
                    setLiked(true);
                    setShowComment(true);
                }}
            >
                <div className={cx(ICON_THUMBS_UP)} />
                <div className={cx(styles.feedbackThumbsupHover)}>{'Like'}</div>
            </div>
            <div
                className={cx(
                    styles.feedbackThumbsdown,
                    'p-2 rounded-full',
                    liked === null ? 'bg-gray-700' : liked ? 'bg-gray-700' : 'bg-red-600 text-white'
                )}
                onClick={() => {
                    setLiked(false);
                    setShowComment(true);
                }}
            >
                <div className={cx(ICON_THUMBS_DOWN)} />
                <div className={cx(styles.feedbackThumbsdownHover)}>{'Dislike'}</div>
            </div>

            {showComment && (
                <div className={cx(styles.comment, ` py-6 px-4`, `shadow`)}>
                    <CustomInput
                        inputType="textarea"
                        placement="bottom"
                        inputProps={{
                            ref: null,
                            placeholder: `Your feedback is important to us. We'll use it to enhance your experience.`
                        }}
                        onChange={(e: { target: { value: any } }) => {
                            setComment(e.target.value.slice(0, 250));
                        }}
                        value={comment}
                        autoFocus
                    />
                    <CustomButton title={LABELS.SUBMIT} onClick={handleSubmit} />
                    <div
                        className={cx(ICON_ADD, styles.commentIcon)}
                        onClick={handleCommentClose}
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(Feedback);
