import React from 'react';
import cx from 'classnames';

//constants
import { AUDIENCE } from 'src/shared/strings';
import { bgSlate750, bgSlate800, textDarkTitleColor } from 'src/shared/styles/colors';

//assets
import Icomoon from 'src/assets/Icomoon';

//styles
import styles from '../../Tabs.module.scss';

//widets
import Tile from '../Tile';

//defs
import { IAudience } from 'src/defs';
import Header from '../Header';

interface IProps {
    userId: string;
    userOrgs: { role: string; code: string; name: string; isDefault: boolean }[];
    targetAudienceData: IAudience[];
    activeAudience: string;
    handleShowAudienceForm: (audience: {}, active: boolean) => void;
    setActiveAudience: (org: string) => void;
    setDeleteData: (data: { title: string; desc: string }) => void;
    setDeleteId: (data: { type: string; id: string }) => void;
    setShowDeleteModal: (value: boolean) => void;
}

const AudienceTab = ({
    userId,
    userOrgs,
    targetAudienceData,
    activeAudience,
    handleShowAudienceForm,
    setActiveAudience,
    setDeleteData,
    setDeleteId,
    setShowDeleteModal
}: IProps) => {
    const renderAudienceTile = (data: IAudience) => {
        const PERSONALIZED_FIELDS = [
            { label: 'Offerings', value: data.offerings },
            { label: 'Job Responsibilities', value: data.jobResponsibilities },
            { label: 'Personal Motivators (Emotional)', value: data.personalMotivators },
            { label: 'Business Outcomes', value: data.businessOutcomes },
            { label: 'Pain Points', value: data.frustrationsAndChallenges },
            { label: 'Use Cases', value: data.useCases },
            { label: 'Triggers', value: data.purchaseTriggers },
            { label: 'Measures of Success', value: data.successMeasures },
            { label: 'Common Objection', value: data.commonObjection },
            { label: 'Insight about the person', value: data.personInsight }
        ];

        const NORMAL_FIELDS = [
            { label: AUDIENCE.SEGMENT, value: data.segment },
            { label: AUDIENCE.INDUSTRY, value: data.industry },
            { label: AUDIENCE.COMPANY_PROFILE, value: data.companyName },
            { label: AUDIENCE.TITLE, value: data.title },
            {
                label: AUDIENCE.KEY_PERFORMANCE_INDICATORS,
                value: data.kpis || data.personalityIndicators
            }
            // { label: AUDIENCE.LINKEDIN, value: data.linkedinUrl }
        ];

        if (data.type === 'personalized')
            return [...NORMAL_FIELDS, ...PERSONALIZED_FIELDS].map((audience, key) => (
                <Tile key={key} data={audience} />
            ));

        return NORMAL_FIELDS.map((audience, key) => <Tile key={key} data={audience} />);
    };

    return (
        <div>
            <Header
                ctaTtitle={'Add Audience'}
                handleCtaClick={() => handleShowAudienceForm({}, true)}
            />
            {targetAudienceData
                ?.sort((a, b) => a?.title?.localeCompare(b?.title))
                .map((audience, key) => {
                    const isActiveAudience = activeAudience === audience._id;
                    const orgDetails = userOrgs.find(
                        (uo) => Number(uo.code) === Number(audience.ownerOrgId)
                    );
                    const isAdmin = orgDetails?.role === 'admin';
                    const isOwner = userId === audience.ownerId;
                    const showEditDelete = isOwner || isAdmin;
                    return (
                        <div key={key} className={cx('rounded-md my-2', bgSlate750)}>
                            <div
                                className={cx(
                                    'relative flex items-center justify-between text-lg',
                                    bgSlate800,
                                    'p-2  cursor-pointer hover:bg-slate-900',
                                    textDarkTitleColor,
                                    isActiveAudience ? 'rounded-t bg-slate-900' : 'rounded'
                                )}
                                onClick={() =>
                                    setActiveAudience(
                                        activeAudience === audience._id ? '' : audience._id
                                    )
                                }
                            >
                                {audience?.name || audience?.title}

                                <div
                                    className={cx(
                                        styles.org,
                                        'flex items-center w-64 text-cyan-600'
                                    )}
                                >
                                    <Icomoon className={cx(styles.orgIcon)} icon={'organization'} />
                                    {orgDetails?.name}
                                </div>

                                {isActiveAudience && showEditDelete && (
                                    <div className={cx('flex')}>
                                        <Icomoon
                                            className={styles.edit}
                                            icon={'bin'}
                                            onClick={(e: { stopPropagation: () => void }) => {
                                                setDeleteData({
                                                    title: 'Delete Audience',
                                                    desc: 'Are you sure you want to delete the audience ?'
                                                });
                                                setShowDeleteModal(true);
                                                setDeleteId({
                                                    type: 'audience',
                                                    id: audience._id
                                                });
                                                e.stopPropagation();
                                            }}
                                        />

                                        <Icomoon
                                            className={styles.edit}
                                            icon={'pencil'}
                                            onClick={(e: { stopPropagation: () => void }) => {
                                                handleShowAudienceForm(audience, true);
                                                e.stopPropagation();
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                            {activeAudience === audience._id && (
                                <div className={cx('flex flex-wrap')}>
                                    {renderAudienceTile(audience)}
                                </div>
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

export default React.memo(AudienceTab);
