import React, { useRef } from 'react';
import cx from 'classnames';

//helpers
import { getContentTypeLabel, highlightDifferences } from 'src/shared/utils/helpers';
import { formatMarkdown } from 'src/shared/utils';

//widgets
import { CustomButton, CustomTextArea } from 'src/widgets';
import CustomActionsContainer from 'src/components/CustomActionsContainer';

//styles
import styles from './styles.module.scss';

//defs
import type { IRegeneratedChoice } from '../../ResultTab';
import type { IAudience, IFeedback, IHandleFeedback, IMaxWordLimit } from 'src/defs';

//constants
import { LABELS } from 'src/shared/strings';

//hooks
import useMergeContent from 'src/hooks/useMergeContent';

interface IProps {
    choice: { choices: string[]; type: string };
    targetKey: number;
    pos: number;
    currentTrends?: string;
    regeneratedChoices: IRegeneratedChoice;
    maxWords: IMaxWordLimit;
    audience: IAudience;
    feedback: IFeedback[];
    content: string;
    seoKeywords?: string;
    updateResult: (
        value: string | string[],
        targetKey: number,
        pos: number,
        idx: number,
        type: string,
        saveData?: boolean,
        isMerge?: boolean
    ) => void;
    updateRegenChoices: (value: string, targetKey: number, pos: number, idx: number) => void;
    clearCurrentTrends: (targetKey: number, key: number) => void;
    updateTrends: (data: string, targetKey: number, key: number) => void;
    handleFeedback: (data: IHandleFeedback) => void;
    handleSetCurrentTrends: (data: string, targetKey: number, pos: number) => void;
    handleMaxWords: (maxWords: IMaxWordLimit) => void;
    handleRegenerate: (
        audience: IAudience,
        targetKey: number,
        key: number,
        type: string,
        resultInstructions?: string
    ) => void;
}

const textClass = cx(
    'max-h-[calc(100vh_-_250px)] min-h-[400px] overflow-hidden overflow-y-auto inset-0'
);
const listContainer = cx('flex flex-col justify-between flex-1 min-w-[40%]');

const ListData = ({
    choice,
    targetKey,
    pos,
    currentTrends = '',
    regeneratedChoices,
    maxWords,
    audience,
    feedback,
    content,
    seoKeywords,
    updateRegenChoices,
    updateResult = () => {},
    clearCurrentTrends,
    updateTrends,
    handleFeedback,
    handleSetCurrentTrends,
    handleMaxWords,
    handleRegenerate
}: IProps) => {
    const textref = useRef<HTMLDivElement>(null);
    const mergeContent = useMergeContent();

    const contentFeedback =
        feedback?.find(
            (feed) => feed.contentType === choice?.type && feed.audienceType === audience?.label
        ) || null;

    const allowRegenerate = !regeneratedChoices?.choices?.filter((choice) => choice)?.length;
    const allowCurrentTrends = !currentTrends.length;

    const showAction = !regeneratedChoices?.choices?.[targetKey]?.length;

    const acceptChoiceCta = (
        value: string | string[],
        pos: number,
        idx: number,
        type: string,
        title = LABELS.ACCEPT
    ) => {
        return (
            <CustomButton
                title={title}
                onClick={() => {
                    updateResult(value, targetKey, pos, idx, type, true);
                }}
            />
        );
    };

    const handleMergeCta = async (
        value: string | string[],
        pos: number,
        idx: number,
        type: string
    ) => {
        if (typeof value !== 'string') {
            value.splice(
                value.length - 1,
                1,
                value[value.length - 1] + '<br /> <br />' + currentTrends
            );
            updateResult(value, targetKey, pos, idx, type, true, true);
            return;
        }

        if (!value || !currentTrends) return;

        let mergedContent = await mergeContent({ content: value, currentTrends });
        updateResult(mergedContent, targetKey, pos, idx, type, true, true);
    };

    const clearCta = () => {
        return (
            <CustomButton
                className="mx-4"
                title={LABELS.CLEAR}
                onClick={() => clearCurrentTrends(targetKey, pos)}
            />
        );
    };

    const renderTextBox = (
        content: string,
        targetKey: number,
        pos: number,
        idx: number,
        type: string,
        noClap?: boolean,
        onRegenChange?: (value: string, targetKey: number, pos: number, idx: number) => void
    ) => {
        return (
            <CustomTextArea
                value={content}
                showCount={false}
                onChange={(value: string) =>
                    onRegenChange
                        ? onRegenChange(value, targetKey, pos, idx)
                        : updateResult(value, targetKey, pos, idx, type)
                }
                noClap={noClap}
                textClass={textClass}
            />
        );
    };

    const renderBulletList = (list: string[], targetKey: number, pos: number, type: string) => {
        let rgeneratedList = regeneratedChoices?.choices || [];

        return (
            <div className={'flex flex-col felx-wrap justify-between flex-1'}>
                {list.map((choice, idx) => {
                    return (
                        <div key={idx} className={cx('flex')}>
                            {renderChoice(choice, targetKey, pos, idx, type)}
                            {!!rgeneratedList?.length &&
                                !!rgeneratedList[idx]?.length &&
                                renderChoice(
                                    rgeneratedList[idx],
                                    targetKey,
                                    pos,
                                    idx,
                                    type,
                                    rgeneratedList[idx],
                                    true,
                                    updateRegenChoices
                                )}
                            {idx === 0 && !!currentTrends && (
                                <div
                                    className={'w-[40%] flex-1 pr-4 flex flex-col justify-between'}
                                >
                                    <CustomTextArea
                                        value={formatMarkdown(currentTrends)}
                                        showCount={false}
                                        onChange={(value: string) =>
                                            updateTrends(value, targetKey, pos)
                                        }
                                        noClap
                                        textClass={textClass}
                                    />
                                    <div className={cx('flex justify-center', 'my-2')}>
                                        {clearCta()}
                                        <CustomButton
                                            title={LABELS.MERGE}
                                            onClick={() => handleMergeCta(list, pos, 0, type)}
                                        />
                                    </div>
                                </div>
                            )}
                            {renderActions(choice, idx !== 0)}
                        </div>
                    );
                })}
            </div>
        );
    };
    const renderChoice = (
        choice: string,
        targetKey: number,
        pos: number,
        idx: number,
        type: string,
        str2?: string,
        noClap?: boolean,
        onRegenChange?: (value: string, targetKey: number, pos: number, idx: number) => void
    ) => {
        choice = formatMarkdown(choice);

        return (
            <div className={cx(listContainer, 'pr-4')}>
                <div
                    className={styles.listDataItem}
                    style={{ minHeight: textref?.current?.offsetHeight }}
                >
                    {renderTextBox(choice, targetKey, pos, idx, type, noClap, onRegenChange)}
                </div>
                {!!regeneratedChoices?.choices[idx]?.length && (
                    <div className={cx('flex justify-center', 'my-2')}>
                        {acceptChoiceCta(str2 ? str2 : choice, pos, idx, type)}
                    </div>
                )}
            </div>
        );
    };

    const renderSingleChoice = (choice: string, targetKey: number, pos: number, type: string) => {
        let regeneratedChoice = regeneratedChoices?.choices[0] || '';

        let str1 = choice;
        let str2 = regeneratedChoice;
        regeneratedChoice = highlightDifferences(str1, regeneratedChoice, styles.diff);
        return (
            <div className={'flex felx-wrap justify-between flex-1'}>
                {renderChoice(choice, targetKey, pos, 0, type)}
                {!!regeneratedChoice?.length &&
                    renderChoice(
                        regeneratedChoice,
                        targetKey,
                        pos,
                        0,
                        type,
                        str2,
                        true,
                        updateRegenChoices
                    )}
                {currentTrends && (
                    <div className={'flex flex-1 pr-4 flex-col justify-between'}>
                        <CustomTextArea
                            value={formatMarkdown(currentTrends)}
                            showCount={false}
                            onChange={(value: string) => updateTrends(value, targetKey, pos)}
                            noClap
                            textClass={textClass}
                        />

                        <div className={cx('flex justify-center', 'my-2')}>
                            {clearCta()}
                            <CustomButton
                                title={LABELS.MERGE}
                                onClick={() => handleMergeCta(choice, pos, 0, type)}
                            />
                        </div>
                    </div>
                )}
                {renderActions(str1)}
            </div>
        );
    };

    const renderActions = (shareData: string, hideActions?: boolean) => {
        return (
            <CustomActionsContainer
                choiceType={choice.type}
                maxWords={maxWords}
                allowRegenerate={allowRegenerate}
                showAllCtas={true}
                allowCurrentTrends={allowCurrentTrends}
                showAction={showAction}
                targetKey={targetKey}
                pos={pos}
                content={content}
                seoKeywords={seoKeywords || ''}
                contentFeedback={contentFeedback}
                shareData={shareData}
                audienceLabel={audience?.label}
                handleFeedback={handleFeedback}
                handleSetCurrentTrends={handleSetCurrentTrends}
                handleMaxWords={handleMaxWords}
                handleRegenerate={() => handleRegenerate(audience, targetKey, 0, choice.type)}
                hideActions={hideActions}
            />
        );
    };

    return (
        <div className={cx(styles.listData)}>
            <h1 className={'text-xl font-semibold'}>{getContentTypeLabel(choice.type)}</h1>
            <div className={cx('flex flex-1 flex-wrap justify-between')}>
                <div className={styles.listDataText}>
                    {choice?.choices.length > 1
                        ? renderBulletList(choice?.choices, targetKey, pos, choice?.type)
                        : renderSingleChoice(choice?.choices[0], targetKey, pos, choice?.type)}
                </div>
            </div>
        </div>
    );
};

export default React.memo(ListData);
