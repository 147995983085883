import React from 'react';
import cx from 'classnames';

//constants
import { ctaStyle } from 'src/shared/styles/colors';

interface IProps {
    ctaTtitle: string;
    handleCtaClick: VoidFunction;
}

const Header = ({ ctaTtitle, handleCtaClick }: IProps) => {
    return (
        <div className={cx('flex justify-end', 'sticky top-0 bg-black py-2 z-10')}>
            <div
                className={cx(
                    'py-1 px-4 my-2 shadow rounded-xl shadow text-center cursor-pointer',
                    ctaStyle
                )}
                onClick={handleCtaClick}
            >
                {ctaTtitle}
            </div>
        </div>
    );
};

export default React.memo(Header);
