import React from 'react';
import cx from 'classnames';

//styles
import styles from './Sidebar.module.scss';

//constants
import { STRINGS } from 'src/shared/strings';
import { CONTENT_TYPE, PERSONA_TONE_OPTIONS } from 'src/shared/constants';
import { themeBorder } from 'src/shared/styles/colors';

//widgets
import { RadioTitle } from 'src/components/RadioTitle';
import { CheckTitle } from 'src/components/CheckTitle';
import { CustomInput } from 'src/widgets';

//helpers
import { useSelector } from 'react-redux';

//defs
import type { IAudience, IPersona } from 'src/defs';

interface IProps {
    data: {
        file: any;
        toneRegister: string;
        mType?: string;
        orgCode?: string;
        orgName?: string;
        contentType: string[];
        persona: IPersona;
        audience: IAudience[];
    };
    errors: {
        campaignOrg: string;
        audience: string;
        persona: string;
        toneOutlook: string;
        contentType: string;
    };
    personas: IPersona[];
    audience: IAudience[];
    handleToneChange: (tone: { value: string }) => void;
    handleOrgUpdate: (e: any) => void;
    handleOutputSelect: (item: { value: string }) => void;
    handleSelectAudience: () => void;
    handleSelectPersona: (perosna: IPersona) => void;
}

const Sidebar = ({
    data,
    errors,
    personas,
    audience,
    handleToneChange,
    handleOrgUpdate,
    handleOutputSelect,
    handleSelectAudience,
    handleSelectPersona
}: IProps) => {
    const userOrgs = useSelector((state: any) => state.user.userDetails?.orgs) || [];

    return (
        <div className={cx(styles.sidebar, themeBorder, '[&&]:border-0 [&&]:border-r')}>
            <RadioTitle
                heading={STRINGS.TONE_REGISTER_TYPE}
                headingTooltip={STRINGS.TONE_REGISTER_TYPE_TOOLTIP}
                data={PERSONA_TONE_OPTIONS}
                value={data.toneRegister}
                onSelect={handleToneChange}
                error={errors?.toneOutlook}
            />

            <RadioTitle
                heading={STRINGS.WRITER_PERSONA}
                headingTooltip={STRINGS.PERSONA_TOOLTIP}
                data={personas.map((persona) => ({ label: persona.name, value: persona.name }))}
                value={data.persona.name}
                onSelect={(persona) =>
                    handleSelectPersona(
                        personas.find((pr) => pr.name === persona.value) as IPersona
                    )
                }
                error={errors?.persona}
            />

            <CheckTitle
                heading={'Output Type'}
                headingTooltip={STRINGS.SELECT_CONTENT_TYPE_TOOLTIP}
                data={CONTENT_TYPE}
                error={errors.contentType}
                onSelect={handleOutputSelect}
                checked={data.contentType.map((content) => ({
                    label: content,
                    value: content
                }))}
                // errorRef={(el) => (errorRefs.current['contentType'] = el)}
            />

            <CheckTitle
                heading={STRINGS.AUDIENCE_TYPE}
                headingTooltip={''}
                data={audience}
                error={errors.audience}
                onSelect={handleSelectAudience}
                checked={data.audience}
                // errorRef={(el) => (errorRefs.current['contentType'] = el)}
            />

            <CustomInput
                id={'campaignOrg'}
                label={STRINGS.ORG}
                inputClass={styles.mainInput}
                value={{ label: data.orgName || '', value: data.orgCode || '' }}
                onChange={handleOrgUpdate}
                error={errors.campaignOrg}
                inputType={'select'}
                labelClass={styles.label}
                selectOptions={[
                    ...userOrgs.map((org: { name: string; code: string }) => ({
                        label: org.name,
                        value: org.code
                    }))
                ]}
                placement={'bottom'}
                // tooltipText={field.tooltip}
                isMulti={false}
            />
        </div>
    );
};

export default React.memo(Sidebar);
