const styles = {
    files: `flex w-full overflow-hidden overflow-x-auto p-2 scrollbar-none`,
    file: `relative flex items-center
        border-[1px] bg-slate-900 border-slate-800 py-2 px-4 mr-3 rounded-lg
        w-80 min-w-[280px] text-white`,
    fileIcon: 'text-4xl mr-2',
    fileText: 'overflow-hidden whitespace-nowrap',
    fileTextTitle: 'font-semibold text-sm overflow-hidden text-ellipsis',
    fileTextType: 'text-sm text-gray-300',
    fileRemove: `cursor-pointer z-10 absolute right-1 top-1 -translate-y-1/2 translate-x-1/2 
        rounded-full border-[2px] border-slate-800 p-[3px] text-xs text-black bg-white`,
    disabled: 'pointer-none'
};
export default styles;
