import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { IResearhInput, IScenario } from 'src/defs';

interface IResearchRes {
    scenarios: IScenario[];
}

const headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': 'true'
};

let token: string = '';

export const researchApi = createApi({
    reducerPath: 'researchApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/research',
        timeout: 60000,
        prepareHeaders: (headers, { getState }) => {
            // Specify RootState to ensure proper typing
            const state = getState() as any;
            token = state.user.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        startResearch: builder.mutation<{ status: string; job_id: string }, IResearhInput>({
            query: (payload: IResearhInput) => {
                return {
                    method: 'POST',
                    headers: { ...headers },
                    url: `/`,
                    body: payload
                };
            }
        }),
        getResarchData: builder.query<{ data: IResearchRes; status: string }, { job_id: string }>({
            query: (payload: { job_id: string }) => {
                return {
                    method: 'Get',
                    headers: { ...headers },
                    url: `/${payload.job_id}`
                };
            }
        })
    })
});

// Exporting hooks generated by the API
export const { useStartResearchMutation, useLazyGetResarchDataQuery } = researchApi;
