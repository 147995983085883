//lib
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

//defs
import type { ISidebarChips } from 'src/defs';
import type { RootState } from 'src/redux/store';

//hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';

//constants
import { ROUTES } from 'src/shared/constants/routes';
import { STRINGS } from 'src/shared/strings';
import {
    ICON_ADD,
    ICON_BOOK,
    ICON_CONSTRUCTION,
    ICON_CONTACTS,
    ICON_FOLDER,
    ICON_HOME,
    ICON_LAYERS,
    ICON_ORGANIZATION
} from 'src/shared/constants/icons';
import { textDarkColor, themeBorder } from 'src/shared/styles/colors';

//styles
import styles from './styles.module.scss';

//assets
import DEFAULT_USER from 'src/assets/icons/user.svg';

interface IProps {
    setShowAddCampaignModal: (value: boolean) => void;
}

const chipContainer = cx('border-b-0 mb-4 relative', styles.chip);
const chipCta = cx(
    'flex items-center',
    'py-2 px-2',
    'text-base font-medium',
    'cursor-pointer rounded-lg',
    styles.chipCta
);
const chipIcon = cx(styles.chipIcon, 'text-[20px] flex items-center');

const darkContainer = cx('[&&]:bg-slate-800', themeBorder, `border-0 border-r-[2px]`);

const AppSidebar = ({ setShowAddCampaignModal }: IProps) => {
    const handleNavigation = useCustomNavigation();
    const loc = useLocation();

    const config = useSelector((state: RootState) => state.config);
    const user = useSelector((state: RootState) => state.user);
    const userDetails = user.userDetails;

    const darkText = textDarkColor;

    const [fullSidebar, setFullSidebar] = useState(true);

    useEffect(() => {
        if (loc?.pathname !== ROUTES.HOME) {
            setFullSidebar(false);
        } else {
            setFullSidebar(true);
        }
    }, [loc]);

    const gotToProfile = () => {
        handleNavigation(ROUTES.PROFILE);
    };

    const gotToHome = () => {
        handleNavigation(ROUTES.HOME);
    };

    const goToOrg = () => {
        handleNavigation(ROUTES.ORG);
    };

    const goToContacts = () => {
        handleNavigation(ROUTES.CONTACT_DETAILS);
    };

    const handleClick = (route: string) => {
        handleNavigation(route);
    };

    const AppLogo = () => {
        return (
            <div
                onClick={gotToHome}
                className={cx(
                    styles.containerTitle,
                    {
                        [styles.containerTitleSmall]: !fullSidebar
                    },
                    darkText
                )}
                aria-label={STRINGS.HOME}
            >
                {fullSidebar ? (
                    <h1>
                        <span className={cx(ICON_HOME, 'mr-2 text-xl')} />
                        {STRINGS.HOME}
                    </h1>
                ) : (
                    <div className={cx(ICON_HOME, 'mr-2 text-xl')} />
                )}
            </div>
        );
    };

    const PlayBook = () => {
        return (
            <div
                className={cx(
                    'mt-7',
                    {
                        [styles.containerTitleSmall]: !fullSidebar
                    },
                    darkText
                )}
                aria-label={'Playbook'}
            >
                {fullSidebar && (
                    <h1 className={cx(styles.containerTitle, '[&&]:cursor-auto')}>
                        <span className={cx(ICON_BOOK, 'mr-2 text-xl')} />
                        Playbook
                    </h1>
                )}
                <div className={cx(fullSidebar && 'pl-4', 'text-center')}>
                    <Chips
                        icon={ICON_ORGANIZATION}
                        path={ROUTES.ORG}
                        title={STRINGS.ORG}
                        handleClick={goToOrg}
                    />
                    <Chips
                        icon={ICON_CONTACTS}
                        path={ROUTES.CONTACT_DETAILS}
                        title={STRINGS.CONTACTS}
                        handleClick={goToContacts}
                        disabled={!config.enableContacts}
                    />
                    <Chips
                        icon={ICON_FOLDER}
                        path={ROUTES.ASSETS}
                        title={STRINGS.ASSETS}
                        handleClick={() => handleClick(ROUTES.ASSETS)}
                        disabled={!config.enableContacts}
                    />
                    <Chips
                        icon={ICON_LAYERS}
                        path={ROUTES.PRESETS}
                        title={STRINGS.PRESETS}
                        handleClick={() => handleClick(ROUTES.PRESETS)}
                        disabled={!config.enableContacts}
                    />
                </div>
            </div>
        );
    };

    const UserChip = () => {
        return (
            <div
                className={cx(
                    styles.user,
                    { [styles.userSmall]: !fullSidebar },
                    darkText,
                    'bg-slate-900',
                    themeBorder
                )}
                onClick={gotToProfile}
            >
                <div className={cx(styles.userImage, { [styles.userSmallImage]: !fullSidebar })}>
                    <img
                        className={styles.userImagePic}
                        src={userDetails?.picture || DEFAULT_USER}
                        alt=""
                    />
                </div>
                {fullSidebar && (
                    <div className={styles.userDetail}>
                        <div className={styles.userDetailName}>
                            {userDetails?.name?.split(' ')[0]}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const AddCampaign = () => {
        return (
            <div
                className={cx(
                    'mt-6',
                    styles.containerTitle,
                    {
                        [styles.containerTitleSmall]: !fullSidebar
                    },
                    darkText
                )}
                aria-label={'Add'}
                onClick={() => setShowAddCampaignModal(true)}
            >
                <h1>
                    <span className={cx(ICON_ADD, 'mr-2 [&&]:font-extrabold text-xl')}></span>
                    {fullSidebar && <span>{'Add'}</span>}
                </h1>
            </div>
        );
    };

    const Chips = ({
        icon,
        path,
        title,
        handleClick,
        classname,
        disabled = false
    }: ISidebarChips) => {
        return (
            <div className={cx(chipContainer, darkText)}>
                <div
                    className={cx(
                        classname,
                        chipCta,
                        'hover:bg-slate-700',
                        {
                            [styles.chipActive]: loc.pathname?.includes(path)
                        },
                        disabled && 'pointer-events-none text-gray-400'
                    )}
                    onClick={handleClick}
                >
                    <div className={cx(chipIcon, icon)} />
                    <h1>{fullSidebar && title}</h1>
                </div>
                {disabled && (
                    <div
                        className={cx(
                            'absolute top-0 right-[-4px]',
                            '[&&]:text-lg [&&]:font-medium text-green-600',
                            ICON_CONSTRUCTION
                        )}
                    />
                )}
            </div>
        );
    };

    return (
        <div
            className={cx(
                styles.container,
                { [styles.containerSmall]: !fullSidebar },
                darkContainer
            )}
        >
            <div className="w-full">
                <AppLogo />
                <PlayBook />
                <AddCampaign />
                {/* <Chips
                    icon={ICON_CASE_STUDY}
                    path={ROUTES.CASE_STUDIES}
                    title={STRINGS.CASE_STUDIES}
                    handleClick={goToCaseStudy}
                    classname={cx(
                        'mt-6 pl-0 flex items-center',
                        '[&&]:hover:bg-slate-800',
                        styles.containerTitle,
                        {
                            [styles.containerTitleSmall]: !fullSidebar
                        },
                        !fullSidebar && `pl-2`,
                        !fullSidebar,
                        darkText
                    )}
                /> */}
            </div>

            <div className="w-full">
                <UserChip />
            </div>
        </div>
    );
};

export default React.memo(AppSidebar);
