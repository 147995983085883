import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

//styles
import styles from './OnboardingForm.module.scss';

//widgets
import { CustomButton, CustomInput } from 'src/widgets';

//constants
import { LABELS, STRINGS } from 'src/shared/strings';
import { ORG_CTA_OPTIONS, ORG_INDSUTRY_OPTIONS } from 'src/shared/constants';
import { ICON_LINK, ICON_LOCATION } from 'src/shared/constants/icons';
import { bgSlate800 } from 'src/shared/styles/colors';

//helpers
import { extractFileNames, formatFormValue, validateForm } from './helpers';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { toast } from 'react-toastify';
import { sanitizeFileNames } from 'src/shared/utils';
import { updateSegments } from 'src/redux/reducers/taxonomy';

//apis
import { useAddOrgMutation, useUpdateOrgMutation, useUploadFileMutation } from 'src/redux/queries';
import { useLazyGetAudienceSegmentsQuery } from 'src/redux/queries/Audience';
import { useAddContentMutation, useUpdateContentMutation } from 'src/redux/queries/Content';
import { useGenerateEmbeddingsMutation } from 'src/redux/queries/Embeddings';

//hooks
import { useDispatch, useSelector } from 'react-redux';

//defs
import { IOrg } from 'src/defs';

interface IErrors {
    name?: string;
    industry?: string;
    audienceSegments?: string;
    websiteUrl?: string;
    targetAudiences?: string;
    successfulCampaigns?: string;
    address?: string;
    geographies?: string;
    ctas?: string;
}

interface IData
    extends Omit<
        IOrg,
        | 'successfulCampaigns'
        | 'underperformingCampaigns'
        | 'geographies'
        | 'targetAudiences'
        | 'industry'
        | 'audienceSegments'
        | 'ctas'
    > {
    successfulCampaigns: string;
    underperformingCampaigns: string;
    geographies: string;
    targetAudiences: string;
    industry: { label: string; value: string }[];
    audienceSegments: { label: string; value: string }[];
    ctas: { label: string; value: string }[];
}

interface IProps {
    data: IData;
    isEditing: boolean;
    isViewing: boolean;
    handleSuccess: (data: IOrg) => void;
    inputClass?: string;
    isDefaultOrg?: boolean;
}

interface IRefs {
    [key: string]: any;
}

const OnboardingForm = ({
    data,
    isEditing = false,
    isViewing = false,
    handleSuccess,
    inputClass = '',
    isDefaultOrg
}: IProps) => {
    const dispatch = useDispatch();
    const taxonomySegments = useSelector((state: any) => state.taxonomy.segments) || [];

    const [organizationData, setOrganizationData] = useState<IData>({
        name: '',
        websiteUrl: '',
        targetAudiences: '',
        successfulCampaigns: '',
        address: '',
        geographies: ''
    } as IData);
    const [errors, setErrors] = useState<IErrors>({
        name: '',
        industry: ''
    });

    const [segmentOptions, setSegmentOptions] = useState<{ value: string; label: string }[]>([]);

    const [campaignFiles, setCampaignFiles] = useState({
        successfulCampaigns: [],
        underperformingCampaigns: []
    });

    const inputRefs: IRefs = {
        name: useRef(null),
        industry: useRef(null),
        audienceSegments: useRef(null),
        websiteUrl: useRef(null),
        targetAudiences: useRef(null),
        successfulCampaigns: useRef(null),
        underperformingCampaigns: useRef(null),
        address: useRef(null),
        geographies: useRef(null),
        ctas: useRef(null)
    };

    const [addOrg] = useAddOrgMutation();
    const [updateOrg] = useUpdateOrgMutation();
    const [uploadFile] = useUploadFileMutation();
    const [getAudienceSegments] = useLazyGetAudienceSegmentsQuery();
    const [addContent] = useAddContentMutation();
    const [updateContent] = useUpdateContentMutation();
    const [generateEmbeddings] = useGenerateEmbeddingsMutation();

    useEffect(() => {
        if (!isViewing && !taxonomySegments?.length) {
            fetchSegments();
        } else {
            setSegmentOptions(taxonomySegments);
        }

        return () => {
            setSegmentOptions([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isViewing]);

    useEffect(() => {
        if (data) {
            let successfulCampaigns = extractFileNames(data?.successfulCampaigns);
            let underperformingCampaigns = extractFileNames(data?.underperformingCampaigns);
            setCampaignFiles({
                ...campaignFiles,
                underperformingCampaigns,
                successfulCampaigns
            });
            let SC = data?.successfulCampaigns
                ?.split(',')
                ?.filter((e) => !e?.includes('clap'))
                // .filter(Boolean)
                .join(', ');

            let UC = data?.underperformingCampaigns
                ?.split(',')
                ?.filter((e) => !e?.includes('clap'))
                // .filter(Boolean)
                .join(', ');

            setOrganizationData({ ...data, successfulCampaigns: SC, underperformingCampaigns: UC });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const fetchSegments = async () => {
        try {
            await getAudienceSegments(null)
                .unwrap()
                .then((data) => {
                    if (data?.data?.length) {
                        dispatch(updateSegments(data.data));
                        let options = data?.data?.map((d: string) => ({ label: d, value: d }));
                        setSegmentOptions(options);
                        return;
                    }
                });
        } catch (err) {
            console.log(err);
        }
    };

    const handleChange = (e: any) => {
        const { id, value } = e.target;
        setOrganizationData((prevData) => ({ ...prevData, [id]: value }));
        setErrors({ ...errors, [id]: '' });
    };

    const processCampaignFiles = async (campaignFiles: any[]) => {
        const files = campaignFiles || [];

        if (files.length) {
            const newFiles = [...files];

            const filesToUpload = newFiles.filter((file) => file instanceof File);
            const preLinks = newFiles
                .filter((file) => !(file instanceof File))
                .map((file) => file.url || '');

            let fileUrls;
            if (filesToUpload.length) {
                fileUrls = (await uploadFile({
                    files: filesToUpload,
                    filePath: 'org'
                })) as { data: { urls: string[] } };
            }

            const urls = fileUrls?.data?.urls || [];
            return [...preLinks, ...urls];
        }

        return [];
    };

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        try {
            dispatch(startLoading());
            e.preventDefault();

            const errors = validateForm(organizationData);
            setErrors({ ...errors });
            if (errors && Object.values(errors).length) {
                const key = Object.keys(errors)[0];
                inputRefs[key]?.current?.focus();
                return;
            }
            let data = formatFormValue(organizationData) as IOrg;

            let SC = await processCampaignFiles(campaignFiles.successfulCampaigns);
            data.successfulCampaigns = [...data.successfulCampaigns, ...SC];

            let UC = await processCampaignFiles(campaignFiles.underperformingCampaigns);
            data.underperformingCampaigns = [...data.underperformingCampaigns, ...UC];

            const contentPayload = {
                filters: {
                    asset: [
                        {
                            files: [
                                ...data.successfulCampaigns?.map((sc) => ({
                                    ...extractFileNames(sc)[0]
                                }))
                            ],
                            type: 'pdf'
                        }
                    ]
                },
                orgCode: data.code,
                orgName: data?.name
            };

            if (isEditing) {
                await updateOrg({ ...data, isDefault: !!isDefaultOrg })
                    .unwrap()
                    .then((res) => {
                        toast.success(res?.message);
                        handleSuccess(data);
                    })
                    .then(async () => {
                        if (!data.contentId) {
                            await addContent({ ...contentPayload })
                                .unwrap()
                                .then(async (content) => {
                                    await updateOrg({
                                        code: data.code,
                                        contentId: content?.data?._id
                                    });
                                    handleSuccess({ ...data, contentId: content?.data?._id });
                                    await generateEmbeddings({
                                        objectId: content?.data?._id,
                                        orgId: data.code
                                    });
                                });
                        } else {
                            await updateContent({
                                id: data.contentId,
                                data: { ...contentPayload }
                            })
                                .unwrap()
                                .then(
                                    async () =>
                                        await generateEmbeddings({
                                            objectId: data.contentId,
                                            orgId: data.code
                                        })
                                );
                        }
                    })
                    .catch((e) => {
                        toast.error(e?.data?.message);
                    });
            } else {
                let newOrgData = {} as IOrg;
                await addOrg(data)
                    .unwrap()
                    .then((res) => {
                        newOrgData = res?.data;
                        toast.success(res?.message);
                    })
                    .then(async () => {
                        await addContent({ ...contentPayload, orgCode: newOrgData.code })
                            .unwrap()
                            .then(async (content) => {
                                await updateOrg({
                                    code: newOrgData?.code,
                                    contentId: content?.data?._id
                                });
                                newOrgData = { ...newOrgData, contentId: content?.data?._id };
                                await generateEmbeddings({
                                    objectId: content?.data?._id,
                                    orgId: newOrgData?.code
                                });
                            })
                            .catch((e) => {
                                toast.error(e?.data?.message);
                            });
                    })
                    .then(() => {
                        handleSuccess({ ...newOrgData });
                    })
                    .catch((e) => {
                        toast.error(e?.data?.message);
                    });
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleUpload = (files = [], id: string) => {
        setCampaignFiles({ ...campaignFiles, [id]: files });
    };

    const renderInput = () => {
        return [
            {
                label: LABELS.ORG_NAME,
                value: organizationData.name,
                id: 'name',
                error: errors.name,
                placeholder: 'eg. Acme Corp'
            },
            {
                label: LABELS.INDUSTRY,
                value: organizationData.industry,
                id: 'industry',
                error: errors.industry,
                placeholder: 'eg. Technology',
                tooltipText: 'Specify your industry',
                inputType: 'select',
                selectOptions: ORG_INDSUTRY_OPTIONS.sort((a, b) => a.value.localeCompare(b.value)),
                placement: 'bottom'
            },
            {
                label: LABELS.AUDIENCE_SEGMENTS,
                value: organizationData.audienceSegments,
                id: 'audienceSegments',
                error: errors.audienceSegments,
                placeholder: 'e.g Federal, State, Local',
                inputType: 'select',
                selectOptions: [...segmentOptions]?.sort((a, b) =>
                    a?.value?.localeCompare(b?.value)
                ),
                tooltipText: 'Specify your audience segments',
                placement: 'bottom',
                isMulti: false
            },
            {
                label: STRINGS.WEB_SITE_URL,
                value: organizationData.websiteUrl,
                id: 'websiteUrl',
                error: errors.websiteUrl,
                icon: ICON_LINK,
                placeholder: 'e.g. https://example.com'
            },
            {
                label: LABELS.TARGET_AUDIENCES,
                value: organizationData.targetAudiences,
                id: 'targetAudiences',
                error: errors.targetAudiences,
                placeholder: 'e.g. Marketing managers, C-level executives',
                inputType: 'textarea',
                tooltipText:
                    'Specify as much detail as possible on their level and function. E.g. Director, Senior Director, and VP of IT security'
            },
            {
                label: LABELS.SUCCESSFUL_CAMPAIGN_URLS,
                value: organizationData.successfulCampaigns,
                id: 'successfulCampaigns',
                error: errors.successfulCampaigns,
                icon: ICON_LINK,
                placeholder: 'e.g. https://example.com',
                inputType: 'textarea',
                fileUpload: true,
                files: campaignFiles.successfulCampaigns,
                tooltipText:
                    'Upload 3-10 materials (PDFs, documents, emails) that exemplify your brand. We will try our best to emulate your brand guidelines.'
            },
            // {
            //     label: LABELS.UNDER_PERFORMING_URLS,
            //     value: organizationData.underperformingCampaigns,
            //     id: 'underperformingCampaigns',
            //     error: errors.underperformingCampaigns,
            //     icon: ICON_LINK,
            //     placeholder: 'e.g. https://example.com',
            //     inputType: 'textarea',
            //     fileUpload: true,
            //     files: campaignFiles.underperformingCampaigns
            // },
            {
                label: STRINGS.ADDRESS_DESC,
                value: organizationData.address,
                id: 'address',
                error: errors.address,
                icon: ICON_LOCATION,
                placeholder: '32, My Street, Kingston, New York 12401',
                inputType: 'textarea',
                tooltipText: 'Specify region or country'
            },
            {
                label: STRINGS.GEO_DESC,
                value: organizationData.geographies,
                id: 'geographies',
                error: errors.geographies,
                placeholder: 'e.g. North America, Europe',
                inputType: 'textarea',
                tooltipText: 'Specify region or country'
            },
            {
                label: STRINGS.COMMON_CTAS,
                value: organizationData.ctas,
                id: 'ctas',
                error: errors.ctas,
                inputType: 'select',
                selectOptions: ORG_CTA_OPTIONS.sort((a, b) => a.value.localeCompare(b.value)),
                placeholder: '',
                tooltipText:
                    'What are common campaign goals for you? E.g., meetings, downloads, trials, etc.'
            }
        ].map((field: any, key) => {
            if (isViewing) {
                return (
                    <div key={key} className={cx(styles.tile, bgSlate800 ,'w-80 rounded shadow m-4 p-2')}>
                        <div className={cx('text-lg mb-2 ')}>{field?.label}</div>
                        <div className={cx('font-medium')}>
                            {field?.value instanceof Array
                                ? field?.value?.map((v: any) => v.value)?.join(', ')
                                : field?.value?.value ||
                                  field?.value ||
                                  (!field?.files?.length && '--')}
                            {field?.files?.length
                                ? `${field?.value ? ', ' : ''}${field?.files?.map(
                                      (f: { name: string }) => {
                                          let fileName = sanitizeFileNames(f.name);
                                          return fileName?.length > 32
                                              ? `${fileName.slice(0, 32).trim()}... `
                                              : `${fileName} `;
                                      }
                                  )}`
                                : ''}
                        </div>
                    </div>
                );
            }
            return (
                <CustomInput
                    key={key}
                    label={field.label}
                    value={field.value}
                    id={field.id}
                    onChange={handleChange}
                    className={inputClass}
                    inputClass={styles.formInput}
                    labelClass={styles.formLabel}
                    error={field.error}
                    inputProps={{
                        ref: inputRefs[field.id],
                        placeholder: field.placeholder
                    }}
                    icon={field.icon}
                    inputType={field.inputType}
                    selectOptions={field.selectOptions}
                    max={400}
                    fileUpload={field.fileUpload}
                    files={field.files}
                    handleUpload={handleUpload}
                    readonly={isViewing}
                    tooltipText={field.tooltipText}
                    placement={field.placement}
                    isMulti={field.isMulti}
                    autoFocus={key === 0}
                />
            );
        });
    };

    return (
        <div className={styles.form}>
            <div className={cx(styles.formWrapper, 'flex-wrap')}>{renderInput()}</div>

            {!isViewing && (
                <div className={styles.formSubmit}>
                    <CustomButton
                        className={styles.formSubmitCta}
                        title={LABELS.SUBMIT}
                        onClick={handleSubmit}
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(OnboardingForm);
