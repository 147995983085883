//libs
import React, { useEffect, useRef, useState } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import HardBreak from '@tiptap/extension-hard-break';
import Highlight from '@tiptap/extension-highlight';
import cx from 'classnames';

//styles
import './TextArea.scss';

//widgets
import Commands from './Command';
import SuggestionItems from './SuggestionItems';
import RenderSuggestionItems from './RenderSuggestionItems';
import { CustomInput } from 'src/widgets';

//hook
import useRephrase from 'src/hooks/useRephrase';

//constants
import { STRINGS } from 'src/shared/strings';
import { bgSlate800, ctaStyle, errorColor, themeBorder } from 'src/shared/styles/colors';

interface IProps {
    content: string;
    onChange: (value: string) => void;
    noClap: boolean;
    textClass: string;
}

function TextArea({ content, onChange, noClap, textClass }: IProps) {
    const getRephrasedText = useRephrase();

    const [instructions, setInstructions] = useState('');
    const [error, setError] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    const editor = useEditor({
        extensions: [
            StarterKit,
            HardBreak,
            Highlight.configure({ multicolor: true }),
            Placeholder.configure({
                placeholder: 'Use / for AI'
            }),
            Commands.configure({
                suggestion: {
                    items: SuggestionItems,
                    render: RenderSuggestionItems
                } as any
            })
        ],
        content,
        onUpdate: ({ editor }) => {
            onChange(editor.getHTML());
        }
    });

    useEffect(() => {
        if (content !== editor?.getHTML()) {
            editor?.commands.setContent(content);
        }
    }, [content, editor]);

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.onfocus = () => {
                editor?.commands?.setHighlight({ color: '#FF0000' });
            };

            inputRef.current.onblur = () => {
                editor?.commands?.unsetHighlight();
            };
        }
    }, [inputRef, editor]);

    const highlightMenuItem = () => {
        return cx('px-2 py-1 rounded hover:shadow [&&]:text-sm', ctaStyle);
    };

    const handleInstructionsChange = (e: { target: { value: any } }) => {
        setError('');
        const { value } = e?.target;
        setInstructions(value);
    };

    const onRephrase = async () => {
        if (!instructions) {
            setError('Empty instructions');
            return;
        }

        const from = editor?.view?.state?.selection?.$anchor?.pos || 0;
        const to = editor?.view?.state?.selection?.$head?.pos || 0;

        const highlightedText = editor?.state?.doc?.textBetween(from, to);
        const paragraph = editor?.getHTML() || '';

        let rephrasedText = await getRephrasedText(highlightedText, paragraph, instructions);
        if (rephrasedText) {
            editor?.commands.setContent(rephrasedText);
            onChange(rephrasedText);
            setInstructions('');
        }
    };

    const handleInstructionFocus = () => {
        editor?.chain()?.toggleHighlight()?.run();
    };

    const handleInstructionBlur = () => {
        editor?.chain()?.toggleHighlight()?.run();
    };

    return (
        <div className="flex flex-row min-h-min justify-center items-center">
            <div
                className={cx(
                    'flex flex-col justify-between',
                    'rounded-xl shadow w-[50%] w-full',
                    bgSlate800,
                    themeBorder,
                    'text-slate-300',
                    textClass
                )}
            >
                <div className={cx('mb-[150px]')}>
                    <EditorContent editor={editor} />

                    {!noClap && (
                        <p className="p-3 text-right text-sm italic text-gray-400 font-medium">
                            {"Highlight the text for paraphrasing; use '/' for commands."}
                        </p>
                    )}
                </div>

                <div className={cx('absolute left-0 right-0 bottom-[-8px]')}>
                    <CustomInput
                        inputType="textarea"
                        className={cx('[&&]:pb-0')}
                        inputClass={cx(
                            '[&&]:bg-slate-900 resize-none h-[150px] [&&]:pb-6 [&&]:pr-[90px]'
                        )}
                        customCss={{ padding: 0, border: 0 }}
                        value={instructions}
                        onChange={handleInstructionsChange}
                        inputProps={{
                            placeholder: 'Enter instructions for rephrasing text',
                            onFocus: handleInstructionFocus,
                            onBlur: handleInstructionBlur
                        }}
                    />
                    <div className={cx('absolute', 'top-2.5 right-4', 'text-xs', errorColor)}>
                        {error}
                    </div>
                    <div className={cx('absolute flex justify-end', 'mt-2', 'bottom-4 right-4')}>
                        <button onClick={onRephrase} className={highlightMenuItem()}>
                            {STRINGS.INSTRUCT}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(TextArea);
