export const CONTACT_HEADINGS = [
    { id: 'firstName', label: 'First Name', enableSort: true, priority: 1 },
    { id: 'lastName', label: 'Last Name', enableSort: true, priority: 1 },
    { id: 'email', label: 'Email', enableSort: true, priority: 1 },
    { id: 'jobTitle', label: 'Job Title', enableSort: true, priority: 5 },
    { id: 'seniorityLevel', label: 'Seniority Level', enableSort: true, priority: 5 },
    {
        id: 'departmentFunction',
        label: 'Department Function',
        enableSort: true,
        priority: 5
    },
    { id: 'jobResponsibilities', label: 'Job Responsibilities', priority: 5 },
    { id: 'industry', label: 'Industry', enableSort: true, priority: 5 },
    { id: 'subIndustry', label: 'Sub Industry', enableSort: true, priority: 5 },
    // {
    //     id: 'industryUseCaseFit',
    //     label: 'Industry UseCase Fit',
    //     enableSort: true,
    //     priority: 5
    // },
    { id: 'companyName', label: 'Company Name', enableSort: true, priority: 5 },
    { id: 'estimatedARR', label: 'Estimated ARR', priority: 5 },
    { id: 'associtedDeals', label: 'Associated Deals', priority: 5 },
    { id: 'icpFit', label: 'ICP Fit', priority: 5 },
    { id: 'icpFitReasoning', label: 'ICP Fit Reasoning', priority: 5 },
    { id: 'tailored_messaging_1', label: 'Tailored Messaging 1', priority: 5 },
    { id: 'tailored_messaging_2', label: 'Tailored Messaging 2', priority: 5 },
    { id: 'tailored_messaging_3', label: 'Tailored Messaging 3', priority: 5 },
    { id: 'fomo_triggers_1', label: 'Fomo Triggers_1', priority: 5 },
    { id: 'fomo_triggers_2', label: 'Fomo Triggers 2', priority: 5 },
    { id: 'fomo_triggers_3', label: 'Fomo Triggers 3', priority: 5 },
    { id: 'last_conversion_content', label: 'Last Conversion Content', priority: 5 },
    { id: 'last_conversion_date', label: 'Last Conversion Date', priority: 5 },

    { id: 'surprising_statistic', label: 'Surprising Statistic', priority: 5 },
    // {
    //     id: 'companyAnnualRevenue',
    //     label: 'Company Annual Revenue',
    //     enableSort: true,
    //     priority: 5
    // },
    {
        id: 'technographicDetails',
        label: 'Technographic Details',
        enableSort: true,
        priority: 5
    },
    {
        id: 'technologyPlatform',
        label: 'Technology Platform',
        enableSort: true,
        priority: 5
    },
    {
        id: 'technologyAdoptionStage',
        label: 'Technology Adoption Stage',
        enableSort: true,
        priority: 5
    },
    {
        id: 'competitorTracking',
        label: 'Competitor Tracking',
        enableSort: true,
        priority: 5
    },
    {
        id: 'competitiveCoverage',
        label: 'Competitive Coverage',
        enableSort: true,
        priority: 5
    },
    { id: 'growthInitiatives', label: 'Growth Initiatives', enableSort: true, priority: 5 },
    // { id: 'keyPriorities', label: 'Key Priorities', enableSort: true, priority: 5 },
    // {
    //     id: 'executiveInitiatives',
    //     label: 'Executive Initiatives',
    //     enableSort: true,
    //     priority: 5
    // },
    // {
    //     id: 'productBenefitsAdoption',
    //     label: 'Product Benefits Adoption',
    //     enableSort: true,
    //     priority: 5
    // },
    // { id: 'solutionProviders', label: 'Solution Providers', enableSort: true, priority: 5 },
    // { id: 'useCaseFit', label: 'UseCase Fit', enableSort: true, priority: 5 },
    // { id: 'linkedInBio', label: 'LinkedIn BIO', priority: 5 },
    { id: 'painPoints', label: 'Pain Points', priority: 5 },
    { id: 'industryTrends', label: 'Industry Trends', priority: 5 },
    // { id: 'targetingStrategy1', label: 'Targeting Strategy 1', priority: 5 },
    // { id: 'targetingStrategy2', label: 'Targeting Strategy 2', priority: 5 },
    // { id: 'purchaseTriggers', label: 'Purchase Triggers', priority: 5 },
    // { id: 'personInsight', label: 'Insights', priority: 5 },
    // { id: 'successMeasures', label: 'Success Measures', priority: 5 },
    // { id: 'customerJourney', label: 'Customer Journey', priority: 1 },
    // {
    //     id: 'kloutScore',
    //     label: 'Lead Score',
    //     enableSort: true,
    //     priority: 5
    // },
    { id: 'status', label: 'Status', enableSort: true, priority: 1 },
    {
        id: 'lastEmailClickDate',
        label: 'Last Email Click Date',
        enableSort: true,
        priority: 1
    },
    { id: 'lastActivityDate', label: 'Last Activity Date', enableSort: true, priority: 1 }
];

export const CONTACT_KEYS = [
    'firstName',
    'lastName',
    'email',
    'jobTitle',
    'seniorityLevel',
    'departmentFunction',
    'jobResponsibilities',
    'industry',
    'subIndustry',
    'companyName',
    'estimatedARR',
    'associtedDeals',
    'icpFit',
    'icpFitReasoning',
    'tailored_messaging_1',
    'tailored_messaging_2',
    'tailored_messaging_3',
    'fomo_triggers_1',
    'fomo_triggers_2',
    'fomo_triggers_3',
    'last_conversion_content',
    'last_conversion_date',
    'surprising_statistic',
    // 'annualRevenue',
    'technographicDetails',
    'technologyPlatform',
    'technologyAdoptionStage',
    'competitorTracking',
    'competitiveCoverage',
    'growthInitiatives',
    // 'keyPriorities',
    // 'executiveInitiatives',
    // 'productBenefitsAdoption',
    // 'solutionProviders',
    // 'useCaseFit',

    // 'industryUseCaseFit',
    // 'linkedInBio',
    'painPoints',
    'industryTrends',
    // 'targetingStrategy1',
    // 'targetingStrategy2',
    // 'purchaseTriggers',
    // 'personInsight',
    // 'successMeasures',
    // 'customerJourney',
    // 'kloutScore',
    'lifecycleState',
    'lastEmailClickDate',
    'lastActivityDate'
];

export const SINGLE_LINE_KEYS = [
    'firstName',
    'lastName',
    'email',
    'jobTitle',
    'seniorityLevel',
    'departmentFunction',
    // 'kloutScore',
    'lifecycleState',
    'lastEmailClickDate',
    'lastActivityDate',
    'industry',
    'subIndustry'
];

export const KEYS_TO_REMOVE = [
    'categories',
    'category',
    'orgId',
    'sourceId',
    'userEmail',
    'userId',
    '__v',
    '_id',
    'linkedInCompanyPage',
    'targetAccount',
    'linkedInUrl',
    'companyLinkedInUrl',
    'leadStatus',
    'lifecycleStage',
    'totalRevenue'
];
