import React from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

//assets
import Icomoon from 'src/assets/Icomoon';
import Tooltip from 'src/components/Tooltip';

//constants
import { indigoDarkBg, themeBorder } from 'src/shared/styles/colors';

const RadioButton = ({
    className = '',
    data = { label: '', value: '' },
    onChange = () => {},
    checked = false,
    id = '1',
    inputClass = '',
    showLabel = true,
    showTooltip = false,
    iconStart = '',
    iconEnd = ''
}) => {
    const handleChange = (e) => {
        e?.target?.blur();
        onChange(e);
    };

    return (
        <div className={cx(styles.container, className)}>
            <input
                className={cx(styles.containerInput, inputClass, {
                    [cx(indigoDarkBg, themeBorder)]: checked
                })}
                type="radio"
                id={id}
                onChange={handleChange}
                checked={checked}
            />
            <label className={styles.containerLabel} htmlFor={id}>
                {!!data?.icon && <Icomoon className={styles.icons} icon={data.icon} />}
                {iconStart && <div className={iconStart} />}
                {showLabel && (data.label || data.name)}
                {iconEnd && <div className={iconEnd} />}
            </label>
            {showTooltip && <Tooltip title={''} tooltipText={data.tooltip} />}
        </div>
    );
};

export default React.memo(RadioButton);
