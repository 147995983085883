import React from 'react';
import cx from 'classnames';

//styles
import styles from '../../Tabs.module.scss';

//constants
import { bgSlate800 } from 'src/shared/styles/colors';

interface IProps {
    data: { label: string; value: string | { value: string }[] };
}

const Tile = ({ data }: IProps) => {
    const [showMore, setShowMore] = React.useState(false);

    const spanClss = cx('p-1 text-cyan-600', 'cursor-pointer');

    return (
        <div className={cx(styles.tile, 'w-72 shadow m-3 p-2 break-words', bgSlate800)}>
            <h1 className={cx('text-xl mb-2 font-semibold')}>{data?.label}</h1>
            <div className={cx('')}>
                {data?.value instanceof Array
                    ? data?.value?.map((v) => v.value)?.join(', ')
                    : showMore
                    ? data?.value
                    : data?.value?.length > 200
                    ? `${data.value.slice(0, 200)}...`
                    : data?.value || '--'}
                {!showMore && data?.value?.length > 200 && (
                    <span className={spanClss} onClick={() => setShowMore(true)}>
                        {' '}
                        more
                    </span>
                )}
                {showMore && (
                    <span className={spanClss} onClick={() => setShowMore(false)}>
                        {' '}
                        less
                    </span>
                )}
            </div>
        </div>
    );
};

export default React.memo(Tile);
