export const darkContainer = 'bg-slate-900 text-slate-400';
export const slateBlackBR = 'bg-gradient-to-br from-slate-800 to-black';
export const slateBlackBL = 'bg-gradient-to-bl from-slate-800 to-black';
export const slateBlackTB = 'bg-gradient-to-b from-black to-slate-800';
export const slateBlackBT = 'bg-gradient-to-t from-black to-slate-800';
export const textDarkColor = `text-slate-400`;
export const textDarkTitleColor = `text-slate-300`;
export const textHoverColor = `hover:text-slate-300`;
export const slateDarkBg = `bg-slate-800`;
export const themeBorder = `border-indigo-900 border-[1px]`;
export const hoverThemeBorder = `hover:inset-0 hover:border-indigo-700 hover:border-[1px]`;
export const themeShadow = `shadow shadow-indigo-900`;
export const indigoDarkBg = `[&&&]:bg-indigo-900`;
export const lightBg = `[&&]:bg-slate-400`;
export const ctaStyle = `bg-indigo-900 font-semibold hover:bg-indigo-800 text-slate-400 hover:text-slate-300`;
export const ctaDisabled = `[&&]:bg-gray-200`;
export const bgSlate750 = `[&&]:bg-slate-750 text-slate-300`;
export const bgSlate800 = `[&&]:bg-slate-800`;
export const bgSlate900 = `[&&]:bg-slate-900`;
export const bgSlate600 = `[&&]:bg-slate-600 text-slate-300`;
export const errorColor = `text-red-600`;
export const hoverBorderResearch = `hover:inset-0 hover:border-green-700 hover:border-[1px]`;
