import React, { useState } from 'react';
import cx from 'classnames';

//hooks
import useClickOutside from 'src/hooks/useClickOutside';

//styles
import styles from './MenuModal.styles';

interface IProps {
    Icon: string;
    title?: string;
    iconClass?: string;
    menuClass?: string;
    list?: { title: string; onClick: VoidFunction }[];
    isPill?: boolean;
    isActive?: boolean;
    handleClick?: VoidFunction;
}

const MenuModal = ({
    Icon,
    title,
    menuClass,
    iconClass,
    list = [],
    isPill,
    isActive,
    handleClick
}: IProps) => {
    const [showMenu, setShowMenu] = useState(false);

    const handleIconClick = () => {
        if (handleClick) {
            handleClick();
            return;
        }

        if (!list.length) return;

        setShowMenu((state) => !state);
    };

    const menuRef = useClickOutside(() => setShowMenu(false));

    const pillStyle =
        isPill &&
        cx(
            `bg-black m-1`,
            `border-[1px] border-transparent shadow shadow-black`,
            `[&&]:py-1 [&&]:px-2 rounded-lg`
        );
    const activePillStyle = isActive && isPill && cx('border-[1px] [&&]:border-green-600');

    const MenuList = () => {
        return (
            <div className={cx(styles.menu, menuClass)}>
                {list.map((item, key) => {
                    return (
                        <div key={key} className={styles.menuItem} onClick={item.onClick}>
                            {item.title}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div ref={menuRef} className={cx(styles.container)}>
            <div
                className={cx(styles.icon, iconClass, Icon, pillStyle, activePillStyle)}
                onClick={handleIconClick}
            >
                {!!title && <div className={cx('ml-2 text-sm')}>{title}</div>}
            </div>
            {showMenu && <MenuList />}
        </div>
    );
};

export default React.memo(MenuModal);
