import React, { useEffect, useState } from 'react';
import cx from 'classnames';

//styles
import styles from './OrgOnboarding.module.scss';

//widgets
import { OnboardingForm } from './widgets/OnboardingForm';
import { CustomButton, CustomInput } from 'src/widgets';
import { toast } from 'react-toastify';
import DeleteModal from 'src/components/DeleteModal';

//constants
import { STRINGS } from 'src/shared/strings';
import { ROUTES } from 'src/shared/constants/routes';

//hooks
import useCustomNavigation from 'src/hooks/useCustomNavigations';
import { useLocation } from 'react-router-dom';
import useUpdateUserDetails from 'src/hooks/useUpdateUserDetails';

//helpers
import { transformStateData } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from 'src/redux/reducers';

//apis
import {
    useLazyGetUserOrgQuery,
    useRemoveMemberMutation,
    useUpdateOrgUserRoleMutation
} from 'src/redux/queries/Org';

//assets
import Icomoon from 'src/assets/Icomoon';
import {
    bgSlate750,
    darkContainer,
    indigoDarkBg,
    slateBlackBT,
    slateBlackTB,
    textDarkTitleColor,
    themeBorder
} from 'src/shared/styles/colors';

const OrgOnboarding = () => {
    const dispatch = useDispatch();

    const userOrgs = useSelector((state) => state.user?.userDetails?.orgs);
    const handleNavigation = useCustomNavigation();
    const { state } = useLocation();
    const updateUserData = useUpdateUserDetails();

    const [orgData, setOrgData] = useState({});
    const [title, setTitle] = useState(STRINGS.ADD_ORG);
    const [isEditing, setIsEditng] = useState(true);
    const [isViewing, setIsViewing] = useState(false);
    const [showDeletModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const [updateUserRole] = useUpdateOrgUserRoleMutation();
    const [getUserOrg] = useLazyGetUserOrgQuery();
    const [removeOrgMember] = useRemoveMemberMutation();

    useEffect(() => {
        if (state && state?.data) {
            const transformState = transformStateData(state.data);
            setTitle(state?.data?.isViewing ? STRINGS.VIEW_ORG : STRINGS.EDIT_ORG);
            setIsEditng(state?.data?.isEditing);
            setIsViewing(state?.data?.isViewing);
            setOrgData({
                ...state.data,
                ...transformState
            });
        }
    }, [state]);

    const goBack = () => handleNavigation(-1);

    const handleSuccess = async () => {
        await updateUserData();
        handleNavigation(ROUTES.ORG, true);
    };

    const handleRoleChange = async (data, email) => {
        try {
            const role = data?.target?.value?.value;
            let admins = orgData?.users?.filter((u) => u.type === 'admin') || [];
            if (admins?.length === 1 && admins[0]?.email === email && role === 'user') {
                return toast.error('Atleast one member should be admin');
            }
            dispatch(startLoading());
            const id = orgData.code;
            const user = email;
            await updateUserRole({ role, id, user })
                .unwrap()
                .then(async () => {
                    let data = await getUserOrg(id).unwrap();
                    handleNavigation(null, true, {
                        data: {
                            ...state.data,
                            users: data?.users
                        }
                    });
                });
        } catch (err) {
            toast.error(err?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const removeMember = async (email) => {
        try {
            dispatch(startLoading());
            const id = orgData.code;

            await removeOrgMember({ id, email })
                .unwrap()
                .then(async (res) => {
                    let data = await getUserOrg(id).unwrap();
                    await updateUserData();
                    toast.success(res?.data?.message);
                    handleNavigation(null, true, {
                        data: {
                            ...state.data,
                            users: data?.users
                        }
                    });
                });
        } catch (err) {
            toast.error(err?.data?.message);
        } finally {
            dispatch(stopLoading());
        }
    };

    const renderDeleteModal = () => {
        return (
            <DeleteModal
                handleDelete={() => {
                    removeMember(deleteId);
                    setDeleteId('');
                    setShowDeleteModal(false);
                }}
                title={'Remove User'}
                desc={'Are you sure you want to remove this user from your Org ?'}
                setIsOpen={setShowDeleteModal}
            />
        );
    };

    return (
        <div className={cx(styles.overlay, darkContainer)}>
            <div
                className={cx(
                    styles.overlayHeader,
                    slateBlackTB,
                    'border-0 border-b-[1px]',
                    themeBorder
                )}
            >
                {!!!userOrgs?.length && (
                    <h1 className={cx('text-2xl font-semibold')} aria-label={STRINGS.APP_NAME}>
                        {STRINGS.APP_NAME}
                    </h1>
                )}
                <h1 className="font-semibold">{'Add Organization'}</h1>
                {!!userOrgs?.length && (
                    <CustomButton
                        className={styles.overlayHeaderCta}
                        onClick={goBack}
                        title={'back'}
                    />
                )}
            </div>
            <div className={cx(styles.body, `[&&&]:text-black`, slateBlackBT)}>
                <div className={cx(styles.container, bgSlate750)}>
                    <div className={cx(styles.containerNudge, indigoDarkBg, textDarkTitleColor)}>
                        {title}
                    </div>
                    <div className={cx(styles.header, textDarkTitleColor)}>
                        <div className={styles.headerTitle}>{STRINGS.WELCOME}</div>
                        <div className={styles.headerSubtext}>{STRINGS.ADD_ORG_DESC}</div>
                    </div>
                    <OnboardingForm
                        data={orgData}
                        isEditing={false}
                        isViewing={isViewing}
                        handleSuccess={handleSuccess}
                    />
                </div>

                {!!orgData?.users?.length && (
                    <div className={cx(styles.list, 'p-4')}>
                        <h5 className="block mb-0 font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                            {STRINGS.MEMBERS}
                        </h5>
                        <div className="flex-auto block py-8 pt-6 px-9">
                            <div className="overflow-x-auto" style={{ minHeight: 200 }}>
                                <table className="w-full my-0 text-dark">
                                    <thead className="align-bottom">
                                        <tr className="font-semibold text-[0.95rem] text-secondary-dark">
                                            <th className="pb-3 text-start min-w-[20px]">SR.</th>
                                            <th className="pb-3 text-start min-w-[100px]">Email</th>
                                            <th
                                                className={cx('pb-3 min-w-[100px] text-end', {
                                                    'text-center': isEditing
                                                })}
                                            >
                                                Role
                                            </th>
                                            <th className="pb-3 text-end w-[20px]"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orgData?.users?.map((user, key) => (
                                            <tr
                                                key={key}
                                                className="border-b border-dashed last:border-b-0"
                                            >
                                                <td className="text-light-inverse text-md/normal">
                                                    {key + 1}
                                                </td>
                                                <td className="p-3 pl-0 text-start">
                                                    <span className=" text-light-inverse text-md/normal">
                                                        {user.email}
                                                    </span>
                                                </td>
                                                <td className="p-3 pr-0 w-40 text-end pr-2">
                                                    {isEditing ? (
                                                        <CustomInput
                                                            inputType={'select'}
                                                            selectOptions={[
                                                                { label: 'admin', value: 'admin' },
                                                                { label: 'user', value: 'user' }
                                                            ]}
                                                            value={{
                                                                label: user.type,
                                                                value: user.type
                                                            }}
                                                            placement={key === 0 ? 'bottom' : 'top'}
                                                            className={cx('w-40')}
                                                            isMulti={false}
                                                            onChange={(e) =>
                                                                handleRoleChange(e, user.email)
                                                            }
                                                        />
                                                    ) : (
                                                        <span className=" text-light-inverse text-md/normal">
                                                            {user.type}
                                                        </span>
                                                    )}
                                                </td>
                                                <td className="p-3 pl-0 text-end w-20">
                                                    {user.type !== 'admin' && isEditing && (
                                                        <Icomoon
                                                            className={styles.delete}
                                                            icon={'bin'}
                                                            onClick={() => {
                                                                setDeleteId(user?.email);
                                                                setShowDeleteModal(true);
                                                            }}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showDeletModal && deleteId && renderDeleteModal()}
        </div>
    );
};

export default React.memo(OrgOnboarding);
