// libs
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

// widgets
import { CustomButton } from 'src/widgets';
import { ColorRing } from 'react-loader-spinner';

// apis
import {
    useLazyInitLinkedinQuery,
    useLazyLinkedinUserInfoQuery,
    useLinkedinLogoutMutation
} from 'src/redux/queries/Linkedin';

const actionsCss = cx(
    'flex-1',
    'mt-4 mx-1 px-2 py-1',
    'bg-gray-300 text-black',
    'hover:bg-gray-400 hover:text-white'
);

const loaderStyles = cx('fixed top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-2/3');

const openUrl = (url: string) => window.open(url, '_self');

const LinkedinWidget = () => {
    const [getLinkedinUserInfo] = useLazyLinkedinUserInfoQuery();
    const [linkedinLogout] = useLinkedinLogoutMutation();
    const [initLinkedin] = useLazyInitLinkedinQuery();

    const [userInfo, setUserInfo] = useState<{ user: string; id: string }>({
        user: '',
        id: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const loc = useLocation();

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleHubspotLogout = async () => {
        try {
            setIsLoading(true);
            await linkedinLogout(null)
                .unwrap()
                .then((res) => {
                    if (res.statusCode === 0) {
                        setUserInfo({ user: '', id: '' });
                    }
                });
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleLinkedinLogin = async () => {
        try {
            setIsLoading(true);
            await initLinkedin(null)
                .unwrap()
                .then(async (data) => {
                    if (data?.loginUrl) {
                        localStorage.setItem('linkedin_redirect', loc.pathname);
                        openUrl(data.loginUrl);
                    } else {
                        await fetchData();
                    }
                })
                .catch((e) => {
                    throw e;
                });
        } catch (error) {
            console.log('error >>>> ', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const data = await getLinkedinUserInfo(null).unwrap();
            setUserInfo(() => ({
                user: data?.name,
                id: data?.email
            }));
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const renderLoader = () => {
        return (
            <div className={loaderStyles}>
                <ColorRing
                    height={60}
                    width={60}
                    colors={['#004170', '#004170', '#004170', '#004170', '#004170']}
                    ariaLabel="loading"
                    visible={true}
                    wrapperClass={loaderStyles}
                />
            </div>
        );
    };

    return isLoading ? (
        renderLoader()
    ) : (
        <div className={cx('relative border-2', 'p-4 py-6')}>
            <h1
                className={cx(
                    'absolute bg-white',
                    'px-2',
                    'top-[-16px] left-2',
                    'text-xl font-medium'
                )}
            >
                Linkedin Details
            </h1>

            {userInfo?.user ? (
                <>
                    <div className={cx('flex justify-between items-center', 'mb-3')}>
                        <p>
                            <span className={cx('mr-1')}>Name : </span>
                            <span>{userInfo.user}</span>
                        </p>

                        <p>
                            <span className={cx('mr-1')}>email : </span>
                            <span>{userInfo.id}</span>
                        </p>
                    </div>

                    <CustomButton
                        className={cx(actionsCss)}
                        title={'Logout Linkedin'}
                        onClick={handleHubspotLogout}
                    />
                </>
            ) : (
                <div className={cx('flex flex-col items-center justify-between', '')}>
                    <h1>{'Linkedin Account not connected!'}</h1>

                    <CustomButton
                        className={cx(actionsCss)}
                        title={'Login Linkedin'}
                        onClick={handleLinkedinLogin}
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(LinkedinWidget);
