//libs
import { diff_match_patch } from 'diff-match-patch';

//constants
import { contentTypeMap, CONTENT_TYPE, MaxWordLimit } from 'src/shared/constants';
import { BDR_EMAIL_PROMPTS, PROMPT_INSTRUCTIONS } from '../prompts';

export const generatePromptFb = (
    summaryInfo,
    persona,
    contentType,
    toneRegister,
    audience,
    maxWords
) => {
    let formattedOutput = contentTypeMap[contentType];
    let prompt = `I want you to act as a ${persona}. Compose a ${maxWords[contentType]}-word `;
    prompt += formattedOutput + ' ';
    prompt += `targeting ${audience}. `;
    prompt += 'Your ' + formattedOutput + ` should be no longer than ${maxWords[contentType]}. `;
    prompt +=
        `Use the following content as the basis for your ${maxWords[contentType]}-word ` +
        formattedOutput +
        '-> ';
    prompt += summaryInfo + '<- ';
    prompt += getInstructions(contentType);

    return prompt;
};

export const getContentTypeLabel = (value) => {
    const label = CONTENT_TYPE.find((content) => content.value === value)?.label || 'Summary';
    return label;
};

export const summarizePdf = async (data, chunkSize = 16000) => {
    try {
        const sentences = data
            ?.split('.')
            .map((sentence) => sentence.trim())
            .filter(Boolean);

        const chunks = sentences.reduce((acc, sentence) => {
            if (acc.length === 0 || acc[acc.length - 1].length + sentence.length >= chunkSize) {
                acc.push(sentence + '.');
            } else {
                acc[acc.length - 1] += sentence + '.';
            }
            return acc;
        }, []);
        return chunks;
    } catch (e) {
        console.log('error : ', e);
    }
};

export const generateSummaryPrompt = (data, summaryLength = 300) => {
    let prompt = `I want you to briefly summarise the following contetnt in not more than ${summaryLength} words. Wherever possible, use numbers and examples from the original document. : `;
    prompt += data;
    return prompt;
};

export const joinSummary = (data = []) => {
    let summary = '';
    data.forEach((ele) => {
        summary = summary + ele?.choices[0] + ' ';
    });
    return summary.trim();
};

export const getChoiceCount = (type = '') => {
    let content = CONTENT_TYPE.find((content) => content.value === type);
    if (content) {
        return content?.count || 1;
    }

    return 1;
};

export const getTokenCount = (type = '') => {
    let content = CONTENT_TYPE.find((content) => content.value === type);
    if (content) {
        //Shaiv:  lowering this threshold 1.5 => 0.8 has been helpful in calls
        return content?.max_tokens * 0.8 || 256;
    }

    return 1;
};

export const findReplaceSubstring = (string, pattern, replacement) => {
    if (!string) return '';

    var regex = new RegExp(pattern, 'g');
    var newString = string.replace(regex, replacement);

    return newString;
};

export const getVisiblePages = (totalPages, currentPage) => {
    const pagesToShow = 7;
    const halfPagesToShow = Math.floor(pagesToShow / 2);
    let startPage = Math.max(currentPage - halfPagesToShow, 1);
    let endPage = Math.min(startPage + pagesToShow - 1, totalPages);

    if (totalPages === pagesToShow) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (endPage - startPage + 1 < pagesToShow) {
        startPage = Math.max(endPage - pagesToShow + 1, 1);
    }

    if (endPage - startPage + 1 > pagesToShow) {
        endPage = Math.min(startPage + pagesToShow - 1, totalPages);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
};

export const getWordCount = (type = '') => {
    return MaxWordLimit[type] ?? 80;
};

export const highlightDifferences = (str1, str2, className) => {
    if (!str1 || !str2) {
        return '';
    }
    const dmp = new diff_match_patch();
    const diffs = dmp.diff_main(str1, str2);
    dmp.diff_cleanupSemantic(diffs);

    let highlightedHtml = '';

    for (const [op, text] of diffs) {
        if (op === 0) {
            // No difference, keep the text as is
            highlightedHtml += text;
        } else if (op === 1) {
            // Added text (in str2), highlight in light green
            highlightedHtml += `<span class="${className}">${text}</span>`;
        } else {
            // do nothing
        }
    }

    return highlightedHtml;
};

export const generateBdrEmailsPromptFb = (
    summaryInfo,
    persona,
    contentType,
    toneRegister,
    audience,
    maxWords,
    key
) => {
    let formattedOutput = contentTypeMap[contentType];
    let prompt = `Compose an email as ${persona} to a potential client. Compose a less than ${maxWords[contentType]}-word `;
    prompt += formattedOutput + ' ';
    prompt += 'in a ' + toneRegister + ' register ';
    prompt += `targeting ${audience}. `;
    prompt += 'Your ' + formattedOutput + ` should be no longer than ${maxWords[contentType]}. `;
    prompt +=
        `Use the following content as the basis for your less than ${maxWords[contentType]}-word ` +
        formattedOutput +
        '-> ';
    prompt += summaryInfo + '<- ';
    prompt += BDR_EMAIL_PROMPTS[key];

    return prompt;
};

const getInstructions = (contentType) => {
    return (
        PROMPT_INSTRUCTIONS[contentType] ||
        `Instructions:
    Wherever possible, use numbers and examples from the original document.`
    );
};

//seaprate for now due to different use case
export const generateBdrEmailsPrompt = ({
    documentTypes = [],
    summaryInfo,
    persona,
    contentType,
    toneRegister,
    audience,
    maxWords,
    key
}) => {
    let personaText = ``;
    if (persona?.title || persona?.name) {
        personaText += `${persona?.title || persona?.name} `;
    }
    if (persona?.writingStyle || persona?.mainStyle) {
        personaText += `who has the following writing style: ${
            persona?.writingStyle || persona?.mainStyle
        }, `;
    }

    if (persona?.tone) {
        personaText += `in a ${persona?.tone} tone `;
    }

    if (persona?.personality || persona?.personalityOfContent) {
        personaText += `with the following personality: ${
            persona?.personality || persona?.personalityOfContent
        }`;
    }

    let audienceText = '';
    if (audience?.label) {
        audienceText += `${audience?.label} `;
    }

    if (audience?.title) {
        audienceText += `${audience?.title} `;
    }

    if (audience?.segment) {
        audienceText += `at ${audience?.segment} `;
    }

    if (audience?.targetIndustry || audience?.industry) {
        audienceText += `in ${audience?.targetIndustry || audience?.industry} `;
    }

    if (audience?.companyProfile || audience?.companyName) {
        audienceText += `at ${audience?.companyProfile || audience?.companyName}. `;
    }

    if (audience?.personalityIndicators || audience?.kpis) {
        audienceText += `Highlight how our solutions address the KPIs ${
            audience?.personalityIndicators || audience?.kpis
        } relevant to ${audience?.title}'s objectives.`;
    }

    if (audience?.linkedinProfile || audience?.linkedinUrl) {
        audienceText += ` Include insights from ${
            audience?.linkedinProfile || audience?.linkedinUrl
        } and `;
    }

    if (audience?.companyProfile || audience?.companyName) {
        audienceText += ` emphasize the impact on ${
            audience?.companyProfile || audience?.companyName
        }'s efficiency and success`;
    }

    let processedPrompt = documentTypes.find((doc) => doc.name === 'bdrEmail');
    const latestPrompt = processedPrompt?.promptText?.length;
    let prompt = processedPrompt?.promptText[latestPrompt - 1];
    if (!processedPrompt || !processedPrompt.promptText)
        return generateBdrEmailsPromptFb(
            summaryInfo,
            personaText || 'Business Development Representative',
            contentType,
            toneRegister,
            audienceText || audience?.value,
            maxWords,
            key
        );

    if (audience?.name || audience?.title) {
        audienceText += `Name: ${audience?.name}
        Title: ${audience?.title}
        Segment: ${audience?.segment}
        Industry: ${audience?.industry}
        KPIs (Key Performance Indicators): ${audience?.personalityIndicators}
        Offerings: ${audience?.offerings}
        Personal Motivators: ${audience?.personalMotivators}
        Business Outcomes: ${audience?.businessOutcomes}
        Pain Points: ${audience?.frustrationsAndChallenges}
        Success Measures: ${audience?.successMeasures}
        Common Objections: ${audience?.commonObjection}
        Use Cases: ${audience?.useCases}
        Triggers: ${audience?.purchaseTriggers}
        Person Insight: ${audience?.personInsight}
        Form the previous use values which are not undefined or null for target
        `;
    }

    prompt = prompt
        .replaceAll('{persona}', personaText || 'Business Development Representative')
        .replaceAll('{personaTitle}', persona?.title || persona?.name || '')
        .replaceAll('{personaMainStyle}', persona?.writingStyle || persona?.mainStyle || '')
        .replaceAll('{personaTone}', persona?.tone || toneRegister || '')
        .replaceAll(
            '{personaPersonality}',
            persona?.personality || persona?.personalityOfContent || ''
        )
        .replaceAll('{words}', maxWords[contentType])
        .replaceAll('{toneRegister}', toneRegister)
        .replaceAll('{toneOutlook}', '')
        .replaceAll('{audience}', audienceText || audience?.value)
        .replaceAll('{content}', `-> ${summaryInfo} <- `)
        .replaceAll('{documentType}', contentTypeMap[contentType])
        .replaceAll('{audienceTitle}', audience?.title || audience?.value || '')
        .replaceAll('{audienceSegment}', audience?.segment || '')
        .replaceAll('{audienceIndustry}', audience?.targetIndustry || audience?.industry || '')
        .replaceAll(
            '{audienceCompanyName}',
            audience?.companyProfile || audience?.companyName || ''
        )
        .replaceAll('{audienceKpis}', audience?.personalityIndicators || audience?.kpis || '')
        .replaceAll(
            '{audienceLinkedinUrl}',
            audience?.linkedinProfile || audience?.linkedinUrl || ''
        );

    if (processedPrompt?.instructions?.length) {
        let instructions = processedPrompt?.instructions[key];
        prompt += `${instructions}`;
    }

    return prompt;
};

export const generatePrompt = ({
    documentTypes = [],
    summaryInfo,
    persona,
    contentType,
    toneRegister,
    audience,
    maxWords,
    key
}) => {
    let personaText = ``;

    if (persona?.title || persona?.name) {
        personaText += `${persona?.title || persona?.name} `;
    }
    if (persona?.writingStyle || persona?.mainStyle) {
        personaText += `who has the following writing style: ${
            persona?.writingStyle || persona?.mainStyle
        }, `;
    }

    if (persona?.tone) {
        personaText += `in a ${persona?.tone} tone `;
    }

    if (persona?.personality || persona?.personalityOfContent) {
        personaText += `with the following personality: ${
            persona?.personality || persona?.personalityOfContent
        }`;
    }

    let audienceText = '';
    if (audience?.label) {
        audienceText += `${audience?.label} `;
    }

    if (audience?.title) {
        audienceText += `${audience?.title} `;
    }

    if (audience?.segment) {
        audienceText += `at ${audience?.segment} `;
    }

    if (audience?.targetIndustry || audience?.industry) {
        audienceText += `in ${audience?.targetIndustry || audience?.industry} `;
    }

    if (audience?.companyProfile || audience?.companyName) {
        audienceText += `at ${audience?.companyProfile || audience?.companyName}. `;
    }

    if (audience?.personalityIndicators || audience?.kpis) {
        audienceText += `Highlight how our solutions address the KPIs ${
            audience?.personalityIndicators || audience?.kpis
        } relevant to ${audience?.title}'s objectives.`;
    }

    if (audience?.linkedinProfile || audience?.linkedinUrl) {
        audienceText += ` Include insights from ${
            audience?.linkedinProfile || audience?.linkedinUrl
        } and `;
    }

    if (audience?.companyProfile || audience?.companyName) {
        audienceText += ` emphasize the impact on ${
            audience?.companyProfile || audience?.companyName
        }'s efficiency and success `;
    }

    let processedPrompt = documentTypes.find((doc) => doc.name === contentType);
    if (!processedPrompt || !processedPrompt.promptText) {
        let genericPrompt = documentTypes.find((doc) => doc.name === 'generic');

        audienceText += `
        Use the following Dimensions for Audience Persona:- 
        1: Offerings: ${audience?.offerings}. 
        2: Personal Motivators: ${audience?.personalMotivators}. 
        3: Business Outcomes: ${audience?.businessOutcomes}. 
        4: Pain Points: ${audience?.frustrationsAndChallenges}. 
        5: Success Measures: ${audience?.successMeasures}. 
        6: Common Objections: ${audience?.commonObjection}. 
        7: Use Cases: ${audience?.useCases}. 
        8: Triggers: ${audience?.purchaseTriggers}. 
        9: Person Insight: ${audience?.personInsight}. 
        Form the previous use values which are not undefined or null for target
        `;

        if (genericPrompt && genericPrompt?.promptText?.length && audience?.title) {
            const latestPrompt = genericPrompt?.promptText?.length;
            let prompt = genericPrompt?.promptText[latestPrompt - 1];
            prompt = prompt
                .replaceAll('{personaTitle}', persona?.title || persona?.name || '')
                .replaceAll('{personaMainStyle}', persona?.writingStyle || persona?.mainStyle || '')
                .replaceAll('{personaTone}', persona?.tone || toneRegister || '')
                .replaceAll(
                    '{personaPersonality}',
                    persona?.personality || persona?.personalityOfContent || ''
                )
                .replaceAll('{persona}', personaText || 'Marketing Copywriter')
                .replaceAll('{words}', maxWords[contentType])
                .replaceAll('{documentType}', contentTypeMap[contentType])
                .replaceAll('{audienceTitle}', audience?.title || audience?.value)
                .replaceAll('{audienceSegment}', audience?.segment || '')
                .replaceAll(
                    '{audienceIndustry}',
                    audience?.targetIndustry || audience?.industry || ''
                )
                .replaceAll(
                    '{audienceCompanyName}',
                    audience?.companyProfile || audience?.companyName || ''
                )
                .replaceAll(
                    '{audienceKpis}',
                    audience?.personalityIndicators || audience?.kpis || ''
                )
                .replaceAll(
                    '{audienceLinkedinUrl}',
                    audience?.linkedinProfile || audience?.linkedinUrl || ''
                )
                .replaceAll('{content}', `-> ${summaryInfo} <- `);
            return prompt;
        } else
            return generatePromptFb(
                summaryInfo,
                personaText || 'Marketing Copywriter',
                contentType,
                toneRegister,
                audienceText || audience?.value,
                maxWords
            );
    }

    const latestPrompt = processedPrompt?.promptText?.length;
    let prompt = processedPrompt?.promptText[latestPrompt - 1];
    prompt = prompt
        .replaceAll('{persona}', personaText || 'Marketing Copywriter')
        .replaceAll('{personaTitle}', persona?.title || persona?.name || '')
        .replaceAll('{personaMainStyle}', persona?.writingStyle || persona?.mainStyle || '')
        .replaceAll('{personaTone}', persona?.tone || toneRegister || '')
        .replaceAll(
            '{personaPersonality}',
            persona?.personality || persona?.personalityOfContent || ''
        )
        .replaceAll('{words}', maxWords[contentType])
        .replaceAll('{toneRegister}', toneRegister || '')
        .replaceAll('{audience}', audienceText || audience?.value)
        .replaceAll('{toneOutlook}', '')
        .replaceAll('{content}', `-> ${summaryInfo} <- `)
        .replaceAll('{documentType}', contentTypeMap[contentType])
        .replaceAll('{audienceTitle}', audience?.title || audience?.value || '')
        .replaceAll('{audienceSegment}', audience?.segment || '')
        .replaceAll('{audienceIndustry}', audience?.targetIndustry || audience?.industry || '')
        .replaceAll(
            '{audienceCompanyName}',
            audience?.companyProfile || audience?.companyName || ''
        )
        .replaceAll('{audienceKpis}', audience?.personalityIndicators || audience?.kpis || '')
        .replaceAll(
            '{audienceLinkedinUrl}',
            audience?.linkedinProfile || audience?.linkedinUrl || ''
        );

    if (processedPrompt?.instructions?.length) {
        let instructions = '';
        if (key !== undefined) {
            instructions = processedPrompt?.instructions[key];
        }
        instructions = processedPrompt?.instructions[0];
        prompt += `${instructions}`;
    }
    prompt += `Instructions: Wherever possible, use numbers and examples and read whole content from the original document. `;

    return prompt;
};

export const deepCopy = (obj) => {
    if (obj === null || typeof obj !== 'object') {
        return obj; // Return primitive values as-is
    }

    if (Array.isArray(obj)) {
        // If it's an array, create a new array and deep copy each element
        return obj.map((item) => deepCopy(item));
    }

    // If it's an object, create a new object and deep copy each property
    const copiedObject = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            copiedObject[key] = deepCopy(obj[key]);
        }
    }

    return copiedObject;
};

export const extractFileNameFromUrl = (url) => {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const fileName = pathname.split('/').pop();
    return fileName;
};

export const generateBdrCadencePrompt = ({
    documentTypes = [],
    summaryInfo,
    persona,
    contentType,
    toneRegister,
    audience,
    maxWords,
    key,
    subType,
    personaFilter = '',
    contentFilter = '',
    emotionalFilter = '',
    contact,
    priorityQueue = [],
    instructions = ''
}) => {
    try {
        let {
            _id,
            userId,
            firstName,
            lastName,
            email,
            __v,
            createdAt,
            category,
            competitiveCoverage,
            competitorTracking,
            associtedDeals,
            icpFit,
            orgId,
            updatedAt,
            userEmail,
            lifecycleStage,
            linkedInCompanyPage,
            linkedInBio,
            fomo_triggers_2,
            fomo_triggers_3,
            tailored_messaging_1,
            tailored_messaging_2,
            tailored_messaging_3,
            technographicDetails,
            technologyAdoptionStage,
            technologyPlatform,
            webTechnologies,
            ...rest
        } = contact;

        // rest = JSON.stringify(rest).replaceAll(firstName, '--').replaceAll(lastName, '--');

        // Filter out keys with empty values
        const filteredObj = Object.entries(rest).reduce((acc, [key, value]) => {
            if (value && typeof value === 'string' && value.trim() !== '') {
                acc[key] = value;
            }
            return acc;
        }, {});

        // Sort keys based on priority order
        const sortedObj = {};
        priorityQueue.forEach((key) => {
            if (filteredObj[key]) {
                sortedObj[key] = filteredObj[key];
            }
        });

        // Add remaining keys that are not in the priority order
        Object.keys(filteredObj).forEach((key) => {
            if (!sortedObj[key]) {
                sortedObj[key] = filteredObj[key];
            }
        });

        let personaText = ``;
        if (persona?.title || persona?.name) {
            personaText += `${persona?.title || persona?.name} `;
        }
        if (persona?.writingStyle || persona?.mainStyle) {
            personaText += `who has the following writing style: ${
                persona?.writingStyle || persona?.mainStyle
            }, `;
        }

        if (persona?.tone) {
            personaText += `in a ${persona?.tone} tone `;
        }

        if (persona?.personality || persona?.personalityOfContent) {
            personaText += `with the following personality: ${
                persona?.personality || persona?.personalityOfContent
            }`;
        }

        let audienceText = '';

        if (audience?.label) {
            audienceText += `${audience?.label} `;
        }

        if (audience?.title) {
            audienceText += `${audience?.title} `;
        }

        if (audience?.segment) {
            audienceText += `at ${audience?.segment} `;
        }

        if (audience?.targetIndustry || audience?.industry) {
            audienceText += `in ${audience?.targetIndustry || audience?.industry} `;
        }

        if (audience?.companyProfile || audience?.companyName) {
            audienceText += `at ${audience?.companyProfile || audience?.companyName}. `;
        }

        if (audience?.personalityIndicators || audience?.kpis) {
            audienceText += `Highlight how our solutions address the KPIs ${
                audience?.personalityIndicators || audience?.kpis
            } relevant to ${audience?.title}'s objectives.`;
        }

        if (audience?.linkedinProfile || audience?.linkedinUrl) {
            audienceText += ` Include insights from ${
                audience?.linkedinData || audience?.linkedinUrl
            } and `;
        }

        if (audience?.companyProfile || audience?.companyName) {
            audienceText += ` emphasize the impact on ${
                audience?.companyProfile || audience?.companyName
            }'s efficiency and success`;
        }

        let processedPrompt = documentTypes.find((doc) => doc.name === 'bdrCadence');
        const latestPrompt = processedPrompt?.promptText?.length;
        let prompt = `Generate content with a focus on: Emotional Filter: ${emotionalFilter}, Content Filter: ${contentFilter} and Persona Filter: ${personaFilter}. ${
            processedPrompt?.promptText[latestPrompt - 1]
        }`;

        prompt = prompt
            .replaceAll('{persona}', personaText || 'Business Development Representative')
            .replaceAll('{personaTitle}', persona?.title || persona?.name || '')
            .replaceAll('{personaMainStyle}', persona?.writingStyle || persona?.mainStyle || '')
            .replaceAll('{personaTone}', persona?.tone || toneRegister || '')
            .replaceAll(
                '{personaPersonality}',
                persona?.personality || persona?.personalityOfContent || ''
            )
            .replaceAll('{words}', maxWords[contentType])
            .replaceAll('{toneRegister}', toneRegister)
            .replaceAll('{toneOutlook}', '')
            .replaceAll('{audience}', audienceText || audience?.value)
            .replaceAll('{content}', `-> ${summaryInfo} <- `)
            .replaceAll('{documentType}', contentTypeMap[contentType])
            .replaceAll('{audienceTitle}', audience?.title || audience?.value || '')
            .replaceAll('{audienceSegment}', audience?.segment || '')
            .replaceAll('{audienceIndustry}', audience?.targetIndustry || audience?.industry || '')
            .replaceAll(
                '{audienceCompanyName}',
                audience?.companyProfile || audience?.companyName || ''
            )
            .replaceAll('{audienceKpis}', audience?.personalityIndicators || audience?.kpis || '')
            .replaceAll(
                '{audienceLinkedinUrl}',
                audience?.linkedinData || audience?.linkedinUrl || ''
            );

        const promptsInstructions = {
            bdr_coldLead: [
                `Introduction: Introduce your brand and solutions to leads who might not be aware of your offerings or the potential benefits.`,
                `Overall benefits: Introduction to your company and a high-level overview of how you solve industry-specific problems.`,
                `Insight: Share valuable industry insights through research reports or statistics to demonstrate your knowledge of the space`,
                `Case study 1: Share a relevant case study as an example of the tangible results you deliver.`,
                `Asset: Offer a valuable piece of content (e.g., whitepaper, report) that addresses common challenges or trends in their industry.`,
                `Resurface: A soft-touch follow-up asking if they had a chance to review the resources and if they have any questions.`,
                `Break-up: Tell them that you have been trying to get their attention, but as they haven't responded, is this a bad time to connect, and do they want you to reach out in a couple of months.`
            ],
            bdr_warmLead: [
                `Follow-Up 1: Offer a short, no-obligation consultation or demo to discuss their specific needs and potential solutions.`,
                `Follow-Up 2: Personalized outreach referencing their recent interaction with your content or event, offering further insights.`,
                `Webinar invite: Deepen the relationship with leads who have shown some interest in your solutions, perhaps by engaging with your content or attending a webinar.`,
                `Insight: Share valuable industry insights through research reports or statistics to demonstrate your knowledge of the space`,
                `Case study 1: Share a relevant case study as an example of the tangible results you deliver.`,
                `Case study 2: Share a relevant case study as an example of the tangible results you deliver.`,
                `Break-up: Tell them that you have been trying to get their attention, but as they haven't responded, is this a bad time to connect, and do they want you to reach out in a couple of months.`
            ],
            bdr_engagedLead: [
                `Follow-Up 1: Offer a short, no-obligation consultation or demo to discuss their specific needs and potential solutions.`,
                `Personalized assessment 1: Offer a personalized assessment or audit that can help identify opportunities or improvements in their current processes.`,
                `Detailed benefit #1: Talk about a specific benefit that customers derive from using your solution.`,
                `Personalized assessment 2: Offer a personalized assessment or audit that can help identify opportunities or improvements in their current processes.`,
                `Detailed benefit #2: Talk about a specific benefit that customers derive from using your solution.`,
                `Tailored proposal # 1: Send a tailored proposal or detailed guide that addresses their specific challenges and outlines a potential solution.`,
                `Break-up: Tell them that you have been trying to get their attention, but as they haven't responded, is this a bad time to connect, and do they want you to reach out in a couple of months.`
            ],
            bdr_customCombinedTemplates: [customTemplate1, customTemplate2, customTemplate3],
            bdr_customTemplate_1: [customTemplate1],
            bdr_customTemplate_2: [customTemplate2],
            bdr_customTemplate_3: [customTemplate3]
        };

        let instructions = '';

        if (processedPrompt?.instructions && processedPrompt?.instructions?.[subType]) {
            instructions = processedPrompt?.instructions?.[subType]?.[key] || '';
            prompt += contact
                ? `Personalize the response for the following Contact: ${JSON.stringify(
                      sortedObj
                  )}.`
                : '';
            prompt += `Keep the count of words strictly to below 55. Add appropriate Call-to-action as part of email. Use the following as reference template for the output - >
        ${instructions}`;
        } else {
            instructions = promptsInstructions?.[subType]?.[key] || '';
            prompt += contact
                ? `Personalize the response for the following Contact: ${JSON.stringify(
                      sortedObj
                  )}.`
                : '';
            prompt += `Keep the count of words strictly to below 55. Add appropriate Call-to-action as part of email. Use the following as reference template for the output - >
        ${instructions}`;
        }

        return prompt;
    } catch (err) {
        console.log('err >>> ', err);
    }
};

const customTemplate1 = `Using the attached email as a template or exemplar, write an email for a BDR that introduces himself or herself to a prospect. Insert the primary benefit in the introduction and 3 specific problems (few words each) that we solve for customers in the bulleted list.
EMAIL: Subject: Your New Point of Contact
Hi <<First name>>,            
I was just assigned your account and I wanted to introduce myself. 
My name is ______ and I am responsible for making sure you know how we help our customers increase their operational efficiency by addressing problems such as:
1. <<problem 1>>
2. <<problem 2>>
3. <<problem 3>>
Have you already solved these pain points already, or is this something you are looking into for 2024?
Sincerely,
Your Name
Your Phone
Company Link`;

const customTemplate2 = `Using the attached email as a template or exemplar, write an email for a BDR. Insert competitor names in the introductory paragraph and specific customer names in the second paragraph.
EMAIL: Subject: Your desire to be #1 in your industry
Nick,
I saw you are in great company with <<competitor 1>> and <<competitor 2>>.  Would you say you are #1, or are you trying to reach #1 in your space? 
Some of our notable clients include leaders in their space such as <<customer1, customer2, and customer3>>. Would you be open to learning more about how we can help you become #1?
Sincerely,
Your Name
Your Phone
Company Link`;

const customTemplate3 = `Using the attached email as a template or exemplar, write an email for a BDR. Insert one competitor name and specific customer names in the second paragraph.
EMAIL: Subject: No response required
Nick,
I recently saw your competitor, _______, is growing with great success.
We have helped multiple customers such as <<customer1, customer1, and customer3>> increase their operational efficiency and achieve incredible growth trajectories. If you’re interested in learning more on how we can help you compete better, please let me know.
Sincerely,
Your Name
Your Phone
Company Link`;

export const generatePersonalizedPrompt = ({
    documentTypes = [],
    summaryInfo,
    persona,
    contentType,
    toneRegister,
    audience,
    maxWords,
    key,
    subType
}) => {
    try {
        let personaText = ``;
        if (persona?.title || persona?.name) {
            personaText += `${persona?.title || persona?.name} `;
        }
        if (persona?.writingStyle || persona?.mainStyle) {
            personaText += `who has the following writing style: ${
                persona?.writingStyle || persona?.mainStyle
            }, `;
        }

        if (persona?.tone) {
            personaText += `in a ${persona?.tone} tone `;
        }

        if (persona?.personality || persona?.personalityOfContent) {
            personaText += `with the following personality: ${
                persona?.personality || persona?.personalityOfContent
            }`;
        }

        let audienceText = '';
        if (audience?.label) {
            audienceText += `${audience?.label} `;
        }

        if (audience?.title) {
            audienceText += `${audience?.title} `;
        }

        if (audience?.segment) {
            audienceText += `at ${audience?.segment} `;
        }

        if (audience?.targetIndustry || audience?.industry) {
            audienceText += `in ${audience?.targetIndustry || audience?.industry} `;
        }

        if (audience?.companyProfile || audience?.companyName) {
            audienceText += `at ${audience?.companyProfile || audience?.companyName}. `;
        }

        if (audience?.personalityIndicators || audience?.kpis) {
            audienceText += `Highlight how our solutions address the KPIs ${
                audience?.personalityIndicators || audience?.kpis
            } relevant to ${audience?.title}'s objectives.`;
        }

        if (audience?.linkedinProfile || audience?.linkedinUrl) {
            audienceText += ` Include insights from ${
                audience?.linkedinData || audience?.linkedinUrl
            } and `;
        }

        if (audience?.companyProfile || audience?.companyName) {
            audienceText += ` emphasize the impact on ${
                audience?.companyProfile || audience?.companyName
            }'s efficiency and success `;
        }

        audienceText += `
        Use the following Dimensions for Audience Persona:- 
        1: Offerings: ${audience?.offerings}. 
        2: Personal Motivators: ${audience?.personalMotivators}. 
        3: Business Outcomes: ${audience?.businessOutcomes}. 
        4: Pain Points: ${audience?.frustrationsAndChallenges}. 
        5: Success Measures: ${audience?.successMeasures}. 
        6: Common Objections: ${audience?.commonObjection}. 
        7: Use Cases: ${audience?.useCases}. 
        8: Triggers: ${audience?.purchaseTriggers}. 
        9: Person Insight: ${audience?.personInsight}. 
        Form these 9 dimensions use ones which are not undefined or null for target.
        `;

        let processedPrompt = documentTypes.find((doc) => doc.name === 'personalized');
        const latestPrompt = processedPrompt?.promptText?.length;
        let prompt = processedPrompt?.promptText[latestPrompt - 1];

        prompt = prompt
            .replaceAll('{persona}', personaText || 'Business Development Representative')
            .replaceAll('{personaTitle}', persona?.title || persona?.name || '')
            .replaceAll('{personaMainStyle}', persona?.writingStyle || persona?.mainStyle || '')
            .replaceAll('{personaTone}', persona?.tone || toneRegister || '')
            .replaceAll(
                '{personaPersonality}',
                persona?.personality || persona?.personalityOfContent || ''
            )
            .replaceAll('{words}', maxWords[contentType])
            .replaceAll('{toneRegister}', toneRegister)
            .replaceAll('{toneOutlook}', '')
            .replaceAll('{audience}', audienceText || audience?.value)
            .replaceAll('{content}', `-> ${summaryInfo} <- `)
            .replaceAll('{documentType}', contentTypeMap[contentType])
            .replaceAll('{audienceTitle}', audience?.title || audience?.value || '')
            .replaceAll('{audienceSegment}', audience?.segment || '')
            .replaceAll('{audienceIndustry}', audience?.targetIndustry || audience?.industry || '')
            .replaceAll(
                '{audienceCompanyName}',
                audience?.companyProfile || audience?.companyName || ''
            )
            .replaceAll('{audienceKpis}', audience?.kpis || audience?.personalityIndicators || '')
            .replaceAll(
                '{audienceLinkedinUrl}',
                audience?.linkedinData || audience?.linkedinUrl || ''
            );

        const promptsInstructions = {
            bdr_customCombinedTemplates: [
                customPersonalizedTemplate1,
                customPersonalizedTemplate2,
                customPersonalizedTemplate3
            ],
            bdr_customTemplate_1: [customPersonalizedTemplate1],
            bdr_customTemplate_2: [customPersonalizedTemplate2],
            bdr_customTemplate_3: [customPersonalizedTemplate3]
        };

        let instructions = '';

        if (processedPrompt?.instructions && processedPrompt?.instructions?.[subType]) {
            instructions = processedPrompt?.instructions?.[subType]?.[key] || '';
            prompt += `Use the following template for the output - >
        ${instructions}`;
        } else {
            instructions = promptsInstructions?.[subType]?.[key] || '';
            prompt += `Use the following template for the output - >
        ${instructions}`;
        }

        return prompt;
    } catch (err) {
        console.log('err >>> ', err);
    }
};

const customPersonalizedTemplate1 = `Using the attached email as a template or exemplar, write an email for a BDR using any one dimension mentioned above which according to you can be considered as primary factor for target's interes. callout which dimension and dimension value you picked
EMAIL: Subject: Addressing Challenges of Inconsistent Communication
Dimension: Pain Points
Dimension Values: Difficulty getting budget approved, existing solutions seen as “good enough”

Dear ____,
____ body`;

const customPersonalizedTemplate2 = `Using the attached email as a template or exemplar, write an email for a BDR using any three dimensions mentioned above as primary factors. callout which dimensions and dimension values you picked
EMAIL: Subject: Addressing Challenges of Inconsistent Communication
Dimension: Pain Points
Dimension Values: Difficulty getting budget approved, existing solutions seen as “good enough”

Dear ____,
____ body`;

const customPersonalizedTemplate3 = `Using the attached email as a template or exemplar, write an email for a BDR using any one dimension mentioned above which according to you can be considered as primary factor for target's interes. callout which dimension and dimension value you picked
EMAIL: Subject: Addressing Challenges of Inconsistent Communication
Dimension: Pain Points
Dimension Values: Difficulty getting budget approved, existing solutions seen as “good enough”

Dear ____,
____ body`;
