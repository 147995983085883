import React, { useEffect, useState } from 'react';
import cx from 'classnames';

//assets
import Icomoon from 'src/assets/Icomoon';

//styles
import styles from './styles.module.scss';

//helpers
import { adjustTooltipPosition } from 'src/shared/utils';
import { textDarkTitleColor } from 'src/shared/styles/colors';

const Tooltip = ({
    className = '',
    title = '',
    tooltipText = '',
    cloudClass = '',
    cloudStyles = {},
    tooltipRef = { current: null }
}) => {
    const [customStyle, setCustomStyle] = useState({});

    useEffect(() => {
        if (tooltipRef?.current) setCustomStyle(adjustTooltipPosition(tooltipRef));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={cx(styles.tooltip, className)}>
            <div className={styles.tooltipTitle}>{title}</div>
            {!!tooltipText && (
                <div className={styles.tooltipBase}>
                    <div className={styles.tooltipInfo}>
                        <Icomoon className={styles.tooltipInfoIcon} icon={'info'} />
                    </div>
                    <div
                        ref={tooltipRef}
                        className={cx(styles.tooltipInfoCloud, cloudClass)}
                        style={{ ...customStyle.cloudStyle, ...cloudStyles }}
                    >
                        <span className={cx(styles.tooltipInfoDesc, textDarkTitleColor)}>
                            {tooltipText}
                        </span>
                        <div className={styles.tooltipInfoCloudTip} style={customStyle.tipStyle} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(Tooltip);
