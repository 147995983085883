import React from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

//constants
import { ctaStyle } from 'src/shared/styles/colors';

interface IProps {
    title: string;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    id?: string;
    icon?: string;
    className?: string;
    gradient?: boolean;
    isDark?: boolean;
}

const CustomButton = ({
    className = '',
    title = '',
    onClick,
    id = '',
    icon,
    gradient = false,
    isDark
}: IProps) => {
    const darkStyle = isDark && cx(`[&&]:bg-gray-700 [&&]:hover:bg-gray-950 [&&]:text-white`);

    return (
        <button
            id={id}
            onClick={onClick}
            className={cx(styles.container, className, darkStyle, ctaStyle)}
        >
            {icon && <div className={icon} />}
            <span className="text-base">{title}</span>
        </button>
    );
};

export default React.memo(CustomButton);
