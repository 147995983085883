import React from 'react';
import cx from 'classnames';

//assets
import Icomoon from 'src/assets/Icomoon';

//styles
import styles from './CampaignCard.module.scss';

//constants
import { CONTENT_TYPE_TAG_COLORS } from 'src/shared/constants';
import {
    bgSlate800,
    hoverThemeBorder,
    textDarkColor,
    textDarkTitleColor
} from 'src/shared/styles/colors';

interface IProps {
    data: {
        _id: string;
        title: string;
        status: string;
        tags: string[];
        updatedAt: string;
        orgName?: string;
        fileLink?: string;
        filters: { contentType: string[] };
        campaignType?: string;
    };
    self?: boolean;
    handleThreeDotsClick: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        id: string,
        fileData: {
            link: string;
            name: string;
        }
    ) => void;
    goToCampaign: (
        _id: string,
        isBdrCadence?: boolean,
        isPersonalized?: boolean,
        isAvancedBdr?: boolean
    ) => void;
}

const cardStyle = cx(
    'flex flex-col justify-between',
    'rounded-md shadow',
    ' w-74 p-4 border-[1px] border-slate-900',
    'cursor-pointer'
);

const CampaignCard = ({ data, self, handleThreeDotsClick, goToCampaign }: IProps) => {
    const hanldeCardClick = () => {
        if (data?.filters?.contentType[0] === 'advanced-bdrCadence') {
            goToCampaign(data._id, false, false, true);
            return;
        }

        if (
            data?.campaignType === 'personalized' &&
            data?.filters?.contentType[0] === 'bdrCadence'
        ) {
            goToCampaign(data._id, false, true);
            return;
        }

        if (data?.filters?.contentType[0] === 'bdrCadence') {
            goToCampaign(data._id, true);
            return;
        }

        if (data?.campaignType === 'personalized') {
            goToCampaign(data._id, false, true);
            return;
        }

        goToCampaign(data._id);
    };
    let contentType: string[] = [];
    if (data?.filters?.contentType?.length)
        contentType = [...data?.filters?.contentType]?.sort((a, b) => a.localeCompare(b));

    return (
        <div
            className={cx(styles.container, cardStyle, bgSlate800, hoverThemeBorder)}
            onClick={hanldeCardClick}
        >
            <div className={cx('text-black')}>
                <div className={cx('flex justify-between items-start mb-2')}>
                    <div className={cx('flex items-start')}>
                        <div className={cx(styles.folderContainer)}>
                            <Icomoon
                                className={cx(styles.folder, textDarkColor, 'mt-1')}
                                icon={'document'}
                            />
                        </div>
                        <h1 className={cx('text-lg font-bold ml-2', textDarkTitleColor)}>
                            {data?.title}
                        </h1>
                    </div>
                    <div
                        className={cx(styles.draftTableItemDots, textDarkColor)}
                        onClick={(e) => {
                            handleThreeDotsClick(e, self ? data._id : '', {
                                link: data.fileLink || '',
                                name: data.title
                            });
                        }}
                    >
                        <Icomoon className={cx(styles.dots, 'mt-1')} icon={'dots-three-vertical'} />
                    </div>
                </div>

                <div className={cx('flex items-center justify-between')}>
                    <div
                        className={cx(
                            'capitalize text-sm',
                            'py-0 px-3 w-fit text-center rounded-2xl mt-1',
                            data?.status === 'complete' ? 'bg-emerald-100' : ' bg-yellow-100'
                        )}
                    >
                        {data?.status}
                    </div>

                    {!!data?.orgName && (
                        <div
                            className={cx(
                                'flex items-center',
                                'capitalize text-sm',
                                'mt-1 py-0 px-3 w-fit rounded-2xl',
                                'bg-indigo-100'
                            )}
                        >
                            <Icomoon
                                className={cx(styles.orgicon, 'text-neutral-900')}
                                icon={'organization'}
                            />
                            <span className={cx('ml-2')}>{data.orgName}</span>
                        </div>
                    )}
                </div>

                <div className={cx('flex flex-wrap', 'mt-1')}>
                    {contentType?.map((content: string, key: number) => (
                        <div
                            key={key}
                            className={cx(
                                'py-1 px-3 mt-2 mr-1',
                                'bg-slate-900 rounded-2xl',
                                'capitalize text-xs font-medium',
                                'text-white'
                            )}
                            style={{ background: CONTENT_TYPE_TAG_COLORS[content] }}
                        >
                            {splitCamelCase(content)}
                        </div>
                    ))}
                </div>
            </div>
            <div className={cx('mt-2')}>
                <div className={cx('text-sm text-right', textDarkColor)}>
                    {new Date(data?.updatedAt).toLocaleString()}
                </div>
            </div>
        </div>
    );
};

export default React.memo(CampaignCard);

function splitCamelCase(text: string) {
    if (text === 'bdrEmail') return 'BDR Emails';
    if (text === 'bdrCadence') return 'BDR Cadence';

    return text.replace(/([a-z])([A-Z])/g, '$1 $2');
}
