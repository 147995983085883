import React, { ChangeEvent, useRef } from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

//assets
import Bin from 'src/assets/icons/bin_white.png';
import Bin_Grad from 'src/assets/icons/bin_grad.png';

//constants
import { LABELS } from 'src/shared/strings';
import { CONTENT_FILE_TYPES } from 'src/shared/constants';
import { ctaStyle } from 'src/shared/styles/colors';

//helper
import { toast } from 'react-toastify';
import { sanitizeFileNames } from 'src/shared/utils';

//defs
import type { IFile } from 'src/defs';

interface IProps {
    files: IFile[];
    multiple: boolean;
    ctaTitle?: string;
    ctaSelectedTitle?: string;
    type?: string;
    containerClass?: string;
    isDisabled?: boolean;
    handleUpload: (files: IFile[]) => void;
}

const FileUpload = ({
    handleUpload,
    files = [],
    type = 'pdf',
    multiple = false,
    ctaTitle = '',
    ctaSelectedTitle = '',
    containerClass = '',
    isDisabled = false
}: IProps) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleClick = () => {
        if (isDisabled) return;

        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (isDisabled || !e.target.files) return;

        const selectedFiles = Array.from(e.target.files);

        if (selectedFiles.length + files.length > 5) {
            toast.error('You can only select up to 5 files.');
            e.target.value = '';
            return;
        }

        const filesArray = [...files, ...selectedFiles];
        e.target.value = '';
        handleUpload(filesArray);
    };

    const acceptType =
        type === CONTENT_FILE_TYPES.PDF.value
            ? 'application/pdf, .pdf'
            : type === CONTENT_FILE_TYPES.DOCX.value
            ? '.doc, .docx'
            : '.txt, application/pdf, .pdf, .doc, .docx ';

    const removeFile = (idx: number) => {
        files.splice(idx, 1);
        handleUpload([...files]);
    };

    return (
        <div className={cx(styles.container, containerClass)}>
            <div
                className={cx(styles.containerButton, ctaStyle, {
                    [styles.disabled]: files.length >= 5 || isDisabled
                })}
                onClick={handleClick}
            >
                {files[0]?.name
                    ? ctaSelectedTitle || LABELS.UPLOADED
                    : ctaTitle || LABELS.UPLOAD_FILE}
            </div>
            <div className={styles.containerText}>{!!!files?.length && LABELS.NO_FILE_CHOOSEN}</div>
            {!!files?.length && (
                <div className="flex flex-wrap">
                    {files.map((file, idx) => {
                        let fileName = sanitizeFileNames(file?.name) || '';
                        fileName = fileName?.length > 32 ? `${fileName.slice(0, 32)}...` : fileName;

                        return (
                            <div key={idx} className={styles.files}>
                                <div className={styles.filesWrapper}>
                                    <div className={styles.containerText}>{fileName}</div>

                                    <div className={styles.icon}>
                                        <img
                                            onClick={() => removeFile(idx)}
                                            src={Bin}
                                            className={styles.iconImg}
                                            onMouseEnter={(e) => (e.currentTarget.src = Bin_Grad)}
                                            onMouseLeave={(e) => (e.currentTarget.src = Bin)}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}

            <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
                className={cx({ [styles.disabled]: files?.length >= 5 })}
                accept={acceptType}
                multiple={multiple}
                readOnly={isDisabled}
            />
        </div>
    );
};

export default React.memo(FileUpload);
