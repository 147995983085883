import React from 'react';
import cx from 'classnames';

// widgets
// import BetaTag from 'src/components/BetaTag';
import { CustomButton } from 'src/widgets';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from 'src/redux/reducers';
import { useLazySerachQuery } from 'src/redux/queries/Search';

interface IProps {
    allowCurrentTrends: boolean;
    seoKeywords: string;
    showAction?: boolean;
    content: string;
    targetKey: number;
    pos: number;
    handleSetCurrentTrends: (data: string, targetKey: number, pos: number) => void;
}

const actionsCss = cx('flex-1  h-[32px]', 'mt-4 mx-1');
// const BetaTagCss = cx('text-[8px]');

const CustomActions = ({
    content,
    seoKeywords,
    showAction,
    allowCurrentTrends,
    targetKey,
    pos,
    handleSetCurrentTrends
}: IProps) => {
    const dispatch = useDispatch();
    const [searchContent] = useLazySerachQuery();

    const callSearchApis = async (type: number) => {
        try {
            dispatch(startLoading());

            let data = '';

            if (!content) return;

            switch (type) {
                case 1:
                    data = `Generate current trends for the following data -> ${
                        seoKeywords ? seoKeywords : content.slice(0, 400)
                    }`;
                    break;
                case 2:
                    data = `Generate use cases for the following data -> ${content.slice(0, 400)}`;
                    break;
                case 3:
                    data = `Analyze the following data -> ${content.slice(0, 400)}`;
                    break;
                case 4:
                    data = `Generate keywords for the following data -> ${content.slice(0, 400)}`;
                    break;
                case 5:
                    data = `Enhance the following data with relevant information -> ${
                        seoKeywords ? seoKeywords : content.slice(0, 400)
                    }`;
                    break;

                default:
                    data = ``;
                    break;
            }

            await searchContent({
                data
            })
                .unwrap()
                .then((res) => {
                    handleSetCurrentTrends(res.data, targetKey, pos);
                })
                .catch((error) => {
                    throw error;
                });
        } catch (err) {
            console.log('error >>> ', err);
        } finally {
            dispatch(stopLoading());
        }
    };

    return (
        <>
            {showAction && (
                <>
                    {/* <BetaTag classname={BetaTagCss} /> */}
                    <CustomButton
                        className={actionsCss}
                        gradient
                        title={'Trends'}
                        onClick={() => {
                            !allowCurrentTrends &&
                                toast.info(
                                    'Please clear or merge the current data or close edit mode to generate again!',
                                    {
                                        toastId: 2
                                    }
                                );
                            allowCurrentTrends && callSearchApis(1);
                        }}
                    />
                </>
            )}
            {/* {showAction && (
                <>
                    <BetaTag classname={BetaTagCss} />
                    <CustomButton
                        className={actionsCss}
                        gradient
                        title={'Use Cases'}
                        onClick={() => {
                            !allowCurrentTrends &&
                                toast.info(
                                    'Please clear or merge the current data or close edit mode to generate again!',
                                    {
                                        toastId: 2
                                    }
                                );
                            allowCurrentTrends && callSearchApis(2);
                        }}
                    />
                </>
            )} */}
            {showAction && (
                <>
                    {/* <BetaTag classname={BetaTagCss} />{' '} */}
                    <CustomButton
                        className={actionsCss}
                        gradient
                        title={'Analyze'}
                        onClick={() => {
                            !allowCurrentTrends &&
                                toast.info(
                                    'Please clear or merge the current data or close edit mode to generate again!',
                                    {
                                        toastId: 2
                                    }
                                );
                            allowCurrentTrends && callSearchApis(3);
                        }}
                    />
                </>
            )}
            {showAction && (
                <>
                    {/* <BetaTag classname={BetaTagCss} />{' '} */}
                    <CustomButton
                        className={actionsCss}
                        gradient
                        title={'Keywords'}
                        onClick={() => {
                            !allowCurrentTrends &&
                                toast.info(
                                    'Please clear or merge the current data or close edit mode to generate again!',
                                    {
                                        toastId: 2
                                    }
                                );
                            allowCurrentTrends && callSearchApis(4);
                        }}
                    />
                </>
            )}
            {/* {showAction && (
                <>
                    <BetaTag classname={BetaTagCss} />
                    <CustomButton
                        className={actionsCss}
                        gradient
                        title={'Enhance'}
                        onClick={() => {
                            !allowCurrentTrends &&
                                toast.info(
                                    'Please clear or merge the current data or close edit mode to generate again!',
                                    {
                                        toastId: 2
                                    }
                                );
                            allowCurrentTrends && callSearchApis(5);
                        }}
                    />
                </>
            )} */}
        </>
    );
};

export default React.memo(CustomActions);
