//hook
import { useDispatch } from 'react-redux';

//api
import { useLazyMergeQuery } from 'src/redux/queries/Clap';

//helpers
import { toast } from 'react-toastify';
import { startLoading, stopLoading } from 'src/redux/reducers';

interface IProps {
    content: string | string[];
    currentTrends: string;
}

const useMergeContent = () => {
    const dispatch = useDispatch();

    const [merge] = useLazyMergeQuery();

    const mergeContent = async ({ content, currentTrends }: IProps) => {
        let mergedContent = '';
        try {
            dispatch(startLoading());

            await merge({ data: content, ct: currentTrends })
                .unwrap()
                .then((data) => {
                    if (data?.data?.length) {
                        mergedContent = data?.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    toast.error('Error in merging data');
                });
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(stopLoading());
            return mergedContent;
        }
    };

    return mergeContent;
};

export default useMergeContent;
