//libs
import React, { useRef, useState } from 'react';
import cx from 'classnames';

//styles
import styles from './styles.module.scss';

//widgets
import Tooltip from 'src/components/Tooltip';
import { Checkbox } from 'src/widgets';

//constants
import { ICON_TRIANGLE_DOWN, ICON_TRIANGLE_RIGHT } from 'src/shared/constants/icons';
import { textDarkTitleColor, textHoverColor } from 'src/shared/styles/colors';

interface IProps {
    heading: string;
    headingTooltip: string;
    data: any[];
    error: string;
    onSelect: (item: { value: string }) => void;
    checked: { label: string; value: string }[];
}

const CheckTitle = ({
    heading = '',
    headingTooltip = '',
    data = [],
    error = '',
    onSelect,
    checked = []
}: IProps) => {
    const tooltipRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (item: { value: string }) => {
        onSelect(item);
    };

    const checkedClass = (item: { value: string }) =>
        cx('mb-4', textHoverColor, {
            [cx(textDarkTitleColor)]: checked?.some((f) => f.value === item.value)
        });

    return (
        <div className={styles.container}>
            <div className={styles.containerTitle} onClick={toggleDropdown}>
                <div className={cx('flex items-center')}>
                    <span
                        className={cx(
                            'mr-2',
                            'text-slate-500 text-lg',
                            isOpen ? ICON_TRIANGLE_DOWN : ICON_TRIANGLE_RIGHT
                        )}
                    />
                    <Tooltip
                        className={styles.containerTitleText}
                        title={heading}
                        tooltipText={headingTooltip}
                        tooltipRef={tooltipRef}
                    />
                </div>
                <span className={styles.containerTitleError}>{error}</span>
            </div>

            {isOpen && (
                <div className={styles.containerContent}>
                    {data.map((item, key) => (
                        <Checkbox
                            className={checkedClass(item)}
                            key={key}
                            data={item}
                            handleCheck={() => handleSelect(item)}
                            isChecked={checked?.some((f) => f.value === item.value)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default React.memo(CheckTitle);
