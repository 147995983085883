import React from 'react';
import cx from 'classnames';

// constants
import {
    ICON_ASCENDING,
    ICON_DESCENDING,
    ICON_TRIANGLE_DOWN,
    ICON_TRIANGLE_UP
} from 'src/shared/constants/icons';
import Tooltip from 'src/components/Tooltip';
import { bgSlate900, textDarkColor } from 'src/shared/styles/colors';

export interface IList {
    id: string;
    label: string;
    priority: number;
    enableSort?: boolean;
    medium?: boolean;
}

interface IProps {
    list: IList[];
    className: string;
    isCampaign?: boolean;
    handleOrderChange: (data: { order: number; sortBy: string }) => void;
    handlePriorityUpdate: (data: IList, idx: number) => void;
}

const Headings = ({
    list,
    className,
    isCampaign,
    handleOrderChange,
    handlePriorityUpdate
}: IProps) => {
    const handleAscending = (e: any) => {
        handleOrderChange({
            sortBy: e.target.id,
            order: 1
        });
    };

    const handleDescending = (e: any) => {
        handleOrderChange({
            sortBy: e.target.id,
            order: -1
        });
    };

    const handlePriorityUp = (e: any) => {
        let pos = Number(e.target.id);
        if (list[pos].priority >= 10) {
            return;
        }
        let data = list[pos];
        data = {
            ...data,
            priority: list[pos].priority + 1
        };
        handlePriorityUpdate(data, pos);
    };

    const handlePriorityDown = (e: any) => {
        let pos = Number(e.target.id);
        if (list[pos].priority <= 0) {
            return;
        }
        let data = list[pos];
        data = {
            ...data,
            priority: list[pos].priority - 1
        };
        handlePriorityUpdate(data, pos);
    };

    return (
        <>
            {list.map(({ id, label, enableSort, medium, priority }, key) => {
                return (
                    <div
                        key={id}
                        className={cx(
                            medium && '[&&]:w-[132px] [&&]:min-w-[132px]',
                            className,
                            'flex items-center', bgSlate900, textDarkColor
                        )}
                    >
                        {!!enableSort && (
                            <span
                                id={id}
                                className={cx(
                                    ICON_ASCENDING,
                                    'text-xs text-slate-300',
                                    'mr-1',
                                    'cursor-pointer'
                                )}
                                onClick={handleAscending}
                            />
                        )}
                        <span>{label}</span>
                        {!!enableSort && (
                            <span
                                id={id}
                                className={cx(
                                    ICON_DESCENDING,
                                    'text-xs text-slate-300',
                                    'ml-1',
                                    'cursor-pointer'
                                )}
                                onClick={handleDescending}
                            />
                        )}
                        {isCampaign && (
                            <div className="relative flex flex-col items-center justify-between ml-2">
                                <div
                                    className={cx(
                                        ICON_TRIANGLE_UP,
                                        'cursor-pointer text-lime-800'
                                    )}
                                    id={`${key}`}
                                    onClick={handlePriorityUp}
                                />
                                <div className="text-sm leading-[0.5]">{priority}</div>
                                <div
                                    className={cx(
                                        ICON_TRIANGLE_DOWN,
                                        'cursor-pointer text-lime-800'
                                    )}
                                    id={`${key}`}
                                    onClick={handlePriorityDown}
                                />
                                <Tooltip
                                    className={'absolute top-9 left-[-2px]'}
                                    tooltipText={'Priority of heading'}
                                    tooltipRef={{ current: null }}
                                    cloudStyles={{ width: 150, minWidth: 150 }}
                                />
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default React.memo(Headings);
